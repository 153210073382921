import { userConstants } from "./constants";
import { fetchUserRef, fetchUser, signOut, uploadImage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { FieldValue } from "../../firebase";
import firebase from "firebase/app";

export const userActions = {
  setUser,
  logout,

  getMyUser,

  login,
  dismissLogin,

  getUserRef,

  showUserSettings,
  closeUserSettings,

  emailLogin,
  emailSignUp,
  googleSignUp,
  facebookSignUp,

  updateUser,
  updateAvatarImage,

  addPreasetToFavorites,
  removePreasetFromFavorites,

  showLoginRequired,
  closeLoginRequired,

  checkLeagalDisplayName,

  showLoginTab,
  showSignUpTab,

  deletePlayerName,
};

function setUser(user, save = false) {
  return (dispatch, getState) => {
    // console.log("set user", user);
    if (user) {
      const userData = {
        displayName: user.displayName ? user.displayName : "",
        photoURL: user.photoURL ? user.photoURL : "",
        uid: user.uid,
      };
      save &&
        updateUser(user.uid, userData, () => {
          dispatch({ type: userConstants.SET_USER, user });
        });
    }
    dispatch({ type: userConstants.SET_USER, user });
  };
}
function logout() {
  signOut();
  return { type: userConstants.LOGOUT };
}

function getMyUser(userId, callback) {
  return (dispatch, getState) => {
    if (userId) {
      fetchUser(userId, (user) => {
        if (user) {
          dispatch(setUser(user, false));
          callback && callback(user);
        } else {
          dispatch(setUser({ uid: userId }, true));
          callback && callback();
        }
      });
    }
  };
}

function login() {
  return { type: userConstants.SHOW_LOGIN };
}

function dismissLogin() {
  return { type: userConstants.CLOSE_LOGIN };
}

function getUserRef(userId) {
  return (dispatch, getState) => {
    if (
      !getState().user.fetchingUsersRefs.includes(userId) &&
      !getState().user.userRefs[userId]
    ) {
      dispatch({ type: userConstants.FETCH_USER_REF_REQUEST, userId });
      fetchUserRef(userId, (user) => {
        dispatch({ type: userConstants.FETCH_USER_REF_SUCCESS, userId, user });
      });
    }
  };
}

function showUserSettings() {
  return { type: userConstants.SHOW_USER_SETTINGS };
}
function closeUserSettings() {
  return { type: userConstants.CLOSE_USER_SETTINGS };
}

const handleLoggedIn = (dispatch, user) => {
  getMyUser(user.uid, (myUser) => {
    if (!myUser) {
      dispatch(userActions.setUser(user, true));
    }
  });
};

function emailLogin(email, password) {
  return (dispatch, getState, getFirebase) => {
    getFirebase()
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        console.log(res.user);
      })
      .catch((error) => {
        console.log(error.message);
        dispatch({ type: userConstants.LOGIN_ERROR, error: error.message });
      });
  };
}

function updateUser(user) {
  return async (dispatch, getState, getFirebase) => {
    await getFirebase().firestore().collection(`users`).doc(user.uid).set(user);
    dispatch({ type: userConstants.UPDATED_PROFILE });
  };
}

function emailSignUp(email, password, confirmPassword, displayName) {
  return (dispatch, getState, getFirebase) => {
    if (password === confirmPassword) {
      getFirebase()
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          const user = {
            uid: res.user.uid,
            displayName: displayName,
            photoURL: null,
          };
          dispatch(updateUser(user));
        })
        .catch((error) => {
          console.log(error.message);
          dispatch({ type: userConstants.LOGIN_ERROR, error: error.message });
        });
    } else {
      dispatch({
        type: userConstants.LOGIN_ERROR,
        error: "passwords dont not match",
      });
    }
  };
}

function googleSignUp() {
  return async (dispatch, getState, getFirebase) => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await getFirebase().auth().signInWithPopup(googleProvider);

      if (result.user) {
        const user = await getFirebase()
          .firestore()
          .collection(`users`)
          .doc(result.user.uid)
          .get();
        if (!user.data()) {
          dispatch(
            updateUser({
              uid: result.user.uid,
              displayName: result.user.displayName,
              photoURL: result.user.photoURL,
            })
          );
        }
      }
    } catch (error) {
      dispatch({
        type: userConstants.LOGIN_ERROR,
        error: error.message,
      });
    }
  };
}

function facebookSignUp() {
  return async (dispatch, getState, getFirebase) => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();
    try {
      const result = await getFirebase()
        .auth()
        .signInWithPopup(facebookProvider);
      if (result.user) {
        const user = await getFirebase()
          .firestore()
          .collection(`users`)
          .doc(result.user.uid)
          .get();
        if (!user.data()) {
          dispatch(
            updateUser({
              uid: result.user.uid,
              displayName: result.user.displayName,
              photoURL:
                result.additionalUserInfo &&
                result.additionalUserInfo.profile &&
                result.additionalUserInfo.profile.picture &&
                result.additionalUserInfo.profile.picture.data &&
                result.additionalUserInfo.profile.picture.data.url
                  ? result.additionalUserInfo.profile.picture.data.url
                  : result.user.photoURL,
            })
          );
        }
      }
    } catch (error) {
      dispatch({
        type: userConstants.LOGIN_ERROR,
        error: error.message,
      });
    }
  };
}

function updateAvatarImage(file, callback) {
  return (dispatch, getState) => {
    if (file) {
      const fileName = uuidv4();
      // start upload file
      uploadImage(file, fileName, (uploadedFileURL) => {
        // file uploaded
        callback && callback(uploadedFileURL);
      });
    }
  };
}

function addPreasetToFavorites(preset) {
  return async (dispatch, getState, getFirebase) => {
    const auth = getState().firebase.auth;
    if (auth.uid && !auth.isAnonymous) {
      await getFirebase()
        .firestore()
        .collection(`users`)
        .doc(auth.uid)
        .update({
          favorites: FieldValue.arrayUnion(preset.id),
        });

      dispatch({
        type: userConstants.ADD_PRESET_TO_FAVORITES,
        presetId: preset.id,
      });
    } else {
      dispatch({ type: userConstants.SHOW_REQUIRE_LOGIN });
    }
  };
}
function removePreasetFromFavorites(preset) {
  return async (dispatch, getState, getFirebase) => {
    const auth = getState().firebase.auth;
    if (auth.uid && !auth.isAnonymous) {
      await getFirebase()
        .firestore()
        .collection(`users`)
        .doc(auth.uid)
        .update({
          favorites: FieldValue.arrayRemove(preset.id),
        });

      dispatch({
        type: userConstants.REMOVE_PRESET_FROM_FAVORITES,
        presetId: preset.id,
      });
    } else {
      dispatch({ type: userConstants.SHOW_REQUIRE_LOGIN });
    }
  };
}

function showLoginRequired() {
  return { type: userConstants.SHOW_REQUIRE_LOGIN };
}

function closeLoginRequired() {
  return { type: userConstants.CLOSE_REQUIRE_LOGIN };
}

function checkLeagalDisplayName(displayName) {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    let result = true;
    await getFirebase()
      .firestore()
      .collection(`users`)
      .where("displayName", "==", displayName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().uid !== uid) {
            result = false;
          }
        });
      });
    return Promise.resolve(result);
  };
}

function showLoginTab() {
  return { type: userConstants.SET_LOGIN_POPUP };
}
function showSignUpTab() {
  return { type: userConstants.SET_SIGNUP_POPUP };
}

function deletePlayerName(name) {
  return async (dispatch, getState, getFirebase) => {
    const auth = getState().firebase.auth;
    await getFirebase()
      .firestore()
      .collection(`users_data`)
      .doc(auth.uid)
      .update({
        players: FieldValue.arrayRemove(name),
      });
  };
}
