import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import InputIcon from "@material-ui/icons/Input";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { presetEditorActions } from "../../features/presetEditor";
import { scoreMethod } from "../../features/gameScoring";
import WalktroughTip from "../../components/WalktoughTip/WalktroughTip";

export default function PresetSettingsEditor(props) {
  const { preset = {} } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDescriptionChanged = (e) => {
    dispatch(
      presetEditorActions.editPresetSettingsField("description", e.target.value)
    );
  };

  const handleOrderChanged = (e, value) => {
    dispatch(
      presetEditorActions.editPresetSettingsField("scoringMethod", value)
    );
  };

  return (
    <div className={classes.root}>
      <TextField
        id="scoreingDescription"
        label="Description"
        multiline
        rows={3}
        value={preset && preset.description ? preset.description : ""}
        variant="outlined"
        className={classes.descriptionTextField}
        onChange={handleDescriptionChanged}
      />

      <div
        className={classes.scoringMethodContainer}
        id="scoreingMethodContainer"
      >
        <Typography variant="caption">Default Scoring Order</Typography>
        <WalktroughTip
          message={`sets the order of columns of the scorepad`}
          gotItButton={true}
          tipId="tip.create.preset.scoring.method"
          anchorElName={`scoreingMethodContainer`}
          popover={true}
        />
        <ToggleButtonGroup
          value={
            preset && preset.scoringMethod
              ? preset.scoringMethod
              : scoreMethod.BY_PLAYER
          }
          exclusive
          onChange={handleOrderChanged}
        >
          <ToggleButton
            value={scoreMethod.BY_PLAYER}
            className={classes.scoringMethodToggleButton}
            classes={{ label: classes.scoringMethodToggleButtonLabel }}
          >
            <SystemUpdateAltIcon />
            By Player
          </ToggleButton>
          <ToggleButton
            value={scoreMethod.BY_SCORE}
            className={classes.scoringMethodToggleButton}
            classes={{ label: classes.scoringMethodToggleButtonLabel }}
          >
            <InputIcon />
            By Score
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    marginTop: 16,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  scoringMethodContainer: {
    display: "flex",
    flex: 1,
    marginLeft: 16,
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  scoringMethodToggleButtonLabel: {
    display: "flex",
    flexDirection: "column",
    fontSize: 10,
  },
  descriptionTextField: {
    flex: 2,
  },
}));
