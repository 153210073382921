import { makeStyles, Button, Popover, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import randomcolor from "randomcolor";
import CheckIcon from "@material-ui/icons/Check";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const ColorPickerbutton = (props) => {
  const { onChange, onDelete, color } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles({ color: color });

  const handleOpenColorPicker = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChange = (color) => {
    onChange && onChange(color);
  };

  const handleDelete = (e) => {
    setAnchorEl(null);
    onDelete && onDelete(color);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Paper className={classes.root}>
      <Button
        variant="contained"
        onClick={handleOpenColorPicker}
        className={classes.colorButton}
      >
        {!color && "Select Color"}
      </Button>

      <Popover
        id="color-picker"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popupContent}>
          <div className={classes.colorPreview} />
          <HexColorPicker
            color={color ? color : randomcolor()}
            onChange={handleChange}
          />

          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={handleDelete}
            >
              <DeleteForeverIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.okButton}
              onClick={handleClose}
            >
              <CheckIcon />
            </Button>
          </div>
        </div>
      </Popover>
    </Paper>
  );
};

export default ColorPickerbutton;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: 0,
    margin: 8,
    padding: 8,
    borderRadius: 10,
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  colorButton: {
    backgroundColor: (props) => props.color,
    "&:hover": {
      backgroundColor: (props) => props.color,
      filter: "brightness(0.8)",
    },
    height: 64,
    width: 64,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  deleteButton: {
    marginTop: 16,
    backgroundColor: theme.palette.error.main,
    marginRight: 16,
  },
  okButton: {
    marginTop: 16,
    backgroundColor: theme.palette.success.main,
  },
  popupContent: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
  },
  colorPreview: {
    width: "100%",
    height: 40,
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: (props) => props.color,
  },
}));
