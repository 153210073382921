import { presetConstants } from "./constants";
import { generateID } from "../../helpers";

const initialState = {
  preset: null,
  scoreSettings: null,
  playersSettings: null,
  currentScoreSettings: null,
  showScoreSettingsEditor: false,

  showPlayersSettingsDialog: false,
};

const newScoreSettings = {
  name: "",
  description: "",
  input: "NUMBER",
  image: null,
};

const defaultPlayersSetup = {
  colors: [],
  avatars: [],
};

export default function presetEditorReducer(state = initialState, action) {
  switch (action.type) {
    case presetConstants.SET_PRESET:
      return {
        ...state,
        preset: action.preset,
        scoreSettings: action.preset.scoreSettings
          ? action.preset.scoreSettings
          : [],
        playersSettings: action.preset.playersSettings
          ? action.preset.playersSettings
          : defaultPlayersSetup,
      };
    case presetConstants.NEW_PRESET:
      return {
        ...state,
        preset: {
          ...action.preset,
          id: generateID(),
          forceUniqueColors: true,
        },
        scoreSettings: [],
        playersSettings: defaultPlayersSetup,
      };

    case presetConstants.LOAD_PRESET:
      return {
        ...state,
        preset: action.preset,
        scoreSettings: action.preset.scoreSettings,
        playersSettings: action.preset.playersSettings
          ? action.preset.playersSettings
          : defaultPlayersSetup,
      };

    case presetConstants.CREATE_SCORE_SETTINGS:
      return {
        ...state,
        currentScoreSettings: { ...newScoreSettings, id: generateID() },
        showScoreSettingsEditor: true,
      };
    case presetConstants.REORDER_SCORE_SETTINGS: {
      return {
        ...state,
        scoreSettings: action.scoreSettingsList,
      };
    }
    case presetConstants.EDIT_SCORE_SETTING:
      return {
        ...state,
        currentScoreSettings: action.scoreSetting,
        showScoreSettingsEditor: true,
      };
    case presetConstants.CLOSE_SCORE_SETTINGS_DIALOG:
      return { ...state, showScoreSettingsEditor: false };
    case presetConstants.EDIT_SCORE_SETTINGS_FIELD:
      return {
        ...state,
        currentScoreSettings: {
          ...state.currentScoreSettings,
          [action.field]: action.value,
        },
      };
    case presetConstants.SAVE_SCORE_SETTINGS:
      if (!action.scoreSettings.id) {
        action.scoreSettings.id = generateID();
      }

      let scoreSettings = state.scoreSettings;
      const index = scoreSettings.findIndex(
        (scoreSettings) => scoreSettings.id === action.scoreSettings.id
      );

      if (index > -1) {
        scoreSettings = scoreSettings.map((scoreSetting) => {
          return scoreSetting.id === action.scoreSettings.id
            ? action.scoreSettings
            : scoreSetting;
        });
      } else {
        scoreSettings = [...scoreSettings, action.scoreSettings];
      }
      return {
        ...state,
        scoreSettings: scoreSettings,
        currentScoreSettings: null,
        showScoreSettingsEditor: false,
      };

    case presetConstants.DELETE_SCORE_SETTINGS:
      return {
        ...state,
        currentScoreSettings: null,
        scoreSettings: state.scoreSettings.filter(
          (scoreSetting) => scoreSetting.id !== action.scoreSetting.id
        ),
      };

    case presetConstants.SCORE_SETTING_IMGAE_START_UPLOAD:
      return { ...state };
    case presetConstants.SCORE_SETTING_IMGAE_FINISHED_UPLOAD:
      return {
        ...state,
        currentScoreSettings: {
          ...state.currentScoreSettings,
          image: action.uploadedFileURL,
        },
      };

    case presetConstants.EDIT_PLAYER_SETTINGS_COLORS:
      return {
        ...state,
        playersSettings: { ...state.playersSettings, colors: action.colors },
      };

    case presetConstants.EDIT_PRESET_SETTINGS_FIELD:
      return {
        ...state,
        preset: { ...state.preset, [action.field]: action.value },
      };

    case presetConstants.EDIT_PLAYER_SETTINGS_FORCE_UNIQUE_COLORS:
      return {
        ...state,
        preset: {
          ...state.preset,
          forceUniqueColors: action.forceUniqueColors,
        },
      };
    default:
      return state;
  }
}
