import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyCbHXe2Ujk3ATr3oCpfrLnOodYsp-PdGWI",
  authDomain: "spielpad-22f17.firebaseapp.com",
  projectId: "spielpad-22f17",
  storageBucket: "spielpad-22f17.appspot.com",
  messagingSenderId: "799604825314",
  appId: "1:799604825314:web:c14ba00891673bb9cf92bb",
  measurementId: "G-XVDSNXGL0J",
};

export const fb = firebase.initializeApp(firebaseConfig);
export const analytics = fb.analytics();
export const firestore = fb.firestore();
export const storage = fb.storage();
export const auth = fb.auth();
export const FieldValue = firebase.firestore.FieldValue;
