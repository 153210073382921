import {
  Button,
  Avatar,
  Typography,
  Popover,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";
import SettingsIcon from "@material-ui/icons/Settings";
import clsx from "clsx";

const AccountButton = (props) => {
  const { className } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const classes = useStyles();
  const dispatch = useDispatch();

  const loggedIn = auth && !auth.isEmpty && !auth.isAnonymous;

  const handleOpenUserMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(userActions.logout());
  };

  const handleLogin = () => {
    dispatch(userActions.login());
  };

  const handleSettings = () => {
    setAnchorEl(null);
    dispatch(userActions.showUserSettings());
  };

  const renderUserPopup = () => {
    return (
      <div className={classes.popover}>
        <div className={classes.popoverDetailsContent}>
          <Avatar src={profile.photoURL} className={classes.popoverAvatar} />
          <Typography>{profile.displayName}</Typography>
        </div>
        <div className={classes.menuButtons}>
          <Button
            onClick={handleSettings}
            className={classes.menuButton}
            variant="contained"
          >
            <SettingsIcon fontSize="small" />
            Settings
          </Button>

          <Button
            onClick={handleLogout}
            variant="contained"
            className={classes.menuButton}
          >
            Logout
          </Button>
        </div>
      </div>
    );
  };

  const open = Boolean(anchorEl);

  const renderUserInfo = () => {
    return (
      <Button className={classes.accountButton} onClick={handleOpenUserMenu}>
        <Avatar src={profile.photoURL} className={classes.avatar} />
        <Typography className={classes.nameLabel}>
          {profile.displayName}
        </Typography>
      </Button>
    );
  };

  const renderGuest = () => {
    return (
      <div className={classes.loginButtonContent}>
        <Button onClick={handleLogin} className={classes.LoginButton}>
          LOGIN
        </Button>
      </div>
    );
  };

  const renderAccountMenu = () => {
    return (
      <Popover
        id="userinfodialog"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {renderUserPopup()}
      </Popover>
    );
  };

  if (!profile.isLoaded) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={clsx(className, classes.root)}>
      {loggedIn && renderAccountMenu()}
      {loggedIn ? renderUserInfo() : renderGuest()}
    </div>
  );
};

export default AccountButton;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: 0,
    },
    [theme.breakpoints.up("md")]: {
      bottom: 0,
    },
    backgroundColor: "#00000099",
    width: "100%",
    position: "absolute",
    left: 0,
    padding: 8,
    minHeight: 70,
  },
  accountButton: {
    display: "flex",
    flexDierection: "row",
    textTransform: "none",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      borderRadius: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      justifyContent: "flex-start",
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  avatar: {
    width: 64,
    height: 64,
    cursor: "pointer",
    backgroundColor: "gray",

    [theme.breakpoints.up("md")]: {},
    "&:hover": {
      opacity: 0.7,
    },
  },
  nameLabel: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 16,
    },
  },
  menuButton: {
    alignItems: "center",
    marginTop: 16,
    backgroundColor: "#00000022",
    color: "white",
    "&:hover": {
      backgroundColor: "#00000044",
    },
  },
  menuButtons: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
  },
  popover: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
  },
  popoverAvatar: {
    width: 90,
    height: 90,
    marginRight: 16,
  },
  popoverDetailsContent: {
    display: "flex",
    alignItems: "center",
  },
  loginButtonContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  LoginButton: {},
}));
