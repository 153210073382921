import React from "react";
import { makeStyles } from "@material-ui/core";
import { MainMenu } from "../../components/MainMenu";
import HomeContent from "./HomeContent";
import Feedback from "../../components/Feedback/Feedback";

export default function Home() {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Feedback />
      <MainMenu />
      <section className={classes.content}>
        <HomeContent />
      </section>
    </main>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#00000044",
    minHeight: "100%",
  },
  content: {
    minHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 100,
      minHeight: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "30%",
      width: "70%",
      maxWidth: 1000,
    },
  },
}));
