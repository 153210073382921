import React from "react";
import { PresetTile, CreatePresetTile } from "../../components/PresetTile";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Masonry from "react-masonry-css";

const gridColumnsCount = {
  default: 2,
  1100: 2,
  640: 1,
};

export default function GamePresets(props) {
  const {
    game = null,
    presets,
    showGameCover = false,
    showCreatePreset = false,
  } = props;
  const history = useHistory();

  const classes = useStyles();

  const handleCreatePreset = (e) => {
    history.push(`/game/${game.id}/edit/new`);
  };

  const renderPresetsList = () => {
    const keys = presets ? [...Object.keys(presets), null] : [null];
    return keys.map((key, index) => {
      const preset = presets && presets[key];
      if (preset) {
        const shouldShow = game ? game.id === preset.gameId : true;
        return (
          preset &&
          shouldShow && (
            <PresetTile
              preset={preset}
              key={preset.id}
              showGameCover={showGameCover}
            />
          )
        );
      } else {
        return (
          showCreatePreset && (
            <CreatePresetTile
              key={index}
              onClick={handleCreatePreset}
              game={game}
              title={
                keys.length > 1
                  ? `Add new scorepad`
                  : `Be the first to create a scorepad for ${game && game.name}`
              }
            />
          )
        );
      }
    });
  };

  return (
    <div>
      <Masonry
        breakpointCols={gridColumnsCount}
        className={classes.grid}
        columnClassName={classes.column}
      >
        {renderPresetsList()}
      </Masonry>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  presetsContainer: {
    display: "flex",
    flexFlow: "column wrap",
  },
  grid: {
    display: " -webkit-box" /* Not needed if autoprefixing */,
    display: "-ms-flexbox" /* Not needed if autoprefixing */,
    display: "flex",

    width: "auto",
  },
  column: {
    backgroundClip: "padding-box",
  },
}));
