import { Avatar, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";

export default function DeleteAccount() {
  const profile = useSelector((state) => state.firebase.profile);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(userActions.login());
  };

  const handleDeleteAccount = () => {};

  const handleGoHome = () => {};

  const loggedIn = profile && !profile.isEmpty;

  const renderProfile = () => {
    if (loggedIn) {
      return (
        <div className={classes.content}>
          <Typography align="center">
            Are you sure you want to delete this account?
          </Typography>
          <Typography align="center">
            All information associated with it will be lost
          </Typography>

          <Avatar src={profile.photoURL} className={classes.avatar} />
          <Typography className={classes.nameLabel}>
            {profile.displayName}
          </Typography>

          <div className={classes.buttonsContainer}>
            <Button
              onClick={handleDeleteAccount}
              className={classes.deleteButton}
            >
              Delete account
            </Button>

            <Button href="/" className={classes.homeButton}>
              I've changed my minde, go home
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.content}>
          <Typography align="center">
            You need to be logged in order to delete your account
          </Typography>

          <div className={classes.buttonsContainer}>
            <Button onClick={handleLogin} className={classes.loginButton}>
              LOGIN
            </Button>

            <Button href="/" className={classes.loginButton}>
              Go home
            </Button>
          </div>
        </div>
      );
    }
  };

  return <div className={classes.root}>{renderProfile()}</div>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    alignItems: "center",
  },
  loginButton: {
    margin: 16,
  },
  avatar: {
    marginTop: 16,
  },
  deleteButton: {
    margin: 16,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main + "AA",
    },
  },
  homeButton: {
    margin: 16,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main + "AA",
    },
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}));
