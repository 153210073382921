import { firestore, storage, FieldValue } from "./Firebase";
import Resizer from "react-image-file-resizer";
var util = require("util");

export function saveGame(game, callback) {
  firestore
    .collection("games")
    .doc(game.id)
    .set(game)
    .then((docRef) => {
      console.log("Document written with ID: ", docRef);
      callback && callback(true);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      callback && callback(false);
    });
}

export function fetchGames(callback) {
  firestore
    .collection("games")
    .get()
    .then((querySnapshot) => {
      let games = [];
      querySnapshot.forEach((doc) => {
        games.push(doc.data());
      });
      callback && callback(games);
    });
}

export function fetchGame(gameId, callback) {
  var docRef = firestore.collection("games").doc(gameId);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
        callback && callback(doc.data());
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
}

export function fetchPresets(gameId, callback) {
  var collectionRef = firestore.collection("presets");

  collectionRef
    .where("gameId", "==", gameId.toString())
    .get()
    .then((querySnapshot) => {
      let presets = [];
      querySnapshot.forEach((doc) => {
        presets.push(doc.data());
      });
      callback && callback(presets);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
}

export function fetchPreset(presetId, callback) {
  var docRef = firestore.collection("presets").doc(presetId);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        if (doc.data() && doc.data().author && doc.data().author.id) {
          doc
            .data()
            .author.get()
            .then((res) => {
              callback && callback({ ...doc.data(), author: res.data() });
            })
            .catch((err) => console.error(err));
        } else {
          callback && callback(doc.data());
        }
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
}

export function savePresetForGame(preset, callback) {
  firestore
    .collection("presets")
    .doc(preset.id)
    .set(preset)
    .then((docRef) => {
      console.log("Document written with ID: ", docRef);
      callback();
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      callback();
    });
}

export function deletePresetForGame(preset, callback) {
  firestore
    .collection("presets")
    .doc(preset.id)
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
      callback && callback(true);
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
      callback && callback(false);
    });
}

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      256,
      256,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

//export async function uploadImageBlob(imageBlob, fileName, callback) {}

var getFileBlob = function (url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.addEventListener("load", function () {
    cb(xhr.response);
  });
  xhr.send();
};

export async function uploadImage__(imageURL, fileName, callback) {
  storage
    .ref(`/images/${fileName}`)
    .putString(imageURL, "data_url")
    .then(
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        //  console.log(util.inspect(snapShot));
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("images")
          .child(fileName)
          .getDownloadURL()
          .then(
            (fireBaseUrl) => {
              // setImageAsUrl((prevObject) => ({
              //   ...prevObject,
              //   imgUrl: fireBaseUrl,
              // }));
              callback(fireBaseUrl);
            },
            (err) => {
              //catches the errors
              console.log(err);
            }
          );
      }
    );
}

export async function uploadImage(imageURL, fileName, callback) {
  // getFileBlob(imageURL, (blob) => {
  // console.log("uploading file", util.inspect(blob));

  // const image = resizeFile(imageURL);

  // console.log("image", image);

  // const image = await resizeFile(file);

  const uploadTask = storage
    .ref(`/images/${fileName}`)
    .putString(imageURL, "data_url");
  //initiates the firebase side uploading
  uploadTask.on(
    "state_changed",
    (snapShot) => {
      //takes a snap shot of the process as it is happening
      //  console.log(util.inspect(snapShot));
    },
    (err) => {
      //catches the errors
      console.log(err);
    },
    () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage
        .ref("images")
        .child(fileName)
        .getDownloadURL()
        .then((fireBaseUrl) => {
          // setImageAsUrl((prevObject) => ({
          //   ...prevObject,
          //   imgUrl: fireBaseUrl,
          // }));
          callback(fireBaseUrl);
        });
    }
  );
  //  });
}

/// ======== Users ==========//

export const updateUser = (userId, userData, callback) => {
  firestore
    .collection("users")
    .doc(userId)
    .set(userData, { merge: true })
    .then((docRef) => {
      console.log("updated user", docRef);
      callback && callback();
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      callback && callback();
    });
};

export const addPresetToFavorites = (userId, favorite, callback) => {
  const userDoc = firestore.collection("users").doc(userId);
  userDoc
    .update({
      favorites: FieldValue.arrayUnion(favorite),
    })
    .then(() => {
      callback && callback();
    });
};

export const removePresetFromFavorites = (userId, favorite, callback) => {
  const userDoc = firestore.collection("users").doc(userId);
  userDoc
    .update({
      favorites: FieldValue.arrayRemove(favorite),
    })
    .then(() => {
      callback && callback();
    });
};

export const fetchUserRef = (userId, callback) => {
  var docRef = firestore.collection("users").doc(userId);
  docRef
    .get()
    .then((res) => {
      callback && callback(res.data());
    })
    .catch((err) => console.error(err));
};

export const fetchUser = (userId, callback) => {
  var docRef = firestore.collection("users").doc(userId);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        //  console.log("Document data:", doc.data());
        callback && callback(doc.data());
      } else {
        console.log("No such document!");
        callback(null);
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};
