import { Button } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

const AdminMenu = () => {
  return (
    <div>
      <Button component={NavLink} to="/admin/games">
        Games
      </Button>
      <Button component={NavLink} to="/admin/playes">
        Playes
      </Button>
    </div>
  );
};

export default AdminMenu;
