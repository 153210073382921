import React, { useState, useEffect } from "react";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { Parser } from "xml2js";
import { search } from "../../helpers";

export default function BGGSearchField(props) {
  const { onSelect, ...other } = props;
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const fetchGames = async (query) => {
    setLoading(true);
    const res = await search(query);
    if (res) {
      const parser = new Parser({ mergeAttrs: true });
      parser.parseString(res, function (err, result) {
        if (result && result.boardgames && result.boardgames.boardgame) {
          let gamesList = [];

          for (let i = 0; i < 40; i++) {
            const item = result.boardgames.boardgame[i];
            if (item && item.name != undefined && item.name[0]._ != undefined) {
              gamesList.push({
                name: item.name[0]._,
                id: item.objectid[0],
                year: item.yearpublished
                  ? Number(item.yearpublished[0])
                  : "Unavailable",
              });
            }
          }

          setOptions(gamesList);
          setLoading(false);
        }
      });
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputValue.length > 0) {
      fetchGames(inputValue);
    } else {
      setOptions([]);
    }

    return () => {};
  }, [inputValue]);

  return (
    <Autocomplete
      id="bgg-search"
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={loading}
      className={classes.root}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.name + "(" + option.year + ")"
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search BGG"
          variant="outlined"
          fullWidth
          className={classes.textField}
        />
      )}
      renderOption={(option) => {
        return (
          <Typography variant="body2" color="textSecondary">
            {option && option.name + " (" + option.year + ")"}
          </Typography>
        );
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 12,
    },
    [theme.breakpoints.up("md")]: { paddingTop: 16 },
  },
  textField: {},
}));
