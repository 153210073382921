import { gameDataConstants } from "./constants";
import {
  fetchGames,
  fetchGame,
  fetchPresets,
  getFavorites as fetchFavorites,
} from "../../firebase";

export const gamesDataActions = {
  getGames,
  getGame,
  setActiveGame,
  getGamePresets,
  getFavorites,
};

function getGames() {
  return (dispatch) => {
    fetchGames((games) => {
      dispatch({ type: gameDataConstants.FETCH_GAMES_LIST, games });
    });
  };
}

function getGame(gameId) {
  return (dispatch, getState) => {
    if (
      !getState().gamesData.gamesInfo[gameId] &&
      !getState().gamesData.fetchingGames.includes(gameId)
    ) {
      dispatch({ type: gameDataConstants.GET_GAME_REQUEST, gameId });
      fetchGame(gameId, (game) => {
        dispatch({
          type: gameDataConstants.GET_GAME_SUCCESS,
          gameId,
          game: game,
        });
      });
    }
  };
}

function setActiveGame(gameId) {
  return (dispatch) => {
    dispatch({ type: gameDataConstants.SET_ACTIVE_GAME_REQUEST });
    fetchGame(gameId, (game) => {
      dispatch({ type: gameDataConstants.SET_ACTIVE_GAME_SUCCESS, game: game });
    });
  };
}

function getGamePresets(gameId) {
  return (dispatch) => {
    dispatch({ type: gameDataConstants.FETCH_GAME_PRESETS_REQUEST });
    fetchPresets(gameId, (presets) => {
      dispatch({ type: gameDataConstants.FETCH_GAME_PRESETS_SUCCESS, presets });
    });
  };
}

function getFavorites() {
  return (dispatch, getState) => {
    const user = getState().firebase.auth;
    if (user.uid && !getState().gamesData.loadingFavorites) {
      dispatch({ type: gameDataConstants.FETCH_FAVORITES_REQUEST });
      fetchFavorites(user.uid, (favorites) => {
        dispatch({
          type: gameDataConstants.FETCH_FAVORITES_SUCCESS,
          favorites,
        });
      });
    }
  };
}
