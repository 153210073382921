import { Divider, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

export default function ScriptFieldHelp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Scripting reference</Typography>
      <Divider />
      <Alert severity="warning">
        Scripts are in early development and might be changed
        <br />
        error handeling is not implemented yet so any error could crash the app,
        so be carful (dont divide by 0!)
      </Alert>
      <Typography className={classes.pergraph}>
        Scripts can be used to have relationships between different scoring rows
        in the score-pad.
        <br />
        Scripts are used to make calculations on defined inputs and output a
        value that will be used as the score for this row.
      </Typography>
      <Typography className={classes.subTitle}>Using Scripts</Typography>
      <Divider />
      <Typography className={classes.subTitle}>
        Referencing the current row
      </Typography>
      <Typography>
        <span className={classes.code}>@this@</span> - reference to the value in
        the current scoring row (the value that as input by the user)
        Referencing
      </Typography>
      <Typography className={classes.subTitle}>
        Referencing other rows
      </Typography>
      <Typography>
        Using the “Key” field in a different row expose it as a value that can
        be used using <span className={classes.code}>@KeyName@</span>
        <br />
        i.e - giving a field the key “level” can be used as
        <span className={classes.code}>@level@</span> in a script on a different
        scoring row.
      </Typography>
      <Typography className={classes.subTitle}>Operations</Typography>
      <Typography>
        Normal math operation are available:
        <br />
        <span className={classes.code}>+</span>{" "}
        <span className={classes.code}>-</span>{" "}
        <span className={classes.code}>*</span>{" "}
        <span className={classes.code}>/</span>{" "}
        <span className={classes.code}>and brackets ( )</span> <br />
        <br />
        Example: in Orléans there is a development track that is used as a
        multiplier for other scoring values
      </Typography>
      <br />
      <Typography className={classes.example}>
        @development@ * @this@
      </Typography>
      <Typography className={classes.subTitle}> Advanced functions</Typography>
      <Typography>
        <span className={classes.code}>@round ( n )</span> - round up the value
        n inside the brackets
        <br />
        <span className={classes.code}> @floor ( n )</span> - round down the
        value n inside the brackets <br />
        <span className={classes.code}> @max ( a , b )</span> - returns the
        bigger value between a and b<br />
        <span className={classes.code}>@min ( a , b )</span> - returns the
        smaller value between a and b<br />
        <span className={classes.code}>@pow ( a , n )</span> - returns the base
        a to the n power as in aⁿ
        <br /> <br />
        Example: In scythe there is a popularity track that is separated to a 3
        levels of scores, so a row of scoring would look like that:
      </Typography>
      <br />
      <Typography className={classes.example}>
        ( 3 + @floor((@max(@popularity@ - 0.1 , 1 )) / 6 ) ) * @this@
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
  title: {
    fontSize: 30,
  },
  subTitle: {
    marginTop: 32,
    fontSize: 26,
    fontWeight: 700,
  },
  pergraph: {
    marginTop: 16,
  },
  code: {
    backgroundColor: "#00000044",
    padding: 4,
    color: theme.palette.primary.main,
  },
  example: {
    backgroundColor: "#00000044",
    padding: 16,
    color: "white",
    fontStyle: "italic",
  },
}));
