import {
  Button,
  IconButton,
  makeStyles,
  Typography,
  Paper,
  Zoom,
} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";

const optionsMock = {
  topId: 1,
  dependencies: [2, 3],
  showOnlyOnce: false,
  closeButton: true,
  gotItButton: true,
};

export default function WalktroughTip(props) {
  const {
    message,
    options,
    className,
    gotItButton,
    closeButton,
    popover,
    anchorElName,
    tipId,
    ...other
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [isFinished, setIsFinished] = useState(false);

  const classes = useStyles({ popover });

  useEffect(() => {
    const ref = document.getElementById(anchorElName);
    setAnchorEl(ref);
    ref && ref.addEventListener("click", handleFinishTipButton);
    return () => {
      ref && ref.removeEventListener("click", handleFinishTipButton);
    };
  }, [anchorElName]);

  useEffect(() => {
    setIsFinished(Boolean(localStorage.getItem(tipId)));
    return () => {};
  }, []);

  const handleFinishTipButton = () => {
    localStorage.setItem(tipId, true);
    setIsFinished(true);
  };

  const show = !isFinished;

  const renderTooltip = () => {
    return (
      <Paper className={clsx(classes.root, className)} {...other}>
        {popover && <span className={classes.arrow} ref={setArrowRef} />}
        <div className={classes.content}>
          <Typography>{message}</Typography>
          {closeButton && (
            <IconButton className={classes.closeButton}>
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
        </div>

        {gotItButton && (
          <Button
            className={classes.gotItButton}
            onClick={handleFinishTipButton}
          >
            OK, Got it
          </Button>
        )}
      </Paper>
    );
  };

  if (popover && anchorEl) {
    return (
      <Popper
        id={tipId}
        open={show}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Zoom
            {...TransitionProps}
            in={show}
            style={{ transitionDelay: show ? "1000ms" : "0ms" }}
          >
            {renderTooltip()}
          </Zoom>
        )}
      </Popper>
    );
  }
  return show && renderTooltip();
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    borderRadius: 4,
    backgroundColor: "#00C3E1",
    border: "1px solid #E4FF00",
    display: "flex",
    flexDirection: "column",
    width: (props) => (props.popover ? 300 : null),
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  closeButton: {
    marginLeft: "auto",
    padding: 6,
    float: "right",
  },
  gotItButton: {
    width: 200,
    marginLeft: "auto",
    color: "#E4FF00",
    fontWeight: 900,
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 1,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 1,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      transform: "rotate(45deg)",
      backgroundColor: "#00C3E1",
      border: "1px solid #E4FF00",
    },
  },
}));
