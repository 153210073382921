import React from "react";
import { Route, Switch } from "react-router-dom";
import Favorites from "./Favorites";
import { GameSelection } from "../../components/GameSelection";
import { CreateGame } from "../Create Game";
import { About } from "../About";

export default function HomeContent() {
  return (
    <Switch>
      <Route exact path="/">
        <GameSelection />
      </Route>
      <Route exact path="/findgame">
        <CreateGame />
      </Route>
      <Route exact path="/favorites">
        <Favorites />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
    </Switch>
  );
}
