import React, { useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { gameScoringActions } from "../../features/gameScoring";
import BackspaceIcon from "@material-ui/icons/Backspace";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import KeyboardHideIcon from "@material-ui/icons/KeyboardHide";
import { useParams } from "react-router";

const operations = {
  DELETE: "DELETE",
  NEXT: "NEXT",
  MINUS: "MINUS",
};

export default function NumberPad(props) {
  const { onNext } = props;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { scoreIndex } = useParams();
  const scorepad = useSelector((state) => state.gameScoring.scorepad);
  const score = scorepad[scoreIndex] && scorepad[scoreIndex].value;
  const classes = useStyles();
  const allowNegative =
    scorepad[scoreIndex].scoring.allowNegative &&
    !scorepad[scoreIndex].scoring.negative;
  const handleKeyPress = (value) => {
    let updatedValue = score.toString();
    if (isNaN(updatedValue)) {
      updatedValue = 0;
    }
    switch (value) {
      case operations.DELETE:
        updatedValue = updatedValue.length > 0 && updatedValue.slice(0, -1);
        break;
      case operations.NEXT:
        onNext();
        return;
      case operations.MINUS:
        updatedValue = updatedValue * -1;
        break;
      default:
        updatedValue = updatedValue + value.toString();
    }

    dispatch(
      gameScoringActions.setScore(Number(updatedValue), Number(scoreIndex))
    );
  };

  const keyContent = (value) => {
    switch (value) {
      case operations.DELETE:
        return <BackspaceIcon />;
      case operations.MINUS:
        return `-`;
      default:
        return value;
    }
  };

  const key = (value, buttonSize) => {
    return (
      <Grid item xs={buttonSize} className={classes.cell}>
        <div className={classes.innerCell}>
          <Button onClick={() => handleKeyPress(value)} className={classes.key}>
            {keyContent(value)}
          </Button>
        </div>
      </Grid>
    );
  };

  const renderKeyboard = () => {
    return (
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={classes.hideKeyboardCell}>
          <Button
            onClick={() => setShow(false)}
            className={classes.hideKeyboardButton}
          >
            <KeyboardHideIcon />
          </Button>
        </Grid>
        {key(1, 4)}
        {key(2, 4)}
        {key(3, 4)}
        {key(4, 4)}
        {key(5, 4)}
        {key(6, 4)}
        {key(7, 4)}
        {key(8, 4)}
        {key(9, 4)}
        {key(operations.DELETE, allowNegative ? 2 : 4)}
        {allowNegative && key(operations.MINUS, 2)}
        {key(0, 4)}
        {key(operations.NEXT, 4)}
      </Grid>
    );
  };

  const renderShowKeyboardButton = () => {
    return (
      <Button
        onClick={() => setShow(true)}
        className={classes.showKeyboardButton}
        variant="contained"
        startIcon={<KeyboardIcon />}
      >
        Virtual keyboard
      </Button>
    );
  };

  return (
    <div className={classes.classes}>
      {show ? renderKeyboard() : renderShowKeyboardButton()}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    margin: "auto",
    width: "100%",
    dispaly: "flex",
  },
  grid: {
    width: "100%",
    maxWidth: 600,
    margin: "auto",
  },
  cell: {},
  innerCell: {
    backgroundColor: "#00000055",
    margin: 1,
    borderRadius: 5,
  },
  key: {
    width: "100%",
    height: "10vh",
    maxHeight: 60,
  },
  showKeyboardButton: {
    display: "flex",
    margin: "auto",
    marginBottom: 100,
  },
  hideKeyboardCell: {
    display: "flex",
  },
  hideKeyboardButton: {
    display: "felx",
    marginLeft: "auto",
  },
});
