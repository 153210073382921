import { Button, makeStyles, Popover, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameScoringActions } from "../../features/gameScoring";
import ClearIcon from "@material-ui/icons/Clear";

export default function PlayerAvatar(props) {
  const { player, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useClasses({ color: player && player.color });
  const dispatch = useDispatch();

  const preset = useSelector((state) => state.gameScoring.preset);
  const players = useSelector((state) => state.gameScoring.players);

  const colors =
    preset && preset.playersSettings && preset.playersSettings.colors;
  const avatars =
    preset && preset.playersSettings && preset.playersSettings.avatars;

  const hanldeSelectAvatar = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorSelect = (color) => {
    setAnchorEl(null);
    dispatch(gameScoringActions.editPlayer({ ...player, color: color }));
  };

  const isColorAvalable = (color) => {
    if (preset.forceUniqueColors) {
      const otherPlayer = players.find((p) => p.color === color);
      if (otherPlayer && color !== player.color) {
        return false;
      }
    }
    return true;
  };

  const open = Boolean(anchorEl);

  const renderClearColorButton = () => {
    return (
      <Button
        className={classes.clearColorButton}
        onClick={() => handleColorSelect(null)}
      >
        <ClearIcon fontSize="large" />
      </Button>
    );
  };

  const renderColorsSelection = () => {
    return (
      colors &&
      colors.map((color, index) => {
        return (
          <ColorSelectorButton
            key={index}
            color={color}
            onClick={handleColorSelect}
            disabled={!isColorAvalable(color)}
          />
        );
      })
    );
  };

  return (
    <Fragment>
      <Button
        className={classes.avatarButton}
        onClick={hanldeSelectAvatar}
        {...other}
      />
      <Popover
        id="avatarSelectionPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.content}>
          <Typography className={classes.typography}>
            Select player color
          </Typography>
          <div className={classes.colorsContainer}>
            {renderClearColorButton()}
            {renderColorsSelection()}
          </div>
        </div>
      </Popover>
    </Fragment>
  );
}

const useClasses = makeStyles((theme) => ({
  avatarButton: {
    width: 70,
    height: 70,
    border: "2px solid #000",
    backgroundColor: (props) => (props.color ? props.color : "#00000066"),
    marginRight: 16,
    "&:hover": {
      backgroundColor: (props) =>
        props.color ? `${props.color}99` : "#00000033",
    },
  },
  content: {
    padding: 32,
    display: "flex",
    flexDirection: "column",
  },
  colorsContainer: {
    display: "flex",
    flex: 1,
    maxWidth: 400,
    flexWrap: "wrap",
  },
  clearColorButton: {
    width: 70,
    height: 70,
    border: "2px solid #000",
    backgroundColor: "gray",
    margin: 8,
  },
}));

const ColorSelectorButton = (props) => {
  const { color, onClick, disabled } = props;

  const classes = useColorSelectionButtonStyles({ color, disabled });

  return (
    <Button
      className={classes.button}
      onClick={() => onClick(color)}
      disabled={disabled}
    />
  );
};

const useColorSelectionButtonStyles = makeStyles((theme) => ({
  button: {
    width: 70,
    height: 70,
    border: (props) =>
      props.disabled ? "2px solid #00000055" : "2px solid #000",
    backgroundColor: (props) =>
      props.disabled ? `${props.color}22` : props.color,
    margin: 8,
    "&:hover": {
      backgroundColor: (props) => `${props.color}33`,
    },
  },
}));
