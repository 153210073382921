import { Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";

export default function (params) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2">404.</Typography>
      <Typography>Opps... There is nothing here </Typography>
      <Link href={`/`}>go back to safty</Link>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
