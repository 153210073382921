import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { userActions } from "../../features/user";
import { useDispatch, useSelector } from "react-redux";

export default function FavoriteButton(props) {
  const { preset } = props;
  const favorites = useSelector((state) => state.firebase.profile.favorites);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleAddTofavorites = () => {
    dispatch(userActions.addPreasetToFavorites(preset));
  };
  const handleRemoveFromfavorites = () => {
    dispatch(userActions.removePreasetFromFavorites(preset));
  };

  if (favorites && favorites.includes(preset.id)) {
    return (
      <IconButton
        className={classes.favoriteButton}
        onClick={handleRemoveFromfavorites}
      >
        <FavoriteIcon className={classes.favoriteActive} />
      </IconButton>
    );
  } else {
    return (
      <IconButton
        className={classes.favoriteButton}
        onClick={handleAddTofavorites}
      >
        <FavoriteBorderIcon />
      </IconButton>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  favoriteButton: {},
  favoriteActive: {
    color: "#F65C5C",
  },
}));
