import {
  Button,
  Typography,
  makeStyles,
  Card,
  CardActionArea,
  Divider,
  CardContent,
  CardActions,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Author } from "../Author";
import { FavoriteButton } from "../../components/FavoriteButton";
import { useHistory } from "react-router";
import { useFirestoreConnect } from "react-redux-firebase";
import PresetRating from "../PresetRating/PresetRating";

export default function PresetTile(props) {
  const { preset, showGameCover = false } = props;

  useFirestoreConnect([
    {
      collection: `games`,
      doc: preset.gameId,
    },
    {
      collection: `users`,
      doc: preset.author,
    },
  ]);

  const auth = useSelector((state) => state.firebase.auth);

  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[preset.gameId]
  );

  const author = useSelector(
    ({ firestore: { data } }) => data.users && data.users[preset.author]
  );

  const history = useHistory();
  const classes = useStyles({
    cover: game && game.thumbnail,
    background: game && game.background,
  });

  let uid = auth && auth.uid;

  const canEdit = preset.author === uid;

  const handleOnClick = (e) => {
    history.push(`/game/${preset.gameId}/${preset.id}`);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/game/${preset.gameId}/edit/${preset.id}`);
  };

  const generateThumbnailURL = (url) => {
    const index = url.lastIndexOf("%2F") + 3;
    const output = [url.slice(0, index), "thumb_", url.slice(index)].join("");
    return output;
  };

  const renderScorepadPreview = () => {
    return (
      <div className={classes.previewContent}>
        {preset.scoreSettings.map((scoreSetting, index) => {
          return (
            <Fragment key={index}>
              <div className={classes.previewRow}>
                {scoreSetting.image && scoreSetting.image.length > 0 && (
                  <img
                    src={generateThumbnailURL(scoreSetting.image)}
                    onError={(e) => {
                      e.target.src = scoreSetting.image;
                    }}
                    className={classes.icon}
                  />
                )}
                {scoreSetting.name}

                <div className={classes.previewTags}>
                  {scoreSetting.multiplier && scoreSetting.multiplier != 0 && (
                    <div className={classes.previewTag}>
                      x {scoreSetting.multiplier}
                    </div>
                  )}

                  {scoreSetting.negative && (
                    <div className={classes.previewTag}>always negative</div>
                  )}
                  {scoreSetting.allowNegative && !scoreSetting.negative && (
                    <div className={classes.previewTag}>can be negative</div>
                  )}

                  {scoreSetting.script && (
                    <div className={classes.previewTag}>script</div>
                  )}
                  {scoreSetting.ignoreScore && (
                    <div className={classes.previewTag}>ignore score</div>
                  )}
                  {scoreSetting.key && (
                    <div className={classes.previewTag}>
                      key: {scoreSetting.key}
                    </div>
                  )}
                  {scoreSetting.isHidden && (
                    <div className={classes.previewTag}>
                      hidden {scoreSetting.key}
                    </div>
                  )}
                </div>
              </div>

              <Divider />
            </Fragment>
          );
        })}
      </div>
    );
  };

  const renderActionArea = () => {
    return (
      <CardActions className={classes.cardActions} disableSpacing>
        {canEdit && <Button onClick={handleEdit}>Edit</Button>}
        <FavoriteButton preset={preset} />
        <PresetRating preset={preset} />
      </CardActions>
    );
  };

  const renderHeader = () => {
    return (
      <div className={classes.headerContainer}>
        {showGameCover && (
          <img src={game && game.thumbnail} className={classes.coverImage} />
        )}
        <div className={classes.headerTitlesContainer}>
          <Author author={author} leadingText="Scorepad by " />
          {preset && preset.description && (
            <Typography className={classes.descriptionLabel}>
              {preset && preset.description}
            </Typography>
          )}
        </div>
      </div>
    );
  };

  return (
    <Card key={preset && preset.id} className={classes.root}>
      <div className={classes.bg} />
      <CardActionArea onClick={handleOnClick}>
        <CardContent className={classes.content}>
          {renderHeader()}

          {renderScorepadPreview()}
        </CardContent>
      </CardActionArea>
      {renderActionArea()}
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 16,
    marginTop: 32,
    overflow: "hidden",
    position: "relative",
  },
  bg: {
    backgroundImage: (props) =>
      `url("${props.background ? props.background : props.cover}")`,
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    zIndex: 0,
    opacity: 0.5,
    filter: (props) => (props.background ? null : "blur(70px)"),
  },
  content: {
    padding: 16,
  },
  previewContent: {
    marginTop: 8,
  },
  previewRow: {
    padding: 6,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 22,
    height: 22,
    marginRight: 8,
    borderRadius: 2,
  },
  cardActions: {
    backgroundColor: "black",
    justifyContent: "space-evenly",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  headerTitlesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  coverImage: {
    display: "flex",
    objectFit: "contain",
    maxHeight: 100,
    filter: "drop-shadow(0px 5px 5px  #00000077)",
    marginRight: 16,
    marginBottom: 16,
  },
  descriptionLabel: {
    fontSize: 18,
    backgroundColor: "#33333333",
    padding: 8,
    width: "100%",
    marginTop: 8,
    flex: 1,
    borderRadius: 4,
  },
  previewTags: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
  },
  previewTag: {
    padding: "2px 5px 2px 5px",
    borderRadius: 8,
    border: "1px solid #FFFFFF44",
    fontSize: 10,
    opacity: 0.7,
    backgroundColor: "#FFFFFF33",
    marginRight: 4,
  },
}));
