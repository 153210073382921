import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { presetEditorActions } from "../../features/presetEditor";
import { ImageField } from "../../components/ImageField";
import clsx from "clsx";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { Tooltip } from "../../components/Tooltip";
import AdvancedScoreSettings from "./AdvancedScoreSettings";

export default function ScoreSettingEditorDialog() {
  const dispatch = useDispatch();
  const showScoreSettingsEditor = useSelector(
    (state) => state.presetEditor.showScoreSettingsEditor
  );
  const currentScoreSettings = useSelector(
    (state) => state.presetEditor.currentScoreSettings
  );

  const classes = useStyles();

  const handleClose = (e) => {
    dispatch(presetEditorActions.closeScoreSettings());
  };

  const handleValueChanged = (e) => {
    dispatch(
      presetEditorActions.editScoreSettingsField(e.target.id, e.target.value)
    );
  };

  const handleSave = (e) => {
    if (currentScoreSettings.name && currentScoreSettings.name.length > 0) {
      dispatch(presetEditorActions.saveScoreSettings(currentScoreSettings));
    }
  };

  const handleUploadFile = (file) => {
    dispatch(
      presetEditorActions.uploadScoreSettingImage(currentScoreSettings, file)
    );
  };

  return (
    <Dialog
      PaperProps={{ className: classes.popupPaper }}
      open={showScoreSettingsEditor}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.popup}>
        <Typography className={classes.title} align="center">
          Score Settings
        </Typography>

        <div className={classes.content}>
          <ImageField
            className={classes.icon}
            imageURL={currentScoreSettings && currentScoreSettings.image}
            onSelectFile={handleUploadFile}
          />
          <div className={classes.fieldsContent}>
            <TextField
              id="name"
              value={currentScoreSettings ? currentScoreSettings.name : ""}
              label="Name"
              onChange={handleValueChanged}
              className={classes.textfield}
              autoComplete="off"
              fullWidth
            />
            <TextField
              id="description"
              value={
                currentScoreSettings ? currentScoreSettings.description : ""
              }
              label="Description"
              onChange={handleValueChanged}
              className={classes.textfield}
              autoComplete="off"
              fullWidth
            />
          </div>
        </div>

        <AdvancedScoreSettings scoreSettings={currentScoreSettings} />

        <div className={classes.buttonsContainer}>
          <Button
            onClick={handleClose}
            className={clsx(classes.button, classes.cancelButton)}
            variant="contained"
          >
            <ClearIcon />
          </Button>
          <Button
            onClick={handleSave}
            className={clsx(classes.button, classes.saveButton)}
            variant="contained"
            disabled={
              !currentScoreSettings ||
              !currentScoreSettings.name ||
              currentScoreSettings.name.length === 0
            }
          >
            <DoneIcon />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minWidth: "90%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 600,
    },
  },
  popupPaper: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("md")]: {},
    overflowY: "auto",
  },
  content: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  fieldsContent: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 30,
  },
  textfield: {
    flex: 1,
    [theme.breakpoints.down("sm")]: { marginTop: 8 },
    [theme.breakpoints.up("md")]: { margin: 16 },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 32,
    padding: 8,
    paddingLeft: 32,
    paddingRight: 32,
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
    color: theme.palette.error.contrastText,
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
    color: theme.palette.error.contrastText,
  },
  multiplierContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingLeft: 16,
  },
  advancedSettingsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    minWidth: 128,
  },
}));
