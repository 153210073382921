import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorPickerbutton } from "../../components/ColorPickerButton";
import { presetEditorActions } from "../../features/presetEditor";
import randomcolor from "randomcolor";
import AddBoxIcon from "@material-ui/icons/AddBox";
export default function PlayersSettingsEditor() {
  const playersSettings = useSelector(
    (state) => state.presetEditor.playersSettings
  );
  const forceUniqueColors = useSelector(
    (state) =>
      state.presetEditor.preset && state.presetEditor.preset.forceUniqueColors
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const colors = playersSettings ? playersSettings.colors : [];

  const handleColorChanged = (index, color) => {
    const updatedColors = [...colors];
    updatedColors[index] = color;
    dispatch(presetEditorActions.editPlayerSettingsColors(updatedColors));
  };

  const handleRemoveColor = (index) => {
    let updatedColors = [...colors];
    updatedColors.splice(index, 1);
    dispatch(presetEditorActions.editPlayerSettingsColors(updatedColors));
  };

  const handleAddColor = () => {
    const updatedColors = [...colors, randomcolor()];
    dispatch(presetEditorActions.editPlayerSettingsColors(updatedColors));
  };

  const handleUniquePlayersColorsChanged = (e) => {
    dispatch(
      presetEditorActions.editPlayerSettingsForceUniqueColors(
        Boolean(e.target.checked)
      )
    );
  };

  const renderAddColorButton = () => {
    return (
      <Button onClick={handleAddColor} className={classes.addButton}>
        <AddBoxIcon fontSize="large" className={classes.addIcon} />
      </Button>
    );
  };

  const renderColors = () => {
    return (
      colors &&
      colors.map((color, index) => {
        return (
          <ColorPickerbutton
            key={index}
            color={color}
            onChange={(updatedColor) => handleColorChanged(index, updatedColor)}
            onDelete={() => handleRemoveColor(index)}
          />
        );
      })
    );
  };

  return (
    <div className={classes.root}>
      <Typography>Players Colors</Typography>

      <FormControlLabel
        control={
          <Checkbox
            checked={forceUniqueColors}
            onChange={handleUniquePlayersColorsChanged}
            id="uniquePlayersColors"
          />
        }
        label="Force unique players colors"
      />

      <div className={classes.colorsContainer}>
        {renderColors()}
        {renderAddColorButton()}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16,
    marginBottom: 16,
  },
  colorsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  addButton: {
    margin: 8,
  },
  addIcon: {
    color: "gray",
    height: 64,
    width: 64,
  },
}));
