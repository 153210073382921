import { bggCosntants } from "./constants";

const initialState = {
  showLogPlay: false,

  isSavingLogPlay: false,
  logPlayResult: null,
  logPlayError: null,

  isConnectingAccount: false,
  connectAccountError: false,
};

export default function bggReducer(state = initialState, action) {
  switch (action.type) {
    case bggCosntants.SHOW_LOG_PLAY:
      return { ...state, showLogPlay: true };
    case bggCosntants.HIDE_LOG_PLAY:
      return { ...state, showLogPlay: false };

    case bggCosntants.SEND_LOG_PLAY:
      return { ...state, isSavingLogPlay: true, logPlayResult: null };
    case bggCosntants.LOG_PLAY_SUCCESS:
      return { ...state, isSavingLogPlay: false, logPlayResult: action.result };
    case bggCosntants.LOG_PLAY_FAILURE:
      return {
        ...state,
        logPlayError: action.error,
        logPlaySuccess: false,
        isSavingLogPlay: false,
      };
    case bggCosntants.RESET_LOG_PLAY:
      return {
        ...state,
        isSavingLogPlay: false,
        logPlayResult: null,
        logPlayError: null,
      };

    case bggCosntants.SEND_CONNECT_ACCOUNT:
      return {
        ...state,
        isConnectingAccount: true,
        connectAccountError: false,
      };
    case bggCosntants.CONNECT_ACCOUNT_SUCCESS:
      return { ...state, isConnectingAccount: false, logPlayError: null };
    case bggCosntants.CONNECT_ACCOUNT_FAILURE:
      return {
        ...state,
        connectAccountError: true,
        isConnectingAccount: false,
      };
    default:
      return state;
  }
}
