import { Button, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage, readFile } from "../../helpers";
import Resizer from "react-image-file-resizer";

var util = require("util");

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export default function ImageCropper(props) {
  const { imageSrc, onCrop, onCancel, round } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [objectUrl, setObjectUrl] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const classes = useStyles();

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  useEffect(() => {
    if (!objectUrl && imageSrc) {
      resizeFile(imageSrc).then((resizedImage) => {
        setObjectUrl(URL.createObjectURL(resizedImage));
      });
    }
    return () => {};
  }, [imageSrc, resizeFile]);

  const applyImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        objectUrl,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);

      //   console.log("croppedImage", util.inspect(croppedImage));

      onCrop(croppedImage);
    } catch (e) {
      console.error("error", e);
    }
  }, [objectUrl, croppedAreaPixels, rotation]);

  return (
    <div className={classes.root}>
      <div className={classes.corpper}>
        <Cropper
          image={objectUrl}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          zoomSpeed={0.1}
          showGrid={false}
          minZoom={0.5}
          cropShape={round ? "round" : "rect"}
          cropSize={{ width: 200, height: 200 }}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          onClick={applyImage}
          variant="contained"
          color="primary"
          className={classes.acceptButton}
        >
          OK
        </Button>
        <Button
          onClick={onCancel}
          variant="contained"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: { padding: 16, width: "100%" },
  corpper: {
    marginBottom: 16,
    overflow: "hidden",
    position: "relative",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 316,
    },
    [theme.breakpoints.up("md")]: {
      width: 316,
      height: 316,
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  acceptButton: {
    marginRight: 8,
    flex: 1,
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
    color: theme.palette.error.contrastText,
    marginLeft: 8,
    flex: 1,
  },
}));
