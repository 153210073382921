import { Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const Disclaimers = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider />
      <Typography className={classes.disclaimerText} align="center">
        This site is not associated, authorized, endorsed by, or in any way
        officially connected with any of the board games and products that are
        shown in it, or any of third subsidiaries or its affiliates.
        <br /> <br />
        **FAIR USE**
        <br /> Copyright Disclaimer under section 107 of the Copyright Act 1976,
        allowance is made for “fair use” for purposes such as criticism,
        comment, news reporting, teaching, scholarship, education and research.
        Fair use is a use permitted by copyright statute that might otherwise be
        infringing. Non-profit, educational or personal use tips the balance in
        favor of fair use.
      </Typography>
    </div>
  );
};

export default Disclaimers;

const useStyles = makeStyles((theme) => ({
  root: { margin: 16 },
  disclaimerText: { fontSize: 12, color: "gray" },
}));
