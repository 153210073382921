import { gameScoringConstatns } from "./constants";
import { FieldValue } from "../../firebase";
import { calculateTotal } from "../../components/ScoreSummary/calculateScore";

export const gameScoringActions = {
  setPreset,
  clearGame,
  addPlayer,
  removePlayer,
  reorderPlayers,
  editPlayer,
  startScoring,
  setScore,
  quit,
  overrideScoreMethod,
  logScoreSummary,
  logBGGLogGame,
};

function setPreset(preset) {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: gameScoringConstatns.SELECT_GAME, preset });

    const auth = getState().firebase.auth;
    const profile = getState().firebase.profile;
    // if (profile && profile.role === "admin") {
    //   return;
    // }
    if (preset && auth) {
      const play = {
        preset: preset.id,
        game: preset.gameId,
        uid: auth.uid,
        completed: false,
        createdAt: new Date(),
      };

      const result = await getFirebase()
        .firestore()
        .collection(`plays`)
        .add(play);

      dispatch({
        type: gameScoringConstatns.SET_GAME_SESSION,
        session: result.id,
      });
    }
  };
}
function clearGame() {
  return { type: gameScoringConstatns.CLEAR_GAME };
}
function addPlayer(player) {
  return { type: gameScoringConstatns.ADD_PLAYER, player };
}
function removePlayer(player) {
  return { type: gameScoringConstatns.REMOVE_PLAYER, player };
}
function reorderPlayers(players) {
  return { type: gameScoringConstatns.REORDER_PLAYERS, players };
}
function editPlayer(player) {
  return { type: gameScoringConstatns.EDIT_PLAYER, player };
}

function startScoring() {
  return async (dispatch, getState, getFirebase) => {
    const started = getState().gameScoring.started;
    const session = getState().gameScoring.session;
    const players = getState().gameScoring.players;

    const auth = getState().firebase.auth;
    if (session && !started && auth) {
      getFirebase().firestore().collection(`plays`).doc(session).set(
        {
          started: true,
          players: players,
        },
        { merge: true }
      );

      const playersNames = players.map((player) => player.name);
      getFirebase()
        .firestore()
        .collection(`users_data`)
        .doc(auth.uid)
        .set(
          { players: FieldValue.arrayUnion(...playersNames) },
          { merge: true }
        );
    }
    dispatch({ type: gameScoringConstatns.START_SCORING });
  };
}

function setScore(score, scoringIndex) {
  return { type: gameScoringConstatns.SET_SCORE, score, scoringIndex };
}
function quit() {
  return { type: gameScoringConstatns.QUIT };
}
function overrideScoreMethod(scoreMethod) {
  return { type: gameScoringConstatns.OVERRIDE_SCORE_METHOD, scoreMethod };
}
function logScoreSummary() {
  return async (dispatch, getState, getFirebase) => {
    const completed = getState().gameScoring.completed;
    const session = getState().gameScoring.session;
    const scorepad = getState().gameScoring.scorepad;
    const players = getState().gameScoring.players;

    const scores = scorepad.map((score) => {
      return { i: `${score.player.id}_${score.scoring.id}`, s: score.value };
    });

    const playersScores = players.map((player) => {
      return {
        name: player.name ? player.name : null,
        color: player.color ? player.color : null,
        score: calculateTotal(player, scorepad),
      };
    });
    console.log(playersScores);
    const auth = getState().firebase.auth;
    if (session && !completed && auth) {
      await getFirebase().firestore().collection(`plays`).doc(session).set(
        {
          completed: true,
          endtAt: new Date(),
          scores: scores,
          players: playersScores,
        },
        { merge: true }
      );
    }

    dispatch({ type: gameScoringConstatns.LOG_SCORE_SUMMARY });
  };
}

function logBGGLogGame(bggLogGameId) {
  return async (dispatch, getState, getFirebase) => {
    const completed = getState().gameScoring.completed;
    const session = getState().gameScoring.session;

    const auth = getState().firebase.auth;
    if (session && auth && bggLogGameId) {
      await getFirebase()
        .firestore()
        .collection(`plays`)
        .doc(session)
        .set({ bggLog: bggLogGameId }, { merge: true });
    }

    dispatch({ type: gameScoringConstatns.LOG_BGGLOG, logId: bggLogGameId });
  };
}
