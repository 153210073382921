import {
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { presetEditorActions } from "../../features/presetEditor";
import { MessagePopup } from "../../components/Message Popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { reorder, useDraggableInPortal } from "../../helpers";
import AddBoxIcon from "@material-ui/icons/AddBox";
import clsx from "clsx";

export default function ScoreSettingsListEditor() {
  const [deleteScoreSetting, setDeleteScoreSetting] = useState(null);
  const dispatch = useDispatch();
  const renderDraggable = useDraggableInPortal();
  const classes = useStyles();
  const scoreSettings = useSelector(
    (state) => state.presetEditor.scoreSettings
  );

  const handleAddPreset = (e) => {
    dispatch(presetEditorActions.createScoreSettings());
  };

  const handleEditScoreSetting = (e, scoreSetting) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(presetEditorActions.editScoreSettingsItem(scoreSetting));
  };

  const handleDeleteScoreSetting = (e) => {
    deleteScoreSetting &&
      dispatch(presetEditorActions.deleteScoreSettings(deleteScoreSetting));
    setDeleteScoreSetting(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderSettingsList = reorder(
      scoreSettings,
      result.source.index,
      result.destination.index
    );

    dispatch(presetEditorActions.reorderScoreSettingsList(reorderSettingsList));
  };

  const getListStyle = (isDraggingOver) => ({ paddingTop: 8 });

  const getItemStyle = (isDragging, draggableStyle) => ({
    paddingTop: 8,
    paddingBottom: 8,
    transform: isDragging ? "scale(1)" : "scale(1.5)",
    ...draggableStyle,
  });

  const renderDraggableList = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {scoreSettings &&
                scoreSettings.map((scoreSetting, index) => (
                  <Draggable
                    key={scoreSetting.id}
                    draggableId={scoreSetting.id}
                    index={index}
                  >
                    {renderDraggable((provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {renderScoreSettingsItem(
                          scoreSetting,
                          snapshot.isDragging
                        )}
                      </div>
                    ))}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const renderScoreSettingsItem = (scoreSetting, isDragging) => {
    return (
      <Paper
        elevation={0}
        key={scoreSetting.id}
        className={clsx(classes.settingsItem, isDragging && classes.dragging)}
      >
        <DragHandleIcon className={classes.dragIcon} />
        {scoreSetting && scoreSetting.image && (
          <img
            src={scoreSetting && scoreSetting.image}
            className={classes.scoreIcon}
          />
        )}
        <div>
          <Typography>{scoreSetting.name}</Typography>
          <Typography className={classes.scoreDescription}>
            {scoreSetting.description}
          </Typography>
          <div className={classes.tagsContainer}>
            {scoreSetting.negative && (
              <div className={classes.tag}>always negative</div>
            )}
            {scoreSetting.allowNegative && !scoreSetting.negative && (
              <div className={classes.tag}>can be negative</div>
            )}
            {scoreSetting.multiplier && scoreSetting.multiplier != 0 && (
              <div className={classes.tag}>x {scoreSetting.multiplier}</div>
            )}
            {scoreSetting.script && <div className={classes.tag}>script</div>}
            {scoreSetting.ignoreScore && (
              <div className={classes.tag}>ignore score</div>
            )}
            {scoreSetting.key && (
              <div className={classes.tag}>key: {scoreSetting.key}</div>
            )}

            {scoreSetting.isHidden && <div className={classes.tag}>hidden</div>}
          </div>
        </div>

        <div className={classes.editButtonsContainer}>
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={(e) => handleEditScoreSetting(e, scoreSetting)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={(e) => setDeleteScoreSetting(scoreSetting)}
            className={classes.deleteButton}
          >
            <DeleteForeverIcon />
          </IconButton>
        </div>
      </Paper>
    );
  };

  return (
    <List>
      <MessagePopup
        isOpen={deleteScoreSetting !== null}
        onClose={() => setDeleteScoreSetting(null)}
        message="Are you sure you wish to delete this score item?"
        onAccept={() => handleDeleteScoreSetting(deleteScoreSetting)}
        onCancel={() => setDeleteScoreSetting(null)}
      />

      {renderDraggableList()}

      <ListItem
        button
        onClick={handleAddPreset}
        className={classes.newScoreRowButton}
      >
        <AddBoxIcon fontSize="large" />
        <Typography variant="h5"> Score row</Typography>
      </ListItem>
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  settingsItem: {
    borderRadius: 20,
    display: "flex",
    flexDirection: "row",
    padding: 16,
    alignItems: "center",

    transform: "scale(1)",
  },
  dragging: {
    transform: "scale(1.05)",
    transition: "all 1s",
    filter: "drop-shadow(0px 10px 10px  #00000077)",
  },
  editButtonsContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
  },
  scoreIcon: {
    width: 64,
    height: 64,
    marginRight: 16,
    borderRadius: 5,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  newScoreRowButton: {
    justifyContent: "center",
    flex: 1,
    marginBottom: 16,
    marginTop: 8,
    border: "4px dashed #FFFFFF33",
    borderRadius: 20,
    minHeight: 90,
    backgroundColor: "#FFFFFF11",
  },
  dragIcon: {
    color: "#FFFFFF33",
    marginRight: 8,
  },
  scoreDescription: {
    color: "gray",
    fontSize: 14,
  },
  tagsContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    marginTop: 4,
  },
  tag: {
    padding: "2px 5px 2px 5px",
    borderRadius: 8,
    border: "1px solid #FFFFFF44",
    fontSize: 10,
    opacity: 0.7,
    backgroundColor: "#FFFFFF33",
    marginRight: 4,
  },
}));
