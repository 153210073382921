import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { bggActions } from "../../features/bgg";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

export default function ConnectBGG(props) {
  const { showInfo, showUnlink } = props;
  const classes = useStyles();

  const auth = useSelector((state) => state.firebase.auth);

  useFirestoreConnect([
    {
      collection: `users_data`,
      doc: auth.uid,
    },
  ]);

  const userData = useSelector(
    ({ firestore: { data } }) => data.users_data && data.users_data[auth.uid]
  );

  const isConnectingAccount = useSelector(
    (state) => state.bgg.isConnectingAccount
  );
  const connectAccountError = useSelector(
    (state) => state.bgg.connectAccountError
  );

  const isConnected = Boolean(userData && userData.bggcredentials !== null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setPassword("");
    setUsername("");
    return () => {};
  }, [isConnected]);

  const dispatch = useDispatch();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConnect = () => {
    username.length > 0 &&
      password.length > 0 &&
      dispatch(bggActions.connectAccount(username, password));
  };

  const handleUnlinkAccount = () => {
    dispatch(bggActions.unlinkAccount());
  };

  const connectForm = () => {
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {showInfo && (
            <Alert severity="info">
              Connecting your BGG account allow you to log plays directly from
              Spielpad.
            </Alert>
          )}
          <Typography></Typography>
          <TextField
            label="BGG username"
            id="bggusername"
            value={username}
            onChange={handleUsernameChange}
            variant="outlined"
            size="small"
            className={classes.textField}
            inputProps={{
              autoComplete: "new-username",
            }}
          />
          <TextField
            label="password"
            id="bggpassword"
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            size="small"
            className={classes.textField}
            type="password"
            autoComplete="off"
            autoCorrect="off"
            inputProps={{
              autoComplete: "new-password",
            }}
          />

          {connectAccountError && (
            <Alert severity="error" className={classes.error}>
              There was a problem connecting your account to BGG.
              <br /> check your username and password and try again
            </Alert>
          )}
        </div>
        <div>
          {isConnectingAccount ? (
            <CircularProgress className={classes.connectButton} />
          ) : (
            <Button
              onClick={handleConnect}
              variant="contained"
              className={classes.connectButton}
            >
              Connect
            </Button>
          )}
        </div>
      </div>
    );
  };

  const connected = () => {
    return (
      <div className={classes.content}>
        <span className={classes.connectedLabel}>
          <CheckCircleOutlineIcon
            className={classes.connectedIcon}
            fontSize="small"
          />
          <Typography align="center">
            connected to BGG as{" "}
            {userData.bggcredentials && userData.bggcredentials.username}
          </Typography>
        </span>

        {showUnlink && (
          <Button
            variant="outlined"
            className={classes.unlikButton}
            onClick={handleUnlinkAccount}
          >
            Unlink account
          </Button>
        )}
      </div>
    );
  };

  return isConnected ? connected() : connectForm();
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    backgroundColor: "#00000033",
    padding: 16,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  textField: {
    marginTop: 32,
  },
  connectButton: {
    margin: 8,
    marginTop: 16,
    marginBottom: 16,
  },
  unlikButton: {
    marginTop: 32,
  },
  error: {
    marginTop: 16,
    fontSize: 14,
  },
  connectedLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  connectedIcon: {
    color: theme.palette.success.main,
    marginRight: 8,
  },
}));
