export function calculateScore(score, scorepad) {
  return score.score;
}

const getKeyValue = (key, player, scorepad) => {
  const match = scorepad.find(
    (scoring) => scoring.scoring.key == key && scoring.player.id === player.id
  );
  return match && match.value;
};

const runScript = (currentValue, scoring, scorepad) => {
  let script = scoring.scoring.script.replaceAll("@", " @");
  const fields = script.match(/(@\S+\w)/gi);
  fields.forEach((field) => {
    const key = field.replace("@", "");
    if (key.includes("this")) {
      script = script.replaceAll(field, currentValue);
    } else if (key.includes("round")) {
      script = script.replaceAll(field, "Math.round");
    } else if (key.includes("floor")) {
      script = script.replaceAll(field, "Math.floor");
    } else if (key.includes("min")) {
      script = script.replaceAll(field, "Math.min");
    } else if (key.includes("max")) {
      script = script.replaceAll(field, "Math.max");
    } else if (key.includes("pow")) {
      script = script.replaceAll(field, "Math.pow");
    } else {
      const value = getKeyValue(key, scoring.player, scorepad);
      script = script.replaceAll(field, value ? value : 0);
    }
  });

  try {
    script = script.replaceAll("@", "");
    const result = eval(script);
    return result ? result : 0;
  } catch (error) {
    return 0;
  }
};

function calculateValue(scoring, scorepad) {
  let value = scoring.value;
  if (scoring.scoring.multiplier && scoring.scoring.multiplier != 0) {
    value = value * scoring.scoring.multiplier;
  }
  if (scoring.scoring.negative) {
    value = Math.abs(value) * -1;
  }
  if (scoring.scoring.script) {
    return runScript(value, scoring, scorepad);
  }
  return value;
}

export function calculateTotal(player, scorepad) {
  let totalScore = 0;
  scorepad.forEach((scoring) => {
    if (scoring.player.id === player.id) {
      totalScore +=
        !scoring.scoring.ignoreScore && calculateValue(scoring, scorepad);
    }
  });
  return totalScore;
}
