import {
  Avatar,
  Button,
  Dialog,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";

const feedbackCategoryType = {
  GENERAL: "GENERAL",
  BUG: "BUG",
  IDEA: "IDEA",
};

export default function FeedbackDialog(props) {
  const { open, onClose } = props;

  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackCategory, setFeedbackCategory] = useState(
    feedbackCategoryType.GENERAL
  );
  const [contactMail, setContactMail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const firestore = useFirestore();

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const classes = useStyles();

  const handleCategoryChanged = (e, value) => {
    setFeedbackCategory(value);
  };

  const handleDescriptionChanged = (e) => {
    setFeedbackText(e.target.value);
  };

  const handleEMailChanged = (e) => {
    setContactMail(e.target.value);
  };

  useEffect(() => {
    if (open) {
      setFeedbackText("");
      setFeedbackCategory(feedbackCategoryType.GENERAL);
      setContactMail("");
      setIsSending(false);
      setSuccess(false);
    }
    return () => {};
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleSendFeedback = async () => {
    const feedback = {
      uid: auth.uid,
      isAnonymous: auth.isAnonymous,
      contact: profile.isEmpty
        ? null
        : {
            name: profile && profile.displayName,
            email: profile && profile.email,
          },
      contactMail: contactMail,
      category: feedbackCategory,
      text: feedbackText,
      isNew: true,
    };

    setIsSending(true);
    try {
      await firestore.collection(`feedback`).add(feedback);
      setIsSending(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setIsSending(false);
      setSuccess(false);
    }
  };

  const renderContact = () => {
    if (auth.isAnonymous) {
      return (
        <TextField
          label="email"
          onChange={handleEMailChanged}
          className={classes.emailTextField}
        />
      );
    } else {
      return (
        <div className={classes.avatarContainer}>
          <Typography variant="caption">send as:</Typography>
          <Avatar src={profile.photoURL} className={classes.avatar} />
          <Typography className={classes.displatNameLabel}>
            {profile.displayName}
          </Typography>
        </div>
      );
    }
  };

  const renderFeedbackContent = () => {
    return (
      <Fragment>
        <IconButton onClick={handleClose} className={classes.cancelButton}>
          <ClearIcon fontSize="small" />
        </IconButton>
        <Typography align="center" variant="h5">
          Send Feedback
        </Typography>

        {renderContact()}

        <Typography variant="caption">select category:</Typography>

        <ToggleButtonGroup
          value={feedbackCategory}
          exclusive
          onChange={handleCategoryChanged}
          className={classes.toggleButtonGroup}
        >
          <ToggleButton
            value={feedbackCategoryType.GENERAL}
            className={classes.feedbackCategoryButton}
            classes={{ label: classes.feedbackCategoryLabel }}
          >
            General
          </ToggleButton>

          <ToggleButton
            value={feedbackCategoryType.BUG}
            className={classes.feedbackCategoryButton}
            classes={{ label: classes.feedbackCategoryLabel }}
          >
            Bug
          </ToggleButton>

          <ToggleButton
            value={feedbackCategoryType.IDEA}
            className={classes.feedbackCategoryButton}
            classes={{ label: classes.feedbackCategoryLabel }}
          >
            Idea
          </ToggleButton>
        </ToggleButtonGroup>
        <TextField
          id="description"
          label="Feedback text"
          multiline
          rows={3}
          value={feedbackText}
          variant="outlined"
          className={classes.descriptionTextField}
          onChange={handleDescriptionChanged}
        />
        <div className={classes.actionsButtonsContainer}>
          <Button
            onClick={handleSendFeedback}
            className={classes.sendButton}
            disabled={isSending}
          >
            Send
          </Button>
        </div>
      </Fragment>
    );
  };

  const renderSuccess = () => {
    return (
      <div className={classes.successContent}>
        <CheckCircleOutlineIcon className={classes.successIcon} />
        <Typography variant="h5" align="center">
          Feedback sent
          <br />
          thank you
        </Typography>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogPaper }}
    >
      {success ? renderSuccess() : renderFeedbackContent()}
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    [theme.breakpoints.down("sm")]: { margin: 16, width: "100%" },
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
    padding: 16,
  },
  emailTextField: {
    marginTop: 16,
    marginBottom: 16,
  },
  toggleButtonGroup: {
    flex: 1,
  },
  feedbackCategoryButton: {
    padding: 4,
    paddingLeft: 16,
    paddingRight: 16,
    textTransform: "none",
    flex: 1,
  },
  feedbackCategoryLabel: {},
  descriptionTextField: {
    marginTop: 16,
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    margin: 8,
  },
  displatNameLabel: {},
  actionsButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 16,
  },
  cancelButton: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 4,
  },
  sendButton: {
    flex: 1,
    maxWidth: 200,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: `${theme.palette.success.main}88`,
    },
  },
  successContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  successIcon: {
    color: theme.palette.success.main,
    width: 100,
    height: 100,
  },
}));
