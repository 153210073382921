import { Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Meepels from "./meepels.png";
import Jako from "./jako.png";
import Disclaimers from "./Disclaimers";
import { Buymecoffee } from "../../components/Buymecoffee";

export default function About() {
  const classes = useStyles();

  const handleFeedback = (e) => {
    const event = new Event("feedback");
    window.dispatchEvent(event);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img src={Jako} className={classes.cover} />

        <Typography className={classes.peregraph}>
          Hello and welcome to spiel-pad
          <br />
          This tool is designed to make board games score counting easy, quick
          and fun.
          <br />
          It's flexible enough and can fit almost any game and group of players.
        </Typography>
        <Typography className={classes.peregraph}>
          Use the{" "}
          <Link onClick={handleFeedback} href="#">
            Feedback
          </Link>{" "}
          button or{" "}
          <Link href="https://www.facebook.com/kobi.chariski" target="_blank">
            Contact me
          </Link>{" "}
          for any bug, requests or suggestion.
          <br />
          Check out my other mobile game -{" "}
          <Link
            href="https://kobichariski.wixsite.com/chromania"
            target="_blank"
          >
            Chromania
          </Link>
        </Typography>

        <Typography variant="caption" align="center">
          Designed and devloped by Kobi Chariski
        </Typography>

        <Buymecoffee />
      </div>

      <Disclaimers />
      <img src={Meepels} className={classes.image} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: { paddingTop: 16 },
    [theme.breakpoints.up("md")]: { paddingTop: 100 },
  },
  peregraph: {
    padding: 32,
  },
  image: {
    position: "fixed",
    zIndex: -1,
    width: "100%",
    opacity: 0.1,
    bottom: 0,
  },
  cover: {
    margin: "auto",
    height: 400,
  },
}));
