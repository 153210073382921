import { MainRoute } from "./pages/MainRoute";
import { Provider } from "react-redux";
import { store } from "./features/store";
import {
  ThemeProvider,
  createMuiTheme,
  CircularProgress,
} from "@material-ui/core";
import "./App.css";
import { AuthProvider } from "./components/providers";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore"; // <- needed if using firestore
import { fb as firebase } from "./firebase";
import "firebase/auth";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Varela Round",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 20,
  },
  palette: {
    type: "dark",
    primary: {
      main: "#01b523",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffea00",
    },
    background: {
      default: "#303030",
    },
  },
  contrastThreshold: 3,
});

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  profileFactory: (userData, profileData, firebase) => {
    //  console.log("use profile", userData, profileData, firebase);
    // how profiles are stored in database
    const { user } = userData;
    return {
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
  },
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

firebase.auth().onAuthStateChanged(async (user) => {
  if (!user) {
    firebase.auth().signInAnonymously();
  }
});

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <MainRoute />
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default App;
