import {
  gameScoringConstatns,
  scoreMethod as scoreMethodTypes,
} from "./constants";

const initialState = {
  preset: null,
  players: [],
  idPool: 0,
  scorepad: [],
  scoreMethod: null,
  session: null,
  started: false,
  completed: false,
};

const fillPlayersNames = (players) => {
  return players.map((player, index) => {
    if (!player.name || (player.name && player.name.length === 0)) {
      return { ...player, name: `Player ${index + 1}` };
    }
    return player;
  });
};

const generateScorepad = (scoreSettings, players, scoringMethod) => {
  let scorePad = [];
  switch (scoringMethod) {
    case scoreMethodTypes.BY_SCORE:
      scoreSettings.forEach((scoreSettings) => {
        players.forEach((player) => {
          scorePad.push({ player: player, scoring: scoreSettings, value: 0 });
        });
      });
      break;
    case scoreMethodTypes.BY_PLAYER:
    default:
      players.forEach((player) => {
        scoreSettings.forEach((scoreSettings) => {
          scorePad.push({ player: player, scoring: scoreSettings, value: 0 });
        });
      });
      break;
  }

  return scorePad;
};

export default function gameScoringReducer(state = initialState, action) {
  switch (action.type) {
    case gameScoringConstatns.SELECT_GAME:
      return {
        ...state,
        preset: action.preset,
        players: [],
        scorepad: [],
        scoreMethod:
          action.preset && action.preset.scoringMethod
            ? action.preset.scoringMethod
            : scoreMethodTypes.BY_PLAYER,
      };
    case gameScoringConstatns.SET_GAME_SESSION:
      return { ...state, session: action.session };
    case gameScoringConstatns.CLEAR_GAME:
      return {
        ...state,
        preset: null,
      };

    ///============ players ==========///
    case gameScoringConstatns.ADD_PLAYER:
      return {
        ...state,
        idPool: state.idPool + 1,
        players: [
          ...state.players,
          { ...action.player, id: state.idPool.toString() },
        ],
      };
    case gameScoringConstatns.REMOVE_PLAYER: {
      return {
        ...state,
        players: state.players.filter(
          (player) => player.id !== action.player.id
        ),
      };
    }
    case gameScoringConstatns.REORDER_PLAYERS: {
      return {
        ...state,
        players: action.players,
      };
    }
    case gameScoringConstatns.EDIT_PLAYER: {
      return {
        ...state,
        players: state.players.map((player) => {
          if (player.id === action.player.id) {
            return action.player;
          }
          return player;
        }),
      };
    }

    ///============ scoring ==========///
    case gameScoringConstatns.START_SCORING:
      const players = fillPlayersNames(state.players);
      return {
        ...state,
        started: true,
        players: players,
        scorepad: generateScorepad(
          state.preset.scoreSettings,
          players,
          state.scoreMethod ? state.scoreMethod : state.preset.scoringMethod
        ),
      };

    case gameScoringConstatns.SET_SCORE:
      return {
        ...state,
        scorepad: state.scorepad.map((item, index) => {
          if (action.scoringIndex === index) {
            return { ...item, value: action.score };
          }
          return item;
        }),
      };
    case gameScoringConstatns.QUIT:
      return initialState;

    case gameScoringConstatns.OVERRIDE_SCORE_METHOD:
      return { ...state, scoreMethod: action.scoreMethod };

    case gameScoringConstatns.LOG_SCORE_SUMMARY:
      return { ...state, completed: true };
    default:
      return state;
  }
}
