import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { AccountButton } from "../AccountButton";
import { NavLink, useLocation } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import PageviewIcon from "@material-ui/icons/Pageview";
import FavoriteIcon from "@material-ui/icons/Favorite";
import InfoIcon from "@material-ui/icons/Info";
import WalktroughTip from "../WalktoughTip/WalktroughTip";
import { ReactComponent as Logo } from "./Logo.svg";
import { useSelector } from "react-redux";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import clsx from "clsx";

export default function MainMenu() {
  const profile = useSelector((state) => state.firebase.profile);
  const classes = useStyles();
  const location = useLocation();

  const showExtraClass = () => {
    return location.pathname.includes("findgame") ? classes.showExtra : "";
  };

  return (
    <nav className={classes.sideMenu}>
      <Typography className={clsx(classes.title, showExtraClass())}>
        <Logo className={classes.logo} />
        Spiel-Pad
      </Typography>

      <div className={classes.sideMenuButtonContent}>
        <WalktroughTip
          message={`Welcome to spielpad - online scorepads for board games. select a game from the list or find a new on here`}
          gotItButton={true}
          tipId="tip.menu.find.game"
          anchorElName={`findGameButton`}
          popover={true}
        />

        <Button
          className={classes.sideMenuButton}
          component={NavLink}
          to={`/`}
          exact
          activeClassName={classes.sideMenuButtonActive}
          classes={{ label: classes.sideButtonLabel }}
        >
          <HomeIcon className={classes.buttonIcon} />
          <span className={classes.buttonLabel}>Games</span>
        </Button>

        <Button
          className={classes.sideMenuButton}
          component={NavLink}
          to={`/findgame`}
          exact
          activeClassName={classes.sideMenuButtonActive}
          classes={{ label: classes.sideButtonLabel }}
          id="findGameButton"
        >
          <PageviewIcon className={classes.buttonIcon} />
          <span className={classes.buttonLabel}>Find game</span>
        </Button>

        <Button
          className={classes.sideMenuButton}
          component={NavLink}
          to={`/favorites`}
          exact
          activeClassName={classes.sideMenuButtonActive}
          classes={{ label: classes.sideButtonLabel }}
        >
          <FavoriteIcon className={classes.buttonIcon} />
          <span className={classes.buttonLabel}>Favorites</span>
        </Button>

        <Button
          className={classes.sideMenuButton}
          component={NavLink}
          to={`/about`}
          exact
          activeClassName={classes.sideMenuButtonActive}
          classes={{ label: classes.sideButtonLabel }}
        >
          <InfoIcon className={classes.buttonIcon} />
          <span className={classes.buttonLabel}>About</span>
        </Button>

        {profile && profile.role === "admin" && (
          <Button
            className={classes.sideMenuButton}
            component={NavLink}
            to={`/admin`}
            exact
            activeClassName={classes.sideMenuButtonActive}
            classes={{ label: classes.sideButtonLabel }}
          >
            <SettingsApplicationsIcon className={classes.buttonIcon} />
            <span className={classes.buttonLabel}>Admin</span>
          </Button>
        )}
      </div>
      <AccountButton className={showExtraClass()} />
    </nav>
  );
}

const useStyles = makeStyles((theme) => ({
  sideMenu: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      // paddingTop: 100,
    },
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      top: 0,
      left: 0,
      paddingTop: 200,
      paddingLeft: 12,
      paddingRight: 12,
      width: "30%",
      height: "100%",
    },
  },

  sideMenuButtonContent: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.background.default,
      position: "fixed",
      bottom: 0,
      left: 0,
      zIndex: 100,
      display: "flex",
      flexDirection: "row",
      minHeight: 66,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
    },
  },
  sideMenuButton: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up("md")]: { margin: "auto" },
  },
  sideButtonLabel: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  sideMenuButtonActive: {
    backgroundColor: "#FFFFFF33",
    "&:hover": {
      backgroundColor: "#FFFFFF33",
    },
  },
  title: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    fontSize: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 100,
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logo: {
    marginRight: 16,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 50,
    },
    [theme.breakpoints.up("md")]: {
      height: 128,
      width: 128,
    },
  },
  buttonLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
    [theme.breakpoints.up("md")]: {},
  },
  buttonIcon: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  showExtra: {
    [theme.breakpoints.down("sm")]: { display: "none" },
    [theme.breakpoints.up("md")]: {},
  },
}));
