import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  gameScoringActions,
  scoreMethod as scoreMethodType,
} from "../../features/gameScoring";
import { Typography, Button, makeStyles, Divider } from "@material-ui/core";
import PlayersList from "./PlayersList";
import { GamePageContainer } from "../GamePageContainer";
import WalktroughTip from "../WalktoughTip/WalktroughTip";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import InputIcon from "@material-ui/icons/Input";

export default function PlayersSelection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { gameId, presetId } = useParams();
  const players = useSelector((state) => state.gameScoring.players);
  const scoreMethod = useSelector((state) => state.gameScoring.scoreMethod);

  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[gameId]
  );
  const classes = useStyles({ cover: game && game.cover });

  const handleStartScoring = () => {
    dispatch(gameScoringActions.startScoring());
    history.push(`/game/${gameId}/${presetId}/score/${0}`);
  };

  const handleScoreMethodChanged = (e, value) => {
    dispatch(gameScoringActions.overrideScoreMethod(value));
  };

  const renderNavigationButtons = () => {
    return (
      <div className={classes.actionButtonsContainer}>
        {renderScoringMethod()}

        <Button
          onClick={handleStartScoring}
          className={classes.startScoringButton}
          disabled={players.length === 0}
          variant="contained"
          color="primary"
        >
          Start Scoring
        </Button>
      </div>
    );
  };

  const renderScoringMethod = () => {
    return (
      <div
        className={classes.scoringMethodContainer}
        id="scoreingMethodContainer"
      >
        <WalktroughTip
          message={`you can select your perfered scoring order - by players or by scoring rows`}
          gotItButton={true}
          tipId="tip.scoring.order"
          dependencyTipId="tip.scoring.order"
          anchorElName={`scoringOrderLabel`}
          popover={true}
        />

        <Typography id="scoringOrderLabel">Scoring Order</Typography>

        <ToggleButtonGroup
          value={scoreMethod}
          exclusive
          onChange={handleScoreMethodChanged}
        >
          <ToggleButton
            value={scoreMethodType.BY_PLAYER}
            className={classes.scoringMethodToggleButton}
            classes={{ label: classes.scoringMethodToggleButtonLabel }}
          >
            <SystemUpdateAltIcon />
            By Player
          </ToggleButton>
          <ToggleButton
            value={scoreMethodType.BY_SCORE}
            className={classes.scoringMethodToggleButton}
            classes={{ label: classes.scoringMethodToggleButtonLabel }}
          >
            <InputIcon />
            By Score
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  return (
    <GamePageContainer
      game={game}
      backURL={`/game/${gameId}`}
      showHeader={true}
    >
      <Typography align="center">Set Players</Typography>
      <Divider />
      <WalktroughTip
        message={`add  players to track score for ${
          game && game.name
        }, you can set name and color for each player`}
        gotItButton={true}
        tipId="tip.players.select.1 "
      />
      <PlayersList />
      {renderNavigationButtons()}
    </GamePageContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  startScoringButton: {
    margin: "auto",
    display: "flex",
    padding: 16,
    paddingRight: 64,
    paddingLeft: 64,
    flex: 1,
    marginTop: 32,

    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
    [theme.breakpoints.up("md")]: {
      height: 74,
      marginRight: 32,
    },
  },
  scoringMethodContainer: {
    display: "flex",
    flex: 1,
    marginLeft: 16,
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      flexDirection: "row",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  scoringMethodToggleButtonLabel: {
    display: "flex",
    flexDirection: "column",
    fontSize: 10,
  },
  actionButtonsContainer: {
    display: "flex",
    marginBottom: 32,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      height: 100,
    },
  },
}));
