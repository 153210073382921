import {
  Typography,
  Button,
  Dialog,
  makeStyles,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

export default function MessagePopup(props) {
  const {
    isOpen,
    onClose,
    title,
    message,
    onAccept,
    onCancel,
    acceptButtonProps,
    cancelButtonProps,
  } = props;
  const classes = useStyles();

  const handleClose = (e) => {
    onClose && onClose();
  };

  const renderActionButtons = () => {
    return (
      <div className={classes.buttonsContainer}>
        {onAccept && (
          <Button
            onClick={onAccept}
            className={clsx(
              classes.button,
              acceptButtonProps && acceptButtonProps.className
            )}
          >
            {acceptButtonProps && acceptButtonProps.title
              ? acceptButtonProps.title
              : "Accept"}
          </Button>
        )}
        {onCancel && (
          <Button
            onClick={onCancel}
            className={clsx(
              classes.button,
              cancelButtonProps && cancelButtonProps.className
            )}
          >
            {cancelButtonProps && cancelButtonProps.title
              ? cancelButtonProps.title
              : "Cancel"}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.popup}>
        <Typography align="center" variant="h5">
          {title}
        </Typography>
        <Divider className={classes.divider} />
        {typeof message === "string" ? (
          <Typography>{message}</Typography>
        ) : (
          message
        )}
        {renderActionButtons()}
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      minWidth: "90%",
    },
    [theme.breakpoints.up("md")]: { minWidth: 500 },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: { justifyContent: "center" },
  },
  divider: {
    marginBottom: 16,
  },
  button: {
    marginRight: 8,
    marginLeft: 8,
  },
}));
