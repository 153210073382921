import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameScoringActions } from "../../features/gameScoring";
import { makeStyles, TextField, Typography } from "@material-ui/core";

export default function ScoringInputField(props) {
  const { scoringIndex, isActive, onSubmit, multiplier = 0, negative } = props;
  const [minus, setMinus] = useState(false);
  const dispatch = useDispatch();

  const scorepad = useSelector((state) => state.gameScoring.scorepad);
  const score = scorepad[scoringIndex].value;

  const classes = useStyles();

  const inputRef = useRef();

  useEffect(() => {
    setMinus(score < 0);
    return () => {};
  }, [score]);

  useEffect(() => {
    const timeout =
      isActive &&
      setTimeout(() => {
        //  const input = document.getElementById(`input_${scoringIndex}`);
        if (inputRef) {
          inputRef.current.focus();
        }
      }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [isActive]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit && onSubmit();
    }
  };

  const handleFocus = (e) => {
    setTimeout(() => {
      if (inputRef) {
        inputRef.current.setSelectionRange(1000, 1000);
      }
    }, 10);
  };

  const handleFieldChange = (e) => {
    const value = e.target.value;
    if (value.includes("-")) {
      dispatch(
        gameScoringActions.setScore(
          Math.abs(score) * (!minus ? -1 : 1),
          scoringIndex
        )
      );
    }
    !isNaN(value) &&
      dispatch(
        gameScoringActions.setScore(
          Number(e.target.value * (minus ? -1 : 1)),
          scoringIndex
        )
      );
  };

  return (
    <div className={classes.root}>
      {negative ||
        (minus && <Typography className={classes.negativeText}>-</Typography>)}
      <TextField
        id={`input_${scoringIndex}`}
        value={score ? Math.abs(score) : ""}
        onChange={handleFieldChange}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        className={classes.textField}
        inputProps={{
          className: classes.textFieldInputProps,
          inputMode: "numeric",
        }}
        inputRef={inputRef}
      />
      {multiplier !== null && multiplier != 0 && (
        <Typography className={classes.multiplierText}>
          X {multiplier}
        </Typography>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    maxWidth: 250,
  },
  multiplierText: {
    fontSize: 60,
    opacity: 0.3,
    marginRight: 0,
  },
  negativeText: {
    fontSize: 60,
  },
  textField: {
    flex: 1,

    width: 150,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 16,
      marginBottom: 16,
    },
  },
  textFieldInputProps: {
    textAlign: "center",
    fontSize: 60,
  },
}));
