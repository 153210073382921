import axios from "axios";
import { analytics } from "../../firebase";
import { gameScoringActions } from "../gameScoring";
import { userConstants } from "../user";
import { bggCosntants } from "./constants";

export const bggActions = {
  showLogPlay,
  hideLogPlay,
  connectAccount,
  unlinkAccount,

  logPlay,

  resetLogPlay,
};

function showLogPlay() {
  return async (dispatch, getState) => {
    const auth = getState().firebase.auth;
    if (auth.uid && !auth.isAnonymous) {
      dispatch({ type: bggCosntants.SHOW_LOG_PLAY });
    } else {
      dispatch({ type: userConstants.SHOW_REQUIRE_LOGIN });
    }
  };
}

function hideLogPlay() {
  return { type: bggCosntants.HIDE_LOG_PLAY };
}

function connectAccount(username, password) {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: bggCosntants.SEND_CONNECT_ACCOUNT });
    const data = { username: username, password: password };
    const headers = { "Content-Type": "application/xml; charset=utf-8" };

    try {
      const result = await axios.post(
        `https://g8df95zuvg.execute-api.us-east-1.amazonaws.com/dev/bgg/connectAccount`,
        data,
        headers
      );
      if (result.data && result.data.credentials) {
        const uid = getState().firebase.auth.uid;
        await getFirebase().firestore().collection(`users_data`).doc(uid).set(
          {
            bggcredentials: result.data.credentials,
          },
          { merge: true }
        );

        const firebaseResult = await getFirebase()
          .firestore()
          .collection(`users_data`)
          .doc(uid)
          .get();

        if (firebaseResult.data().bggcredentials) {
          dispatch({ type: bggCosntants.CONNECT_ACCOUNT_SUCCESS });
          analytics.logEvent("bgg_connect", {});
        } else {
          dispatch({ type: bggCosntants.CONNECT_ACCOUNT_FAILURE });
        }
      } else {
        dispatch({ type: bggCosntants.CONNECT_ACCOUNT_FAILURE });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: bggCosntants.CONNECT_ACCOUNT_FAILURE });
    }
  };
}

function unlinkAccount() {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    await getFirebase().firestore().collection(`users_data`).doc(uid).set(
      {
        bggcredentials: null,
      },
      { merge: true }
    );

    const firebaseResult = await getFirebase()
      .firestore()
      .collection(`users_data`)
      .doc(uid)
      .get();
  };
}

function logPlay(playPayload) {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: bggCosntants.SEND_LOG_PLAY });
    try {
      const uid = getState().firebase.auth.uid;
      const firebaseResult = await getFirebase()
        .firestore()
        .collection(`users_data`)
        .doc(uid)
        .get();

      const credentials = firebaseResult.data().bggcredentials;
      if (credentials) {
        const data = {
          username: credentials.username,
          password: credentials.password,
          payload: playPayload,
        };

        const headers = { "Content-Type": "application/xml; charset=utf-8" };
        const result = await axios.post(
          `https://g8df95zuvg.execute-api.us-east-1.amazonaws.com/dev/bgg/logPlay`,
          data,
          headers
        );

        if (result.data.playid) {
          analytics.logEvent("bgg_log_play", {
            game: playPayload.objectid,
          });
          dispatch({
            type: bggCosntants.LOG_PLAY_SUCCESS,
            result: result.data,
          });
          dispatch(gameScoringActions.logBGGLogGame(result.data.playid));
        } else {
          dispatch({
            type: bggCosntants.LOG_PLAY_FAILURE,
            error: result.data.message,
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: bggCosntants.LOG_PLAY_FAILURE, error });
    }
  };
}

function resetLogPlay() {
  return { type: bggCosntants.RESET_LOG_PLAY };
}
