import {
  Typography,
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import React from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";

export default function CreatePresetTile(props) {
  const { preset, onClick, game, title } = props;

  const classes = useStyles({
    cover: game && game.thumbnail,
    background: game && game.background,
  });

  const handleOnClick = (e) => {
    onClick && onClick(preset);
  };

  return (
    <Card key={preset && preset.id} className={classes.root}>
      <div className={classes.bg} />
      <CardActionArea onClick={handleOnClick}>
        <CardContent className={classes.content}>
          <Typography variant="h5" align="center" className={classes.title}>
            {title}
          </Typography>
          <AddBoxIcon className={classes.addIcon} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 16,
    marginTop: 32,
    overflow: "hidden",
    position: "relative",
    borderRadius: 10,
  },
  bg: {
    backgroundImage: (props) =>
      `url("${props.background ? props.background : props.cover}")`,
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    zIndex: 0,
    opacity: 0.3,
    filter: (props) => (props.background ? "" : "blur(70px)"),
  },
  content: {
    padding: 16,
    border: "5px dashed #ffffff44",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    opacity: 0.6,
  },
  addIcon: {
    width: 64,
    height: 64,
    marginTop: 16,
    opacity: 0.6,
  },
}));
