import { auth } from "./Firebase";
import firebase from "firebase/app";

export const signOut = () => {
  auth
    .signOut()
    .then(() => {
      console.log("logged out");
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const loginWithEmail = (email, password, callback) => {
  auth
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      callback && callback(res.user);
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const signupWithEmail = (email, password, callback) => {
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      callback && callback(res.user);
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const signInWithGoogle = (callback) => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  auth
    .signInWithPopup(googleProvider)
    .then((res) => {
      callback && callback(res.user);
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const signInWithFacebook = () => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  auth
    .signInWithPopup(facebookProvider)
    .then((res) => {})
    .catch((error) => {
      console.log(error.message);
    });
};

export const logout = () => {
  auth
    .signOut()
    .then(() => {
      console.log("logged out");
    })
    .catch((error) => {
      console.log(error.message);
    });
};
