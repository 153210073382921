export const userConstants = {
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",

  LOGIN_ERROR: "LOGIN_ERROR",

  SET_LOGIN_POPUP: "SET_LOGIN_POPUP",
  SET_SIGNUP_POPUP: "SET_SIGNUP_POPUP",

  SHOW_LOGIN: "SHOW_LOGIN",
  CLOSE_LOGIN: "CLOSE_LOGIN",

  SHOW_REQUIRE_LOGIN: "SHOW_REQUIRE_LOGIN",
  CLOSE_REQUIRE_LOGIN: "CLOSE_REQUIRE_LOGIN",

  FETCH_USER_REF_REQUEST: "FETCH_USER_REF_REQUEST",
  FETCH_USER_REF_SUCCESS: "FETCH_USER_REF_SUCCESS",
  FETCH_USER_REF_FAILURE: "FETCH_USER_REF_FAILURE",

  SHOW_USER_SETTINGS: "SHOW_USER_SETTINGS",
  CLOSE_USER_SETTINGS: "CLOSE_USER_SETTINGS",

  ADD_PRESET_TO_FAVORITES: "ADD_PRESET_TO_FAVORITES",
  REMOVE_PRESET_FROM_FAVORITES: "REMOVE_PRESET_FROM_FAVORITES",

  UPDATED_PROFILE: "UPDATED_PROFILE",
};
