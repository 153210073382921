export const gameScoringConstatns = {
  SELECT_GAME: "SELECT_GAME",
  SET_GAME_SESSION: "SET_GAME_SESSION",
  CLEAR_GAME: "CLEAR_GAME",
  ADD_PLAYER: "ADD_PLAYER",
  REMOVE_PLAYER: "REMOVE_PLAYER",
  REORDER_PLAYERS: "REORDER_PLAYERS",
  EDIT_PLAYER: "EDIT_PLAYER",

  START_SCORING: "START_SCORING",
  NEXT_SCORING: "NEXT_SCORING",
  PREVIUS_SCORING: "PREVIUS_SCORING",
  FINISH_SCORING: "FINISH_SCORING",

  NAVIGATE_TO_SCORE: "NAVIGATE_TO_SCORE",

  QUIT: "QUIT",

  SET_SCORE: "SET_SCORE",

  OVERRIDE_SCORE_METHOD: "OVERRIDE_SCORE_METHOD",

  LOG_SCORE_SUMMARY: "LOG_SCORE_SUMMARY",
  LOG_BGGLOG: "LOG_BGGLOG",
};

export const scoreMethod = {
  BY_PLAYER: "byPlayer",
  BY_SCORE: "byScore",
};
