import { fb as firebase } from "./Firebase";
require("firebase/functions");

export const getFavorites = (userId, callback) => {
  const callableReturnMessage = firebase
    .functions()
    .httpsCallable("getFavorites");

  callableReturnMessage({ uid: userId })
    .then((result) => {
      callback && callback(result.data);
    })
    .catch((error) => {
      console.log(`error: ${JSON.stringify(error)}`);
      callback && callback([]);
    });
};
