import React from "react";
import { Route, Switch } from "react-router";
import AdminGames from "./AdminGames";
import AdminMenu from "./AdminMenu";
import AdminPlayes from "./AdminPlayes";

export default function Admin() {
  return (
    <div>
      <AdminMenu />

      <Switch>
        <Route path="/admin/games">
          <AdminGames />
        </Route>

        <Route path="/admin/playes">
          <AdminPlayes />
        </Route>
      </Switch>
    </div>
  );
}
