import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GameTile } from "../../components/GameTile";
import { List } from "@material-ui/core";
import { useFirestoreConnect } from "react-redux-firebase";

const GameSelection = () => {
  useFirestoreConnect([
    {
      collection: "games",
      where: [["presets", ">", 0]],
    },
  ]);
  const gamesList = useSelector((state) => state.firestore.ordered.games);

  const history = useHistory();

  const handleSelectGame = (game) => {
    history.push("/game/" + game.id);
  };

  const renderGamesList = () => {
    return (
      gamesList &&
      gamesList.map((game, index) => {
        return (
          game.presets > 0 && (
            <GameTile
              game={game}
              index={index}
              key={game.id}
              onClick={handleSelectGame}
            />
          )
        );
      })
    );
  };

  return <List disablePadding>{renderGamesList()}</List>;
};

export default GameSelection;
