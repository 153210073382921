import React, { useState, useEffect } from "react";
import { BGGSearchField } from "../../components/BGG Search Field";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Typography,
  makeStyles,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { xml2js } from "xml-js";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Alert } from "@material-ui/lab";
import { useFirestore } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";
import WalktroughTip from "../../components/WalktoughTip/WalktroughTip";

export default function CreateGame() {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedGameInfo, setSelectedGameInfo] = useState(null);
  const [fullGame, setFullGame] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const firestore = useFirestore();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const parseParentGame = (expenstionsField) => {
    if (Array.isArray(expenstionsField)) {
      let res = [];
      expenstionsField.forEach((expensionInfo) => {
        if (expensionInfo._attributes.inbound) {
          res = [
            ...res,
            {
              id: expensionInfo._attributes.objectid,
              name: expensionInfo._text,
            },
          ];
        }
      });
      return res;
    } else {
      return expenstionsField && expenstionsField._attributes.inbound
        ? [
            {
              id: expenstionsField._attributes.objectid,
              name: expenstionsField._text,
            },
          ]
        : [];
    }
  };

  const parseName = (nameField) => {
    if (Array.isArray(nameField)) {
      return nameField.find((nameObj) => nameObj._attributes.primary === `true`)
        ._text;
    } else {
      return nameField._text;
    }
  };

  const parseGame = (XMLData) => {
    const result = xml2js(XMLData, { compact: true, spaces: 4 });
    if (result) {
      let game = {
        ...selectedGame,
        name: parseName(result.boardgames.boardgame.name),
        year: result.boardgames.boardgame.yearpublished._text,
        maxplayers: Number(result.boardgames.boardgame.maxplayers._text),
        minplayers: Number(result.boardgames.boardgame.minplayers._text),
        cover:
          result.boardgames.boardgame.image &&
          result.boardgames.boardgame.image._text,
        thumbnail:
          result.boardgames.boardgame.thumbnail &&
          result.boardgames.boardgame.thumbnail._text,
        parentgames: parseParentGame(
          result.boardgames.boardgame.boardgameexpansion
        ),
      };

      setFullGame(game);
      return game;
    }
  };

  useEffect(() => {
    if (selectedGame) {
      setIsLoading(true);
      setFullGame(null);
      const url = `https://api.allorigins.win/raw?url=https://www.boardgamegeek.com/xmlapi/boardgame/${selectedGame.id}`;
      // const url = `xmlapi/boardgame/${selectedGame.id}``;

      axios
        .get(url, { "Content-Type": "application/xml; charset=utf-8" })
        .then(async (res) => {
          parseGame(res.data);

          setIsLoading(false);

          const game = await firestore
            .collection(`games`)
            .doc(selectedGame.id)
            .get();

          if (game.exists) {
            setSelectedGameInfo(game.data());
          } else {
            setSelectedGameInfo(null);
          }
        });
    }

    return () => {};
  }, [selectedGame]);

  const handleCreateGame = async (e) => {
    if (auth.uid && !auth.isAnonymous) {
      fullGame.createdAt = new Date();
      await firestore
        .collection(`games`)
        .doc(fullGame.id)
        .set(fullGame, { merge: true });
      history.push(`/game/${fullGame.id}/edit/new`);
    } else {
      dispatch(userActions.showLoginRequired());
    }
  };

  const handleGoToGame = (e) => {
    history.push(`/game/${fullGame.id}`);
  };

  const handleGameSelect = (game) => {
    if (game) {
      setSelectedGame(game);
    } else {
      setFullGame(null);
    }
  };

  const handleSelectParentGame = (id) => {
    setSelectedGame({ id: id });
  };

  const renderGameCover = () => {
    return (
      <div className={classes.coverContainer}>
        <img src={fullGame.cover} className={classes.cover} />
        <Typography className={classes.gameTitleLabel}>
          {fullGame.name}
        </Typography>
        <Typography className={classes.playerCountLabel}>
          {fullGame.minplayers} to {fullGame.maxplayers} players
        </Typography>
      </div>
    );
  };

  const renderParentGameDetails = (parentsGames) => {
    if (parentsGames && parentsGames.length > 0) {
      return (
        <div className={classes.parentsContainer}>
          <List>
            <Alert severity="warning">
              You selected an expension, <br />
              please select the core game for scorepad
            </Alert>
            {parentsGames &&
              parentsGames.map((parenGame) => {
                return (
                  <ListItem
                    button
                    key={parenGame.id}
                    divider
                    onClick={() => handleSelectParentGame(parenGame.id)}
                  >
                    <ListItemIcon>
                      <ArrowForwardIcon fontSize="small" />
                    </ListItemIcon>

                    <ListItemText primary={parenGame.name} />
                  </ListItem>
                );
              })}
          </List>
        </div>
      );
    }
  };

  const renderActions = () => {
    const hasGame = selectedGameInfo && selectedGameInfo.presets > 0;
    return (
      <div className={classes.actionContainer}>
        {!hasGame && (
          <Alert severity="info" className={classes.infoAlert}>
            This game dont have a scorepad yet.
            <br /> be the first to create one
          </Alert>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={hasGame ? handleGoToGame : handleCreateGame}
          disabled={fullGame === null}
        >
          {hasGame ? "Play" : "Create Scorepad"}
        </Button>
      </div>
    );
  };

  return (
    <main className={classes.root}>
      <section className={classes.content}>
        <Typography className={classes.title} variant="h5">
          Find a game for scorepad
        </Typography>

        <WalktroughTip
          message={`Here you can search for a game that already got a scorepad, or create a new scorepad for a game that dont have one.`}
          gotItButton={true}
          tipId="tip.search.game"
        />

        <BGGSearchField onSelect={handleGameSelect} />

        {fullGame && renderGameCover()}

        {fullGame && renderParentGameDetails(fullGame.parentgames)}
        {fullGame && fullGame.parentgames.length === 0 && renderActions()}

        {isLoading && <CircularProgress className={classes.loadingIndicator} />}
      </section>
    </main>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 8,
      paddingBottom: 64,
    },
    [theme.breakpoints.up("md")]: {
      width: 500,
      paddingTop: 64,
    },

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "auto",
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 8,
      textAlign: "center",
    },
  },
  saveButton: {
    margin: "auto",
    marginTop: 32,
  },
  coverContainer: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cover: {
    display: "flex",
    objectFit: "contain",
    width: "80%",
    maxHeight: 300,
    margin: "auto",
    filter: "drop-shadow(0px 8px 10px  #000000)",
  },
  gameTitleLabel: {
    marginTop: 16,
    textAlign: "center",
  },
  playerCountLabel: {
    textAlign: "center",
    fontSize: 16,
  },
  loadingIndicator: {
    margin: "auto",
    marginTop: 32,
  },
  parentsContainer: {
    marginTop: 32,
  },
  infoAlert: {
    marginTop: 32,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
