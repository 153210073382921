import React from "react";

const Buymecoffee = () => {
  return (
    <a
      href="https://www.buymeacoffee.com/barnaff"
      style={{ padding: 16, margin: "auto" }}
    >
      <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=barnaff&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" />
    </a>
  );
};
export default Buymecoffee;
