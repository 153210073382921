import {
  Box,
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  populate,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";
import { Fragment } from "react";
import { Author } from "../../components/Author";
import clsx from "clsx";

function Row(props) {
  const { play, playId } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const firestore = useFirestore();
  console.log(play);

  const handleDeletePlay = (e) => {
    firestore.collection("plays").doc(playId).delete();
    console.log("deleted play", playId);
  };

  const renderCheckbox = (label, value) => {
    return (
      <div className={classes.checkMarkContainer}>
        <CheckBoxIcon
          className={
            value ? classes.checkMarkEnabled : classes.checkMarkDisabled
          }
        />
        <Typography className={classes.checkMarkLabel}>{label}</Typography>
      </div>
    );
  };

  const renderPlayers = () => {
    return (
      <div>
        {play.players &&
          play.players.map((player, index) => {
            return (
              <div style={{ backgroundColor: `${player.color}33` }} key={index}>
                {player.name ? player.name : `player_${index}`} :
                {player.score ? player.score : 0}
              </div>
            );
          })}
      </div>
    );
  };

  const renderPlayTime = () => {
    return (
      <div>
        <Typography>
          Started At :{" "}
          {play.createdAt &&
            moment(new Date(play.createdAt.seconds * 1000)).format("LLL")}
        </Typography>
        <Typography>
          Started At :{" "}
          {play.endtAt &&
            moment(new Date(play.endtAt.seconds * 1000)).format("LLL")}
        </Typography>

        {play.endtAt && play.createdAt && (
          <Typography>
            Duration :
            {moment(new Date(play.endtAt.seconds * 1000)).diff(
              moment(new Date(play.createdAt.seconds * 1000)),
              "seconds",
              true
            )}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          <img
            src={play.game && play.game.thumbnail}
            className={classes.thumbnail}
          />
        </TableCell>

        <TableCell scope="row">
          {play.uid && play.uid.displayName ? (
            <Author author={play.uid} />
          ) : (
            <Typography>{play.uid && play.uid.id.substring(0, 16)}</Typography>
          )}
        </TableCell>

        <TableCell scope="row">{play.game && play.game.name}</TableCell>

        <TableCell scope="row">
          {play.createdAt &&
            moment(new Date(play.createdAt.seconds * 1000)).format("LL")}
        </TableCell>

        <TableCell scope="row">
          {renderCheckbox("started", play.started)}
        </TableCell>
        <TableCell scope="row">
          {renderCheckbox("completed", play.completed)}
        </TableCell>
        <TableCell scope="row">
          {renderCheckbox("bgg Log", play.bggLog)}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Play Details
              </Typography>
              {renderPlayers()}
              {renderPlayTime()}
              <Button
                variant="contained"
                onClick={handleDeletePlay}
                className={clsx(classes.actionButton, classes.deleteButton)}
              >
                Delete
              </Button>

              <Button
                variant="contained"
                className={classes.actionButton}
                href={`https://www.spielpad.com/game/${play.game.id}`}
              >
                View Game
              </Button>

              <Button
                variant="contained"
                className={classes.actionButton}
                href={`https://www.spielpad.com/game/${play.game.id}/${play.preset}`}
              >
                Select Preset
              </Button>

              <Button
                variant="contained"
                href={`https://boardgamegeek.com/play/details/${play.bggLog}`}
                target={`_blank`}
                className={classes.actionButton}
                disabled={!play.bggLog}
              >
                View BGG Log
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function AdminPlayes() {
  const classes = useStyles();

  const playPopulate = [
    //  { child: "preset", root: "presets" },
    { child: "game", root: "games" },
    { child: "uid", root: "users" },
    { keyProp: "id" },
  ];

  useFirestoreConnect([
    {
      collection: "plays",
      populates: playPopulate,
      // queryParams: ["orderByKey"],
    },
  ]);

  const plays = useSelector((state) => {
    const plays = populate(state.firestore, "plays", playPopulate);
    return plays;
  });

  const renderTableBody = () => {
    const keys = plays ? [...Object.keys(plays)] : [];
    var playesList = keys.map((key) => {
      return key && plays[key] && { ...plays[key], key };
    });
    playesList = playesList.sort(
      (a, b) =>
        a &&
        b &&
        a.createdAt &&
        b.createdAt &&
        b.createdAt?.seconds - a.createdAt?.seconds
    );
    return (
      playesList &&
      playesList.map((play) => {
        return play && <Row key={play.key} playId={play.key} play={play} />;
      })
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  thumbnail: {
    height: 60,
  },
  checkMarkContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkMarkEnabled: {
    color: theme.palette.success.main,
  },
  checkMarkDisabled: {
    color: "gray",
  },
  checkMarkLabel: {
    fontSize: 12,
  },
  actionButton: {
    margin: 8,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
  },
}));
