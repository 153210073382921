import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { getFirebase } from "react-redux-firebase";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";

const composedEnhancer = composeWithDevTools(
  // Add whatever middleware you actually want to use here
  applyMiddleware(thunk.withExtraArgument(getFirebase))
  // other store enhancers if any
);

const store = createStore(rootReducer, composedEnhancer);

export default store;
