import {
  Button,
  Dialog,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Fragment } from "react";
import ImageCropper from "./ImageCropper";
import clsx from "clsx";
import ImageIcon from "@material-ui/icons/Image";

export default function ImageField(props) {
  const { imageURL, onSelectFile, round = false, className } = props;
  const [selectedFile, setSelectedFile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [hover, setHover] = useState(false);

  const classes = useStyles({
    image: croppedImage ? croppedImage : imageURL,
    round: round,
  });

  const handleOpenMenu = (e) => {
    setAnchorEl(e.target);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUploadPhoto = async (e) => {
    setAnchorEl(null);
    console.log("upload photo:", e.target.files);
    setSelectedFile(e.target.files[0]);
  };

  const handleCloseImageEditor = (e) => {
    setSelectedFile(null);
  };

  const handleCropImage = (image) => {
    handleCloseImageEditor();
    setCroppedImage(image);

    onSelectFile && onSelectFile(image);
  };

  const handleRemovePhoto = (e) => {
    setCroppedImage(null);
    onSelectFile && onSelectFile(null);
  };

  const renderImageEditor = () => {
    return (
      <Dialog
        open={Boolean(selectedFile)}
        onClose={handleCloseImageEditor}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <ImageCropper
          imageSrc={selectedFile}
          onCrop={handleCropImage}
          onCancel={handleCloseImageEditor}
          round={round}
        />
      </Dialog>
    );
  };

  const renderActionMenu = () => {
    return (
      <Menu
        id="image-action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {/* <MenuItem onClick={handleCloseMenu}>View Photo</MenuItem> */}
        <input
          color="primary"
          accept="image/*"
          type="file"
          onChange={handleUploadPhoto}
          id="file-selector-button"
          style={{ display: "none" }}
        />
        <label htmlFor="file-selector-button">
          <MenuItem component="span">Upload Photo</MenuItem>
        </label>

        <MenuItem onClick={handleRemovePhoto}>Remove Photo</MenuItem>
      </Menu>
    );
  };

  const hasImage = Boolean(croppedImage || imageURL);
  return (
    <Fragment>
      {renderActionMenu()}
      {renderImageEditor()}
      <div className={clsx(classes.root, className)}>
        <div className={classes.imagePreview} />
        <Button
          className={classes.imageButton}
          onClick={handleOpenMenu}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <div
            className={
              hover || !hasImage ? classes.overlayShow : classes.overlayHide
            }
          >
            <ImageIcon fontSize="large" />
            <Typography className={classes.placeholderLabel}>
              Change Photo
            </Typography>
          </div>
        </Button>
      </div>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: (props) => `url("${props.image}")`,
    backgroundColor: "gray",
    objectFit: "cover",
    backgroundRepeat: "round",
    overflow: "hidden",
    borderRadius: (props) => (props.round ? "50%" : 5),
    width: 128,
    height: 128,
    margin: 16,
  },
  imageButton: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "clear",
    "&:hover": {
      backgroundColor: "#00000088",
    },
  },
  overlayShow: {
    "&:hover": {
      opacity: 0.7,
    },
  },
  overlayHide: { opacity: 0 },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      margin: 8,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {},
  },
  placeholderLabel: {
    fontSize: 12,
  },
}));
