import { userConstants } from "./constants";

const initialState = {
  showLogin: false,
  showUserSettings: false,
  showRequireLogin: false,
  loginError: null,
  showSignupTab: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return { ...state, user: null };
    case userConstants.SHOW_LOGIN:
      return { ...state, showLogin: true, loginError: null };
    case userConstants.CLOSE_LOGIN:
      return { ...state, showLogin: false, loginError: null };

    case userConstants.LOGIN_ERROR:
      return { ...state, loginError: action.error };

    case userConstants.SHOW_USER_SETTINGS:
      return { ...state, showUserSettings: true };
    case userConstants.CLOSE_USER_SETTINGS:
      return { ...state, showUserSettings: false };

    case userConstants.SHOW_REQUIRE_LOGIN:
      return { ...state, showRequireLogin: true };
    case userConstants.CLOSE_REQUIRE_LOGIN:
      return { ...state, showRequireLogin: false };

    case userConstants.SET_LOGIN_POPUP:
      return { ...state, showSignupTab: false, loginError: null };
    case userConstants.SET_SIGNUP_POPUP:
      return { ...state, showSignupTab: true, loginError: null };
    default:
      return state;
  }
}
