import React from "react";
import { Typography, CircularProgress, makeStyles } from "@material-ui/core";

export default function GameCover(props) {
  const { game } = props;
  const classes = useStyles();

  const playerCountLabel = () => {
    if (game.minplayers === game.maxplayers) {
      if (game.maxplayers === 1) {
        return "solo game";
      }
      return `for ${game.maxplayers} players`;
    }
    return `${game.minplayers} to ${game.maxplayers} players`;
  };

  const renderGameCover = () => {
    return (
      <div className={classes.root}>
        <img src={game && game.cover} className={classes.cover} />
        <Typography variant="h4" className={classes.title}>
          {game && game.name}
          <Typography className={classes.yearLabel}>
            ({game && game.year})
          </Typography>
        </Typography>
        <Typography className={classes.playerCountLabel}>
          {game && playerCountLabel()}
        </Typography>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  };

  return game ? renderGameCover() : renderLoading();
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cover: {
    display: "flex",
    objectFit: "contain",
    width: "80%",
    maxHeight: 300,
    filter: "drop-shadow(0px 8px 10px  #000000)",
  },
  title: {
    marginTop: 32,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: 16,
      fontSize: 28,
    },
    [theme.breakpoints.up("md")]: {
      margin: 32,
    },
    filter: "drop-shadow(0px 1px 7px  #00000099)",
  },
  yearLabel: {
    textSize: 20,
    filter: "drop-shadow(0px 1px 7px  #00000099)",
  },
  playerCountLabel: {
    filter: "drop-shadow(0px 1px 7px  #00000099)",
  },
}));
