import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  makeStyles,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { gameScoringActions } from "../../features/gameScoring";
import { useParams, useHistory } from "react-router-dom";
import { GamePageContainer } from "../GamePageContainer";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { FavoriteButton } from "../../components/FavoriteButton";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { analytics } from "../../firebase";
import { calculateTotal } from "./calculateScore";
import BGGLogPlay from "../BGGLogPlay/BGGLogPlay";
import { bggActions } from "../../features/bgg";
import PresetRating from "../PresetRating/PresetRating";
import { useFirestoreConnect } from "react-redux-firebase";
import WalktroughTip from "../WalktoughTip/WalktroughTip";

export default function () {
  const dispatch = useDispatch();
  const { gameId, presetId } = useParams();
  const history = useHistory();

  const [zoom, setZoom] = useState(1);

  const players = useSelector((state) => state.gameScoring.players);
  const scorepad = useSelector((state) => state.gameScoring.scorepad);

  useFirestoreConnect([
    {
      collection: `presets`,
      doc: presetId,
    },
  ]);

  const preset = useSelector(
    ({ firestore: { data } }) => data.presets && data.presets[presetId]
  );

  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[gameId]
  );

  const { scoreSettings } = preset;

  const classes = useStyles({
    cover: game && game.cover,
    zoom: zoom,
    playerCount: players.length,
  });

  useEffect(() => {
    analytics.logEvent("score_summary", { name: game.name, preset: presetId });
    dispatch(gameScoringActions.logScoreSummary());
    return () => {};
  }, []);

  const handleQuit = () => {
    dispatch(gameScoringActions.quit());
    history.push("/");
  };

  const handlePrevius = () => {
    let index = scorepad.length;
    do {
      index--;
    } while (scorepad[index] && scorepad[index].scoring.isHidden);

    history.push(`/game/${gameId}/${presetId}/score/${index}`);
  };
  const handleZoomOut = () => {
    setZoom(Math.max(zoom - 0.25, 0.5));
  };
  const handleZoomIn = () => {
    setZoom(Math.min(zoom + 0.25, 2));
  };

  const handleLogPlay = () => {
    dispatch(bggActions.showLogPlay());
  };

  const handleEditScoreCell = (scoring) => {
    const index = scorepad.indexOf(scoring);
    history.push(`/game/${gameId}/${presetId}/score/${index}`);
  };

  const renderZoomControls = () => {
    return (
      <ButtonGroup size="small" className={classes.zoomButtonsContainer}>
        <Button onClick={handleZoomOut}>
          <ZoomOutIcon />
        </Button>
        <Button disabled={true}>{`${zoom * 100}%`}</Button>
        <Button onClick={handleZoomIn}>
          <ZoomInIcon />
        </Button>
      </ButtonGroup>
    );
  };

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}> </TableCell>
          {players.map((player) => {
            return (
              <TableCell
                key={`player-${player.id}`}
                style={{ backgroundColor: `${player.color}66` }}
                className={classes.tableCell}
              >
                <Typography align="center" className={classes.textScaling}>
                  {player.name}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const renderPlayerScoreCell = (player, scoreSetting) => {
    const scoring = scorepad.find(
      (scr) => scr.player.id === player.id && scr.scoring.id === scoreSetting.id
    );
    return (
      <TableCell
        align="center"
        key={`${player.id}${scoreSetting.id}`}
        style={{ backgroundColor: `${player.color}22` }}
        className={clsx(
          classes.tableCell,
          classes.scoringCell,
          scoring.scoring.ignoreScore ? classes.ignoreScore : null
        )}
      >
        <Button
          onClick={() => handleEditScoreCell(scoring)}
          className={classes.scoringButton}
        >
          {scoring.scoring.negative && scoring.value > 0 && (
            <span className={classes.negativeLabel}>-</span>
          )}
          <span>{scoring.value}</span>
          {scoring.scoring.multiplier && scoring.scoring.multiplier != 0 && (
            <span className={classes.multiplierLabel}>
              x{scoring.scoring.multiplier}
            </span>
          )}
        </Button>
      </TableCell>
    );
  };

  const renderScoreSettingRow = (scoreSetting) => {
    if (scoreSetting.isHidden) {
      return null;
    }
    return (
      <TableRow component="tr" key={`${scoreSetting.id}-row`}>
        <TableCell
          component="th"
          scope="row"
          key={`${scoreSetting.id}-title`}
          className={classes.tableCell}
        >
          <div className={classes.scoreTitleContent}>
            {scoreSetting.image && (
              <img src={scoreSetting.image} className={classes.scoreIcon} />
            )}
            <Typography className={classes.textScaling}>
              {scoreSetting.name}
            </Typography>
          </div>
        </TableCell>

        {players.map((player) => {
          return renderPlayerScoreCell(player, scoreSetting);
        })}
      </TableRow>
    );
  };

  const renderTotalCell = (player) => {
    const totalScore = calculateTotal(player, scorepad);

    return (
      <TableCell
        align="center"
        key={`${player.id}-total`}
        style={{ backgroundColor: `${player.color}66` }}
        className={classes.tableCell}
      >
        <Typography className={clsx(classes.textScaling, classes.totalLabel)}>
          {totalScore}
        </Typography>
      </TableCell>
    );
  };

  const renderTotalScoreRow = () => {
    return (
      <TableRow component="tr" key="totals-row">
        <TableCell
          component="th"
          scope="row"
          key="totals-title"
          className={classes.tableCell}
        >
          <Typography className={classes.textScaling}>Total</Typography>
        </TableCell>
        {players.map((player) => {
          return renderTotalCell(player);
        })}
      </TableRow>
    );
  };

  const renderTableBody = () => {
    return (
      <TableBody>
        {scoreSettings.map((scoreSetting) => {
          return renderScoreSettingRow(scoreSetting);
        })}
        {renderTotalScoreRow()}
      </TableBody>
    );
  };

  const renderScoreTable = (e) => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    );
  };

  const renderNavigationButtons = () => {
    return (
      <div className={classes.buttonsLayout}>
        <div className={classes.buttonsContainer}>
          <IconButton onClick={handlePrevius} className={classes.navButton}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={handleQuit} className={classes.navButton}>
            <HomeIcon />
          </IconButton>
          <FavoriteButton preset={preset} />
        </div>

        <div className={classes.buttonsContainer}>
          <WalktroughTip
            message={`Keep track your scores on BoardGameGeek`}
            gotItButton={true}
            tipId="tip.bgg.log.play"
            anchorElName={`bgglogplaybutton`}
            popover={true}
          />

          <Button onClick={handleLogPlay} id="bgglogplaybutton">
            Log Play
          </Button>
          <PresetRating preset={preset} />
        </div>
      </div>
    );
  };

  return (
    <GamePageContainer
      game={game}
      showHeader={true}
      contentProps={{ className: classes.content }}
    >
      <BGGLogPlay
        scorepad={scorepad}
        players={players}
        preset={preset}
        game={game}
      />
      <Typography variant="h6" className={classes.scoreTitle}>
        Final Scores
      </Typography>
      {renderZoomControls()}
      <div className={classes.tableContainer}>{renderScoreTable()}</div>

      {renderNavigationButtons()}
    </GamePageContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  content: { padding: 0, width: "100%" },
  scoreTitle: { textAlign: "center", padding: 32 },
  buttonsLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column-reverse",
      alignItems: "stretch",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
  },
  buttonsContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
    marginTop: 16,
    marginBottom: 16,
    alignItems: "center",
  },
  table: {
    minWidth: (props) => props.zoom * (150 + props.playerCount * 100),
    flexWrap: "nowrap",
  },
  tableContainer: {
    position: "relative",
    width: "100%",
  },
  scoreIcon: {
    width: (props) => props.zoom * 48,
    height: (props) => props.zoom * 48,
    marginRight: 8,
    borderRadius: (props) => props.zoom * 5,
  },
  scoreTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tableCell: {
    padding: 8,
    borderColor: "#00000055",
    borderWidth: 2,
  },
  textScaling: {
    [theme.breakpoints.down("sm")]: { fontSize: (props) => props.zoom * 18 },
    [theme.breakpoints.up("md")]: { fontSize: (props) => props.zoom * 22 },
  },
  totalLabel: {
    fontWeight: 900,
  },
  zoomButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 8,
  },
  scoringCell: {
    padding: 0,
    alignItems: "stretch",
  },
  ignoreScore: {
    opacity: 0.5,
  },
  scoringButton: {
    [theme.breakpoints.down("sm")]: { fontSize: (props) => props.zoom * 18 },
    [theme.breakpoints.up("md")]: { fontSize: (props) => props.zoom * 22 },
    width: "100%",
    minHeight: (props) => props.zoom * 64,
    borderRadius: 0,
  },
  multiplierLabel: {
    opacity: 0.3,
    paddingLeft: 8,
    textTransform: "none",
  },
  negativeLabel: {
    textTransform: "none",
  },
  navButton: {},
}));
