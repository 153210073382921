import { userConstants } from "../user";

export const ratingActions = {
  setRating,
  clearRating,
};

function setRating(preset, newRating) {
  return async (dispatch, getState, getFirebase) => {
    const auth = getState().firebase.auth;
    if (auth && !auth.isAnonymous) {
      const ratingKey = `${auth.uid}_${preset.id}`;
      await getFirebase().firestore().collection(`ratings`).doc(ratingKey).set({
        uid: auth.uid,
        preset: preset.id,
        rating: newRating,
      });
    } else {
      dispatch({ type: userConstants.SHOW_REQUIRE_LOGIN });
    }
  };
}

function clearRating(preset) {
  return async (dispatch, getState, getFirebase) => {
    const auth = getState().firebase.auth;
    const ratingKey = `${auth.uid}_${preset.id}`;
    await getFirebase()
      .firestore()
      .collection(`ratings`)
      .doc(ratingKey)
      .delete();
  };
}
