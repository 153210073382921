import { combineReducers } from "redux";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import { userReducer } from "../user";
import { gameScoringReducer } from "../gameScoring";
import { gamesDataReducer } from "../gamesData";
import { presetEditorReducer } from "../presetEditor";
import { bggReducer } from "../bgg";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user: userReducer,
  gamesData: gamesDataReducer,
  gameScoring: gameScoringReducer,
  presetEditor: presetEditorReducer,
  bgg: bggReducer,
});

export default rootReducer;
