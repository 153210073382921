export const presetConstants = {
  SET_PRESET: "SET_PRESET",
  NEW_PRESET: "NEW_PRESET",
  LOAD_PRESET: "LOAD_PRESET",

  CREATE_SCORE_SETTINGS: "CREATE_SCORE_SETTINGS",
  SAVE_SCORE_SETTINGS: "EDIT_SCORE_SETTINGS",
  EDIT_SCORE_SETTING: "EDIT_SCORE_SETTING",
  DELETE_SCORE_SETTINGS: "DELETE_SCORE_SETTINGS",
  REORDER_SCORE_SETTINGS: "REORDER_SCORE_SETTINGS",
  CLOSE_SCORE_SETTINGS_DIALOG: "CLOSE_SCORE_SETTINGS_DIALOG",
  EDIT_SCORE_SETTINGS_FIELD: "EDIT_SCORE_SETTINGS_FIELD",

  EDIT_PRESET_SETTINGS_FIELD: "EDIT_PRESET_SETTINGS_FIELD",

  PRESET_SAVED: "PRESET_SAVED",

  SCORE_SETTING_IMGAE_START_UPLOAD: "SCORE_SETTING_IMGAE_START_UPLOAD",
  SCORE_SETTING_IMGAE_FINISHED_UPLOAD: "SCORE_SETTING_IMGAE_FINISHED_UPLOAD",

  EDIT_PLAYER_SETTINGS_COLORS: "EDIT_PLAYER_SETTINGS_COLORS",
  EDIT_PLAYER_SETTINGS_FORCE_UNIQUE_COLORS:
    "EDIT_PLAYER_SETTINGS_FORCE_UNIQUE_COLORS",
};
