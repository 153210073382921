import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { presetEditorActions } from "../../features/presetEditor";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpTipButton from "../../components/HelpTips/HelpTipButton";
import { ScriptFieldHelp } from "../../components/HelpTips/Help";

export default function AdvancedScoreSettings(props) {
  const { scoreSettings } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleValueChanged = (e) => {
    dispatch(
      presetEditorActions.editScoreSettingsField(e.target.id, e.target.value)
    );
  };
  const handleCheckboxChanged = (e) => {
    dispatch(
      presetEditorActions.editScoreSettingsField(e.target.id, e.target.checked)
    );
  };

  const scoreMultiplierField = () => {
    return (
      <span className={classes.multiplierContainer}>
        <Typography>X</Typography>
        <TextField
          id="multiplier"
          value={scoreSettings.multiplier ? scoreSettings.multiplier : false}
          label="Multiplier"
          onChange={handleValueChanged}
          className={clsx(classes.textfield, classes.multiplierTextField)}
          autoComplete="off"
          type="number"
        />
      </span>
    );
  };

  const canBeNegativeCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={
              scoreSettings.allowNegative ? scoreSettings.allowNegative : false
            }
            onChange={handleCheckboxChanged}
            className={classes.checkBox}
            id="allowNegative"
            disabled={scoreSettings.negative}
          />
        }
        label="Can Be Negative"
      />
    );
  };

  const alwaysNegativeCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={scoreSettings.negative ? scoreSettings.negative : false}
            onChange={handleCheckboxChanged}
            className={classes.checkBox}
            id="negative"
          />
        }
        label="Always Negative"
      />
    );
  };

  const ignoreScoringCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={
              scoreSettings.ignoreScore ? scoreSettings.ignoreScore : false
            }
            onChange={handleCheckboxChanged}
            className={classes.checkBox}
            id="ignoreScore"
          />
        }
        label="Dont count as score"
      />
    );
  };

  const isHiddenCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={scoreSettings.isHidden ? scoreSettings.isHidden : false}
            onChange={handleCheckboxChanged}
            className={classes.checkBox}
            id="isHidden"
          />
        }
        label="Hide this row"
      />
    );
  };

  const keyField = () => {
    return (
      <TextField
        id="key"
        value={scoreSettings.key}
        label="Key"
        onChange={handleValueChanged}
        className={classes.textfield}
        autoComplete="off"
      />
    );
  };

  const scriptField = () => {
    return (
      <TextField
        id="script"
        value={scoreSettings.script}
        label="Script"
        onChange={handleValueChanged}
        className={classes.scriptField}
        autoComplete="off"
        variant="outlined"
        fullWidth
      />
    );
  };

  const advanceSettingsContent = () => {
    return (
      scoreSettings && (
        <div>
          <Grid container spacing={1} justify={"flex-start"}>
            <Grid item sm={6} xs={12}>
              {scoreMultiplierField()}
            </Grid>
            <Grid item sm={6} xs={12}>
              {keyField()}
            </Grid>
            <Grid item sm={6} xs={12}>
              {canBeNegativeCheckbox()}
            </Grid>
            <Grid item sm={6} xs={12}>
              {alwaysNegativeCheckbox()}
            </Grid>

            <Grid item sm={6} xs={12}>
              {ignoreScoringCheckbox()}
            </Grid>

            <Grid item sm={6} xs={12}>
              {isHiddenCheckbox()}
            </Grid>

            <Grid item sm={12} className={classes.gridCell}>
              {scriptField()}
              <HelpTipButton helpComponent={<ScriptFieldHelp />} />
            </Grid>
          </Grid>
        </div>
      )
    );
  };

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Advanced Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>{advanceSettingsContent()}</AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#00000022",
    [theme.breakpoints.down("sm")]: { marginTop: 16 },
    [theme.breakpoints.up("md")]: {},
  },
  textfield: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
    [theme.breakpoints.up("md")]: {
      margin: 8,
    },
  },
  multiplierTextField: {
    marginLeft: 8,
  },
  multiplierContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingLeft: 16,
  },
  checkBox: {
    margin: 8,
  },
  scriptField: {},
  gridCell: {
    paddingTop: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
