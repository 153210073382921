import {
  Button,
  makeStyles,
  Typography,
  TextField,
  IconButton,
  Paper,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { gameScoringActions } from "../../features/gameScoring";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { reorder, useDraggableInPortal } from "../../helpers";
import PlayerAvatar from "./PlayerAvatar";
import WalktroughTip from "../WalktoughTip/WalktroughTip";
import PlayerNameField from "./PlayerNameField";

export default function PlayersList() {
  const players = useSelector((state) => state.gameScoring.players);
  const dispatch = useDispatch();

  const renderDraggable = useDraggableInPortal();

  const classes = useClasses();

  const handleAddPlayer = (e) => {
    dispatch(gameScoringActions.addPlayer({ name: "" }));
  };

  const handleRemovePlayer = (e, player) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(gameScoringActions.removePlayer(player));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reoprderPlayers = reorder(
      players,
      result.source.index,
      result.destination.index
    );

    dispatch(gameScoringActions.reorderPlayers(reoprderPlayers));
  };

  const getListStyle = (isDraggingOver) => ({ paddingTop: 8 });

  const getItemStyle = (isDragging, draggableStyle) => ({
    paddingTop: 8,
    paddingBottom: 8,
    ...draggableStyle,
  });

  const addPlayerButton = () => {
    return (
      <Button className={classes.addPlayerButton} onClick={handleAddPlayer}>
        <Typography variant="h5">+Add Player</Typography>
      </Button>
    );
  };

  const renderDraggableList = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {players.map((player, index) => (
                <Draggable
                  key={player.id}
                  draggableId={player.id}
                  index={index}
                >
                  {renderDraggable((provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <PlayerTile
                        player={player}
                        onDelete={handleRemovePlayer}
                        index={index}
                        isDragged={snapshot.isDragging}
                      />
                    </div>
                  ))}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <div className={classes.root}>
      {renderDraggableList()}
      {addPlayerButton()}
    </div>
  );
}

function PlayerTile(props) {
  const { player, isDragged, index, onDelete } = props;
  const classes = useClasses({ color: player && player.color });
  const dispatch = useDispatch();

  const handleNameValueChange = (value) => {
    const updatedPlayer = {
      ...player,
      name: value,
    };
    dispatch(gameScoringActions.editPlayer(updatedPlayer));
  };

  return (
    <Paper elevation={0} key={index} className={classes.playerTile}>
      <DragHandleIcon className={classes.dragIcon} />

      <PlayerAvatar player={player} id={`playerAvatarButton_${index}`} />
      {index === 0 && !isDragged && (
        <WalktroughTip
          message={`click here to pick a color for this player`}
          gotItButton={true}
          tipId="tip.players.select.color "
          anchorElName={`playerAvatarButton_${index}`}
          popover={true}
        />
      )}
      <PlayerNameField
        defaultValue={player.name}
        onChange={handleNameValueChange}
        placeholder={`Player ${index + 1}`}
      />

      <IconButton
        onClick={(e) => onDelete(e, player)}
        className={classes.deleteButton}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </Paper>
  );
}

const useClasses = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  playerTile: {
    height: 100,
    borderRadius: 20,
    display: "flex",
    flexDirection: "row",
    padding: 16,
    alignItems: "center",
    //filter: "brightness(0.6)",
    border: (props) => (props.color ? `3px solid ${props.color}` : "none"),
  },
  addPlayerButton: {
    justifyContent: "center",
    flex: 1,
    marginBottom: 16,
    marginTop: 8,
    border: "4px dashed #FFFFFF33",
    borderRadius: 20,
    minHeight: 90,
    backgroundColor: "#FFFFFF11",
  },
  avatarButton: {
    width: 70,
    height: 70,
    border: "2px solid #000",
    backgroundColor: "#00000066",
    marginRight: 16,
  },

  dragIcon: {
    color: "#FFFFFF33",
    marginRight: 8,
  },
  deleteButton: {
    zIndex: 1,
  },
}));
