import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import BGGLogo from "./navbar-logo-bgg-b2.svg";

export default function BGGLinkGameButton(props) {
  const { gameId } = props;
  const classes = useStyles();

  return (
    gameId && (
      <Button
        href={`https://boardgamegeek.com/boardgame/${gameId}`}
        target="_blank"
        className={classes.button}
      >
        game at <img src={BGGLogo} className={classes.bggIcon} />
      </Button>
    )
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    marginTop: 8,
    filter: "drop-shadow(0px 1px 7px  #00000099)",
  },
  bggIcon: {
    marginLeft: 8,
  },
}));
