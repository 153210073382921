import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../BackButton";

export default function GamePageContainer(props) {
  const {
    game,
    children,
    backURL,
    showHeader = true,
    contentProps,
    ...other
  } = props;

  const classes = useStyles({
    cover: game && game.cover,
    background: game && game.background,
  });

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title} align="center">
          {game && game.name}
        </Typography>
        {renderBackButton()}
      </div>
    );
  };

  const renderBackButton = () => {
    return (
      backURL && <BackButton to={backURL} className={classes.backButton} />
    );
  };

  return (
    <main className={classes.root} {...other}>
      <div className={classes.bg} />
      <div className={classes.mainCard}>
        {showHeader && renderHeader()}
        <div className={classes.content} {...contentProps}>
          {children}
        </div>
      </div>
    </main>
  );
}

const useStyles = makeStyles((theme) => ({
  root: { width: "100%", minHeihgt: "100%", paddingBottom: 64 },
  mainCard: {
    [theme.breakpoints.down("sm")]: { width: "100%", borderRadius: 0 },
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
      minWidth: 728,
      maxWidth: "50%",
      filter: "drop-shadow(0px 8px 8px  #00000055)",
      borderRadius: 5,
    },
    margin: "auto",
    display: "flex",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    backgroundImage: (props) => `url("${props.cover}")`,
    width: "100%",
    minHeight: 120,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bg: {
    backgroundImage: (props) =>
      `url("${props.background ? props.background : props.cover}")`,
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    zIndex: -2,
    opacity: 0.5,
    filter: (props) => (props.background ? null : "blur(50px) brightness(2)"),
  },
  content: {
    width: "100%",

    [theme.breakpoints.down("sm")]: { padding: 8 },
    [theme.breakpoints.up("md")]: { padding: 32 },
  },
  title: {
    filter: "drop-shadow(0px 2px 3px  #000000)",
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      padding: "0px 8px 0px 8px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      padding: "0px 32px 0px 32px",
    },
  },
  backButton: {
    position: "absolute",
    left: 0,
    top: 0,
    margin: 8,
    filter: "drop-shadow(0px 2px 3px  #000000)",
  },
}));
