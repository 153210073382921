import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FeedbackDialog from "./FeedbackDialog";

export default function Feedback() {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  const classes = useStyles();

  const handleFeedbackEvent = (e) => {
    setShowFeedbackDialog(true);
  };

  useEffect(() => {
    window.addEventListener("feedback", handleFeedbackEvent);

    return () => {
      window.removeEventListener("feedback", handleFeedbackEvent);
    };
  }, [handleFeedbackEvent]);

  const handleOpenFeedbackDialog = () => {
    setShowFeedbackDialog(true);
  };

  const handleCloseFeedbackDialog = () => {
    setShowFeedbackDialog(false);
  };

  const renderFeedbackButton = () => {
    return (
      <Button
        className={classes.feedbackButton}
        onClick={handleOpenFeedbackDialog}
      >
        Feedback
      </Button>
    );
  };

  return (
    <div>
      <FeedbackDialog
        open={showFeedbackDialog}
        onClose={handleCloseFeedbackDialog}
      />
      {!showFeedbackDialog && renderFeedbackButton()}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  feedbackButton: {
    position: `fixed`,
    right: -40,
    zIndex: 999,
    transform: `rotate(-90deg)`,
    backgroundColor: `#000000`,
    "&:hover": {
      backgroundColor: `#00000088`,
    },
    [theme.breakpoints.down("sm")]: {
      bottom: 110,
    },
    [theme.breakpoints.up("md")]: {
      bottom: `10%`,
    },
  },
}));
