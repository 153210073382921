import { Avatar, makeStyles, Typography } from "@material-ui/core";
import React from "react";

export default function Author(props) {
  const { leadingText, author, ...other } = props;
  const classes = useStyles();

  const renderUser = () => {
    return (
      <div className={classes.root}>
        {leadingText && (
          <Typography className={classes.leadingText}>{leadingText}</Typography>
        )}
        <Avatar src={author && author.photoURL} className={classes.avatar} />
        <Typography className={classes.nameLabel}>
          {author && author.displayName}
        </Typography>
      </div>
    );
  };

  return author ? renderUser() : null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    width: 28,
    height: 28,
  },
  nameLabel: {
    marginLeft: 8,
    fontSize: 18,
  },
  leadingText: {
    marginRight: 8,
    fontSize: 18,
  },
}));
