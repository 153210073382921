import React from "react";
import { ListItem, makeStyles, Typography } from "@material-ui/core";

export default function GameTile(props) {
  const { game, onClick } = props;
  const classes = useStyles({
    cover: game.thumbnail,
    background: game.background,
  });

  const handleSelectGame = () => {
    onClick && onClick(game);
  };

  return (
    <ListItem onClick={handleSelectGame} button className={classes.tile}>
      <div className={classes.bg} />
      <div className={classes.gradient} />
      <img src={game.thumbnail} className={classes.cover} />
      <div>
        <div className={classes.titleContainer}>
          <Typography className={classes.titleLabel}>{game.name}</Typography>
          <Typography className={classes.yearLabel}>({game.year})</Typography>
        </div>
        <Typography className={classes.scorePadsLabel}>
          {game.presets} {game.presets == 1 ? "Scorepad" : "Scorepads"}
        </Typography>
      </div>
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  tile: {
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: 32,
    },
  },
  cover: {
    objectFit: "contain",
    filter: "drop-shadow(0px 2px 8px  #00000033)",
    [theme.breakpoints.down("sm")]: {
      height: 100,
      width: 100,
    },
    [theme.breakpoints.up("md")]: {
      height: 200,
      width: 200,
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 16,
  },
  titleLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
    },
  },
  yearLabel: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
    opacity: 0.4,
  },
  scorePadsLabel: {
    fontSize: 16,
    marginLeft: 16,
  },
  bg: {
    backgroundImage: (props) =>
      `url("${props.background ? props.background : props.cover}")`,
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    zIndex: -2,
    filter: (props) => (props.background ? null : "blur(40px)"),
    opacity: 1,
    // border: "20px solid black",
  },
  gradient: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    flex: 1,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      background:
        "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 20%)",
    },
    [theme.breakpoints.up("md")]: {
      background:
        "linear-gradient(90deg, rgba(0,0,0,0.6811099439775911) 0%, rgba(48,48,48,1) 4%, rgba(48,48,48,0) 81%, rgba(48,48,48,1) 100%)",
    },
  },
}));
