import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { calculateTotal } from "../ScoreSummary/calculateScore";
import { FaCrown } from "react-icons/fa";
import { MdNewReleases } from "react-icons/md";
import clsx from "clsx";
import ConnectBGG from "../Account Settings/ConnectBGG";
import { useDispatch, useSelector } from "react-redux";
import { bggActions } from "../../features/bgg";
import { Fragment } from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Alert } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import Feedback from "../Feedback/Feedback";
import { analytics } from "../../firebase";

export default function BGGLogPlay(props) {
  const { scorepad, players, preset, game } = props;
  const showLogPlay = useSelector((state) => state.bgg.showLogPlay);
  const logPlayResult = useSelector((state) => state.bgg.logPlayResult);
  const isSavingLogPlay = useSelector((state) => state.bgg.isSavingLogPlay);
  const logPlayError = useSelector((state) => state.bgg.logPlayError);
  const auth = useSelector((state) => state.firebase.auth);
  const userData = useSelector(
    ({ firestore: { data } }) => data.users_data && data.users_data[auth.uid]
  );
  const isConnected = Boolean(userData && userData.bggcredentials !== null);

  const [playPayload, setPlayPayload] = useState({});
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    setPlayPayload(generatePlayLog());
    dispatch(bggActions.resetLogPlay());
    return () => {};
  }, [players, scorepad, dispatch]);

  const handlePlayerWin = (player) => {
    player.win = !player.win;
    const index = playPayload.players.indexOf(player);

    setPlayPayload({
      ...playPayload,
      players: playPayload.players.map((p, i) => (i === index ? player : p)),
    });
  };

  const handlePlayerNew = (player) => {
    player.new = !player.new;
    const index = playPayload.players.indexOf(player);

    setPlayPayload({
      ...playPayload,
      players: playPayload.players.map((p, i) => (i === index ? player : p)),
    });
  };

  const generatePlayerData = (player) => {
    return {
      name: player.name,
      score: calculateTotal(player, scorepad),
      new: false,
      win: false,
      selectedColor: player.color,
    };
  };

  const generatePlayLog = () => {
    const date = new Date().toISOString();
    const playLog = {
      players: players.map((player) => generatePlayerData(player)),
      comments: "\rgenerated by www.Spielpad.com",
      objecttype: "thing",
      objectid: preset.gameId,
      quantity: 1,
      date: date,
      playdate: date.substring(0, 10),
      ajax: 1,
      action: "save",
      location: "",
    };

    return playLog;
  };

  const handleLogPlay = () => {
    analytics.logEvent("log_play_button", {
      name: game.name,
      preset: preset.id,
    });
    dispatch(bggActions.logPlay(playPayload));
  };

  const handleClose = () => {
    dispatch(bggActions.hideLogPlay());
  };

  const renderBGGConnectPanel = () => {
    return (
      <div>
        <ConnectBGG showUnlink={false} showInfo={true} />
      </div>
    );
  };

  const renderPlayersList = () => {
    return (
      <div>
        <div className={classes.playersHeader}>
          <Typography className={classes.plyersHeaderLabel}>Score</Typography>
          <Typography className={classes.plyersHeaderLabel}>Winner</Typography>
          <Typography className={classes.plyersHeaderLabel}>
            First Time Playing
          </Typography>
        </div>
        {playPayload.players &&
          playPayload.players.map((player, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: `${
                    player.selectedColor ? player.selectedColor : "#222222"
                  }44`,
                }}
                className={classes.playerRow}
              >
                <Typography className={classes.playerNameLabel}>
                  {player.name}
                </Typography>
                <Typography className={classes.playerScoreLabel}>
                  {player.score}
                </Typography>

                <Button
                  onClick={() => handlePlayerWin(player)}
                  className={clsx(
                    classes.playerIconButton,
                    player.win
                      ? classes.playerIconEnabled
                      : classes.playerIconDisabled
                  )}
                >
                  <FaCrown />
                </Button>
                <Button
                  onClick={() => handlePlayerNew(player)}
                  className={clsx(
                    classes.playerIconButton,
                    player.new
                      ? classes.playerIconEnabled
                      : classes.playerIconDisabled
                  )}
                >
                  <MdNewReleases />
                </Button>
              </div>
            );
          })}
      </div>
    );
  };

  const renderLogPlay = () => {
    return (
      <Fragment>
        <Alert severity="warning" className={classes.disclaimer}>
          Log play feature is still in early development, <br />
          plase use the feedback option to report about any issues
        </Alert>

        {renderBGGConnectPanel()}

        <Divider />

        {renderPlayersList()}

        <TextField
          label="comment"
          multiline
          rows={4}
          variant="outlined"
          value={playPayload.comments}
          onChange={(e) =>
            setPlayPayload({ ...playPayload, comments: e.target.value })
          }
          className={classes.commentsTextField}
        />

        <TextField
          label="location"
          variant="outlined"
          value={playPayload.location}
          onChange={(e) =>
            setPlayPayload({ ...playPayload, location: e.target.value })
          }
          className={classes.commentsTextField}
        />

        {logPlayError && (
          <Alert severity="error" className={classes.error}>
            There was an error logging this game to BGG <br />
            Check your username and password and try again
          </Alert>
        )}

        {isSavingLogPlay ? (
          <CircularProgress className={classes.loadingIndicator} />
        ) : (
          <Button
            onClick={handleLogPlay}
            variant="contained"
            className={classes.logPlayButton}
            disabled={!isConnected}
          >
            Log Play
          </Button>
        )}
      </Fragment>
    );
  };

  const renderLogPlayResult = () => {
    return (
      <div className={classes.logPlayContainer}>
        <Divider className={classes.divider} />
        <CheckCircleOutlineIcon className={classes.successIcon} />
        <Typography align="center">Game logged</Typography>
        <Typography align="center">
          you logged {logPlayResult && logPlayResult.numplays} games of{" "}
          {game.name}
        </Typography>
        <Button
          href={`https://boardgamegeek.com/play/edit/${
            logPlayResult && logPlayResult.playid
          }`}
          target="_blank"
          variant="outlined"
          className={classes.bggRefButton}
        >
          See on BGG
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      open={showLogPlay}
      onClose={handleClose}
      PaperProps={{ className: classes.popupPaper }}
    >
      <Feedback />
      <Typography align="center" variant="h5" className={classes.title}>
        Log Play
      </Typography>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <ClearIcon />
      </IconButton>

      {logPlayResult ? renderLogPlayResult() : renderLogPlay()}
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  popupPaper: {
    maxWidth: 800,
    margin: 8,
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  playerRow: {
    marginTop: 8,
    padding: 4,
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    width: "100%",
    marginTop: 16,
    marginBottom: 16,
  },
  playerIconButton: {
    width: 55,
  },
  playerIconDisabled: {
    opacity: 0.2,
  },
  playerIconEnabled: {
    opacity: 1,
  },
  playerNameLabel: {},
  playerScoreLabel: {
    marginLeft: "auto",
  },
  commentsTextField: {
    marginTop: 16,
  },
  playersHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  plyersHeaderLabel: {
    fontSize: 12,
    padding: 8,
    maxWidth: 80,
    textAlign: "center",
  },
  logPlayContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  successIcon: {
    color: theme.palette.success.main,
    width: 100,
    height: 100,
    margin: "auto",
  },
  logPlayButton: {
    width: 200,
    margin: "auto",
    marginTop: 16,
  },
  loadingIndicator: {
    margin: "auto",
    marginTop: 16,
  },
  error: {
    marginTop: 16,
    fontSize: 14,
  },
  bggRefButton: {
    marginTop: 16,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  disclaimer: {
    fontSize: 14,
    marginBottom: 8,
  },
}));
