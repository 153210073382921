import { gameDataConstants } from "./constants";
import { userConstants } from "../user";

const initialState = {
  games: [],
  activeGame: null,
  presets: null,
  loadingPresets: false,
  favorites: [],
  fetchingGames: [],
  gamesInfo: {},
  loadingFavorites: false,
};

export default function gamesDataReducer(state = initialState, action) {
  switch (action.type) {
    case gameDataConstants.SET_ACTIVE_GAME_REQUEST:
      return { ...state, activeGame: null, presets: null };
    case gameDataConstants.SET_ACTIVE_GAME_SUCCESS:
      return {
        ...state,
        activeGame: action.game,
        gamesInfo: { ...state.gamesInfo, [action.game.id]: action.game },
      };
    case gameDataConstants.FETCH_GAME_PRESETS_REQUEST:
      return { ...state, presets: [], loadingPresets: true };
    case gameDataConstants.FETCH_GAME_PRESETS_SUCCESS:
      return { ...state, presets: action.presets, loadingPresets: false };
    case gameDataConstants.FETCH_GAMES_LIST:
      return { ...state, games: action.games };

    case gameDataConstants.FETCH_FAVORITES_REQUEST:
      return { ...state, loadingFavorites: true };
    case gameDataConstants.FETCH_FAVORITES_SUCCESS:
      return { ...state, favorites: action.favorites, loadingFavorites: false };

    case gameDataConstants.GET_GAME_REQUEST:
      return {
        ...state,
        fetchingGames: [...state.fetchingGames, action.gameId],
      };
    case gameDataConstants.GET_GAME_SUCCESS:
      return {
        ...state,
        fetchingGames: state.fetchingGames.filter(
          (gameId) => gameId !== action.gameId
        ),
        gamesInfo: { ...state.gamesInfo, [action.gameId]: action.game },
      };
    case userConstants.REMOVE_PRESET_FROM_FAVORITES: {
      return {
        ...state,
        favorites: state.favorites.filter(
          (favorite) => favorite.id !== action.presetId
        ),
      };
    }
    case userConstants.LOGOUT: {
      return {
        ...state,
        favorites: [],
      };
    }
    default:
      return state;
  }
}
