export const bggCosntants = {
  SHOW_LOG_PLAY: "SHOW_LOG_PLAY",
  HIDE_LOG_PLAY: "HIDE_LOG_PLAY",

  SEND_LOG_PLAY: "SEND_LOG_PLAY",
  LOG_PLAY_SUCCESS: "LOG_PLAY_SUCCESS",
  LOG_PLAY_FAILURE: "LOG_PLAY_FAILURE",
  RESET_LOG_PLAY: "RESET_LOG_PLAY",

  SEND_CONNECT_ACCOUNT: "SEND_CONNECT_ACCOUNT",
  CONNECT_ACCOUNT_SUCCESS: "CONNECT_ACCOUNT_SUCCESS",
  CONNECT_ACCOUNT_FAILURE: "CONNECT_ACCOUNT_FAILURE",
};
