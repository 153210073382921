import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";

export default function BackButton(props) {
  const { to, ...other } = props;
  return (
    <Link to={to ? to : "/"}>
      <IconButton {...other}>
        <IoArrowBack />
      </IconButton>
    </Link>
  );
}
