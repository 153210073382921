import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesDataActions } from "../../features/gamesData";
import { GamePresets } from "../../components/GamePresets";
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { userActions } from "../../features/user";
import { useHistory } from "react-router";

export default function Favorites() {
  const auth = useSelector((state) => state.firebase.auth);
  const favorites = useSelector((state) => state.gamesData.favorites);
  const loadingFavorites = useSelector(
    (state) => state.gamesData.loadingFavorites
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (auth.uid && !auth.isAnonymous) {
      dispatch(gamesDataActions.getFavorites());
    }

    return () => {};
  }, [auth]);

  const handleLogin = () => {
    dispatch(userActions.login());
  };

  const handleGoHome = () => {
    history.push(`/`);
  };

  const renderNotLoggedIn = () => {
    return (
      <div className={classes.textMessageContainer}>
        <Typography className={classes.messageTitle}>
          Login to add scorepads to your favorits
        </Typography>

        <Button className={classes.actionButton} onClick={handleLogin}>
          Login
        </Button>
      </div>
    );
  };

  const renderNoFavorites = () => {
    return (
      <div className={classes.textMessageContainer}>
        <Typography className={classes.messageTitle}>
          You have no favorites. <br /> Go to the games list and find a game
        </Typography>

        <Button className={classes.actionButton} onClick={handleGoHome}>
          Games List
        </Button>
      </div>
    );
  };

  return (
    <section className={classes.content}>
      <GamePresets presets={favorites} showGameCover={true} />
      {loadingFavorites && (
        <div className={classes.loadingIndicatorContainer}>
          <CircularProgress className={classes.loadingIndicator} />
        </div>
      )}
      {!loadingFavorites &&
        auth.uid &&
        favorites.length === 0 &&
        !auth.isAnonymous &&
        renderNoFavorites()}
      {auth.isAnonymous && renderNotLoggedIn()}
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    minHeight: "100%",
    width: "100%",
  },

  loadingIndicatorContainer: {
    display: "flex",
    width: "100%",
    minHeight: "100%",
    alignContent: "center",
    justifyContent: "center",
    paddingTop: 64,
  },
  textMessageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 32,
    paddingTop: "40%",
  },
  messageTitle: {
    textAlign: "center",
    color: "#999999",
  },
  actionButton: {
    marginTop: 32,
  },
}));
