import axios from "axios";

const resources = {};

const makeRequestCreator = () => {
  let cancel;

  return async (query) => {
    console.log(resources);
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source();
    try {
      if (resources[query]) {
        // Return result if it exists
        return resources[query];
      }

      const url = `https://g8df95zuvg.execute-api.us-east-1.amazonaws.com/dev/bgg/search?search=${query}`;

      //const url = `https://api.allorigins.win/raw?url=https://www.boardgamegeek.com/xmlapi/search?search=${query}`;
      //const url = `xmlapi/search?search=${query}`;

      const res = await axios.get(url, {
        cancelToken: cancel.token,
        "Content-Type": "application/xml; charset=utf-8",
      });

      const result = res.data;
      // Store response
      resources[query] = result;

      return result;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
        //  console.log("Request canceled", error);
      } else {
        // Handle usual errors
        console.log("Something went wrong: ", error.message);
      }
    }
  };
};

export const search = makeRequestCreator();
