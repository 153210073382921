import { Dialog, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import HelpIcon from "@material-ui/icons/Help";
import ClearIcon from "@material-ui/icons/Clear";
import { Fragment } from "react";

export default function HelpTipButton(props) {
  const { helpComponent } = props;

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openHelp = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderHelpTipButton = () => {
    return (
      <IconButton className={classes.helpButton} onClick={openHelp}>
        <HelpIcon fontSize="inherit" />
      </IconButton>
    );
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.popupPaper }}
      >
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <ClearIcon />
        </IconButton>
        {helpComponent}
      </Dialog>
      {renderHelpTipButton()}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  popupPaper: {
    maxWidth: 800,
    margin: 0,
  },
  helpButton: {
    color: theme.palette.info.main,
    padding: 4,
    margin: 4,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));
