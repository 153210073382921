import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GameCover } from "../../components/GameCover";
import { GamePresets } from "../../components/GamePresets";
import { BackButton } from "../../components/BackButton";
import { Button, makeStyles, Typography } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { AccountButton } from "../../components/AccountButton";
import { useFirestoreConnect } from "react-redux-firebase";
import { userActions } from "../../features/user";
import BGGLinkGameButton from "../../components/BGGLink Button/BGGLinkGameButton";
import Feedback from "../../components/Feedback/Feedback";

export default function GamePage() {
  const { gameId } = useParams();

  useFirestoreConnect([
    {
      collection: `games`,
      doc: gameId,
    },
    {
      collection: `presets`,
      where: ["gameId", "==", gameId],
    },
  ]);

  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[gameId]
  );

  const presets = useSelector((state) => state.firestore.data.presets);
  const profile = useSelector((state) => state.firebase.profile);

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles({
    cover: game ? game.cover : "",
    background: game && game.background,
  });

  const handleCreatePreset = (e) => {
    if (profile.uid) {
      history.push(`/game/${game.id}/edit/new`);
    } else {
      dispatch(userActions.showLoginRequired());
    }
  };

  const createPresetButton = () => {
    return (
      presets &&
      Object.keys(presets).length > 0 && (
        <Button
          variant="contained"
          onClick={handleCreatePreset}
          className={classes.createPresetButton}
        >
          <AddBoxIcon />
          <Typography>new Scorepad</Typography>
        </Button>
      )
    );
  };

  return (
    <main className={classes.root}>
      <Feedback />
      <section className={classes.sideMenu}>
        <div className={classes.backgroundCover} />
        <BackButton to="/" className={classes.bakcButton} />
        <GameCover game={game} />

        <BGGLinkGameButton gameId={gameId} />

        <AccountButton />
      </section>
      <section className={classes.content}>
        <GamePresets game={game} presets={presets} showCreatePreset={true} />
      </section>
    </main>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: "100%",
    },
    [theme.breakpoints.up("md")]: {},
  },
  sideMenu: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      position: "relative",
      paddingBottom: 16,
      filter: "drop-shadow(0px 4px 8px  #000000)",
      paddingTop: 100,
    },
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "30%",
      height: "100%",
      overflow: "hidden",
      filter: "drop-shadow(0px 4px 8px  #000000)",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 64,
  },
  content: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginLeft: "30%",
      width: "70%",
      maxWidth: 1000,
    },
  },
  backgroundCover: {
    backgroundImage: (props) =>
      `url("${props.background ? props.background : props.cover}")`,
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    zIndex: -2,
    filter: (props) => (props.background ? null : "blur(90px)"),
    opacity: 1,
  },
  bakcButton: {
    [theme.breakpoints.down("sm")]: {
      top: 4,
      left: 4,
      zIndex: 100,
    },
    [theme.breakpoints.up("md")]: {
      top: 8,
      left: 8,
    },
    filter: "drop-shadow(0px 4px 8px  #000000)",
    position: "absolute",
  },
  createPresetButton: {
    marginTop: 32,
    padding: 16,
  },
}));
