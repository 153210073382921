export const gameDataConstants = {
  FETCH_GAMES_LIST: "FETCH_GAMES_LIST",

  GET_GAME_REQUEST: "GET_GAME_REQUEST",
  GET_GAME_SUCCESS: "GET_GAME_SUCCESS",

  SET_ACTIVE_GAME_REQUEST: "SET_ACTIVE_GAME_REQUEST",
  SET_ACTIVE_GAME_SUCCESS: "SET_ACTIVE_GAME_SUCCESS",

  FETCH_GAME_PRESETS_REQUEST: "FETCH_GAME_PRESETS_REQUEST",
  FETCH_GAME_PRESETS_SUCCESS: "FETCH_GAME_PRESETS_SUCCESS",

  FETCH_FAVORITES_REQUEST: "FETCH_FAVORITES_REQUEST",
  FETCH_FAVORITES_SUCCESS: "FETCH_FAVORITES_SUCCESS",
};
