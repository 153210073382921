import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { presetEditorActions } from "../../features/presetEditor";
import { Typography, Button, makeStyles, Divider } from "@material-ui/core";
import ScoreSettingEditorDialog from "./ScoreSettingEditorDialog";
import ScoreSettingsListEditor from "./ScoreSettingsListEditor";
import PlayersSettingsEditor from "./PlayersSettingsEditor";
import { GamePageContainer } from "../../components/GamePageContainer";
import { MessagePopup } from "../../components/Message Popup";
import { useFirestoreConnect } from "react-redux-firebase";
import PresetSettingsEditor from "./PresetSettingsEditor";
import WalktroughTip from "../../components/WalktoughTip/WalktroughTip";
import Feedback from "../../components/Feedback/Feedback";
import { Fragment } from "react";

export default function PresetEditor() {
  const { gameId, presetId } = useParams();

  useFirestoreConnect([
    {
      collection: `games`,
      doc: gameId,
    },
  ]);
  const auth = useSelector((state) => state.firebase.auth);

  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[gameId]
  );

  const dispatch = useDispatch();
  const editPreset = useSelector((state) => state.presetEditor.preset);
  const scoreSettings = useSelector(
    (state) => state.presetEditor.scoreSettings
  );
  const history = useHistory();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const classes = useStyles({ cover: game && game.cover });

  useEffect(() => {
    if (editPreset && auth) {
      if (editPreset.author !== auth.uid && presetId !== "new") {
        history.push(`/restricted/`);
      }
    }
    return () => {};
  }, [editPreset]);

  useEffect(() => {
    dispatch(presetEditorActions.setPreset(gameId, presetId));
    return () => {};
  }, []);

  const handleSave = (e) => {
    const isNew = presetId === "new";
    dispatch(presetEditorActions.savePreset(gameId, editPreset, isNew)).then(
      (success) => {
        success && history.push(`/game/${game.id}`);
      }
    );
  };
  const handleDelete = (e) => {
    setShowDeletePopup(true);
  };

  const handleDleteMessageClose = () => {
    setShowDeletePopup(false);
  };
  const handleDeleteAccecpt = () => {
    setShowDeletePopup(false);
    dispatch(presetEditorActions.deletePreset(editPreset));
    history.push(`/game/${game.id}`);
  };

  return (
    <Fragment>
      <Feedback />

      <GamePageContainer
        game={game}
        showHeader={true}
        backURL={game && `/game/${game.id}`}
      >
        <MessagePopup
          isOpen={showDeletePopup}
          onClose={handleDleteMessageClose}
          title="Are you sure?"
          message="You about to delete this scorepad, this cannot undone"
          onAccept={handleDeleteAccecpt}
          onCancel={handleDleteMessageClose}
        />
        <div className={classes.root}>
          <ScoreSettingEditorDialog />

          <Typography align="center">
            {`${presetId === "new" ? "Create a new" : "Edit"} scorepad for ${
              game && game.name
            }`}
          </Typography>

          {/* <WalktroughTip
          message={`you about to create a new scorepad for ${
            game && game.name
          } `}
          gotItButton={true}
          tipId="tip.create.preset.intro"
        /> */}

          <Divider />

          <PresetSettingsEditor preset={editPreset} />
          <section>
            <ScoreSettingsListEditor />
          </section>
          <Divider />
          <PlayersSettingsEditor />
          <Divider />
          <div className={classes.buttonsContainer}>
            <Button
              onClick={handleSave}
              className={classes.saveButton}
              disabled={
                (scoreSettings && scoreSettings.length === 0) || !scoreSettings
              }
            >
              Save
            </Button>
            {editPreset && presetId !== `new` && (
              <Button onClick={handleDelete} className={classes.deleteButton}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </GamePageContainer>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 16,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  saveButton: {
    padding: 12,
    paddingRight: 100,
    paddingLeft: 100,
    margin: "auto",
    marginTop: 16,
    flex: 1,
    marginRight: 16,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  deleteButton: {
    marginLeft: "auto",
    marginTop: 16,
    padding: 12,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));
