import { presetConstants } from "./constants";
import { userConstants } from "../user/constants";
import { uploadImage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { FieldValue } from "../../firebase";

export const presetEditorActions = {
  setPreset,
  //loadPreset,

  createScoreSettings,
  saveScoreSettings,
  editScoreSettingsItem,
  deleteScoreSettings,
  reorderScoreSettingsList,
  closeScoreSettings,
  editScoreSettingsField,

  editPresetSettingsField,

  //updatePreset,
  savePreset,
  deletePreset,

  uploadScoreSettingImage,

  editPlayerSettingsColors,
  editPlayerSettingsForceUniqueColors,
};

function setPreset(gameId, presetId) {
  return async (dispatch, getState, getFirebase) => {
    if (presetId === "new") {
      dispatch({
        type: presetConstants.NEW_PRESET,
        preset: {
          gameId: gameId,
        },
      });
    } else {
      const preset = await getFirebase()
        .firestore()
        .collection(`presets`)
        .doc(presetId)
        .get();

      dispatch({ type: presetConstants.SET_PRESET, preset: preset.data() });
    }
  };
}

// function loadPreset(presetId) {
//   return (dispatch) => {
//     fetchPreset(presetId, (preset) => {
//       dispatch({ type: presetConstants.LOAD_PRESET, preset });
//     });
//   };
// }

function createScoreSettings() {
  return { type: presetConstants.CREATE_SCORE_SETTINGS };
}

function saveScoreSettings(scoreSettings) {
  return (dispatch) => {
    dispatch({ type: presetConstants.SAVE_SCORE_SETTINGS, scoreSettings });
  };
}

function editScoreSettingsItem(scoreSetting) {
  return { type: presetConstants.EDIT_SCORE_SETTING, scoreSetting };
}
function deleteScoreSettings(scoreSetting) {
  return (dispatch) => {
    dispatch({ type: presetConstants.DELETE_SCORE_SETTINGS, scoreSetting });
  };
}
function reorderScoreSettingsList(scoreSettingsList) {
  return { type: presetConstants.REORDER_SCORE_SETTINGS, scoreSettingsList };
}

function closeScoreSettings() {
  return { type: presetConstants.CLOSE_SCORE_SETTINGS_DIALOG };
}
function editScoreSettingsField(field, value) {
  return { type: presetConstants.EDIT_SCORE_SETTINGS_FIELD, field, value };
}

function editPresetSettingsField(field, value) {
  return { type: presetConstants.EDIT_PRESET_SETTINGS_FIELD, field, value };
}

function savePreset(gameId, preset, isNew) {
  return async (dispatch, getState, getFirebase) => {
    const auth = getState().firebase.auth;
    console.log("saving preset", preset);
    if (auth.uid && !auth.isAnonymous) {
      const scoreSettings = getState().presetEditor.scoreSettings;
      const playersSettings = getState().presetEditor.playersSettings;

      preset.gameId = gameId;
      preset.scoreSettings = scoreSettings;
      preset.playersSettings = playersSettings;
      preset.author = auth.uid;

      isNew &&
        getFirebase()
          .firestore()
          .collection(`games`)
          .doc(preset.gameId)
          .update({ presets: FieldValue.increment(1) });

      await getFirebase()
        .firestore()
        .collection(`presets`)
        .doc(preset.id)
        .set(preset);

      dispatch({ type: presetConstants.PRESET_SAVED, preset });

      return Promise.resolve(true);
    } else {
      dispatch({ type: userConstants.SHOW_REQUIRE_LOGIN });
      return Promise.resolve(false);
    }
  };
}

function deletePreset(preset, callback) {
  return async (dispatch, getState, getFirebase) => {
    await getFirebase()
      .firestore()
      .collection("presets")
      .doc(preset.id)
      .delete();

    getFirebase()
      .firestore()
      .collection(`games`)
      .doc(preset.gameId)
      .update({ presets: FieldValue.increment(-1) });

    return Promise.resolve(true);
  };
}

function uploadScoreSettingImage(scoreSetting, file) {
  return (dispatch, getState) => {
    if (file) {
      const fileName = uuidv4();
      dispatch({
        type: presetConstants.SCORE_SETTING_IMGAE_START_UPLOAD,
        scoreSetting,
      });
      uploadImage(file, fileName, (uploadedFileURL) => {
        console.log(uploadedFileURL);
        dispatch({
          type: presetConstants.SCORE_SETTING_IMGAE_FINISHED_UPLOAD,
          scoreSetting,
          uploadedFileURL,
        });
      });
    } else {
      dispatch({
        type: presetConstants.SCORE_SETTING_IMGAE_FINISHED_UPLOAD,
        scoreSetting,
        uploadedFileURL: "",
      });
    }
  };
}

// ==== players setup

function editPlayerSettingsColors(colors) {
  return { type: presetConstants.EDIT_PLAYER_SETTINGS_COLORS, colors };
}

function editPlayerSettingsForceUniqueColors(forceUniqueColors) {
  return {
    type: presetConstants.EDIT_PLAYER_SETTINGS_FORCE_UNIQUE_COLORS,
    forceUniqueColors,
  };
}
