import {
  Button,
  Dialog,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";
import { ImageField } from "../ImageField";
import ConnectBGG from "./ConnectBGG";

export default function AccountSettings() {
  const showUserSettings = useSelector((state) => state.user.showUserSettings);
  const profile = useSelector((state) => state.firebase.profile);

  const [displayName, setDisplayname] = useState("");
  const [photoURL, setPhotoURL] = useState(null);
  const [tab, setTab] = useState(0);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(userActions.closeUserSettings());
  };

  const handleTabChange = (e, tabValue) => {
    setTab(tabValue);
  };

  const handleDispalyNameChange = (e) => {
    setDisplayname(e.target.value);
  };

  const handleAvatarChange = (file) => {
    if (file) {
      dispatch(
        userActions.updateAvatarImage(file, (imageFileURL) => {
          setPhotoURL(imageFileURL);
        })
      );
    } else {
      setPhotoURL(" ");
    }
  };

  const handleSave = async () => {
    let displayNameLeagal = true;
    if (displayName) {
      displayNameLeagal = await dispatch(
        userActions.checkLeagalDisplayName(displayName)
      );
    }

    if (displayNameLeagal) {
      const updatedUser = {
        ...profile,
        displayName: displayName.length > 0 ? displayName : profile.displayName,
        photoURL: photoURL ? photoURL : profile.photoURL,
      };
      dispatch(userActions.updateUser(updatedUser));
      dispatch(userActions.closeUserSettings());
    }
  };

  const renderAccountPanel = () => {
    return (
      <Fragment>
        <div className={classes.content}>
          <TextField
            label="Display Name"
            defaultValue={profile && profile.displayName}
            onChange={handleDispalyNameChange}
          />

          <ImageField
            imageURL={photoURL ? photoURL : profile && profile.photoURL}
            className={classes.avatar}
            onSelectFile={handleAvatarChange}
            round={true}
          />
        </div>
        <div>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.saveButton)}
            onClick={handleSave}
          >
            Save
          </Button>

          <Button
            variant="contained"
            className={clsx(classes.button, classes.closeButton)}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Fragment>
    );
  };

  const rencerConnectBGGPanel = () => {
    return <ConnectBGG showInfo={true} showUnlink={true} />;
  };

  return (
    <Fragment>
      <Dialog
        open={showUserSettings}
        onClose={handleClose}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <div className={classes.root}>
          <Typography variant="h5" className={classes.title}>
            Account Settings
          </Typography>

          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            className={classes.tabBar}
            variant="fullWidth"
          >
            <Tab label="Account" />
            <Tab label="Connect BGG" />
          </Tabs>

          {tab === 1 ? rencerConnectBGGPanel() : renderAccountPanel()}
        </div>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dialogPaper: {
    [theme.breakpoints.down("sm")]: {
      margin: 8,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  tabBar: {
    width: "100%",
  },
  avatar: {
    margin: 8,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      height: 200,
      width: 200,
    },
    [theme.breakpoints.up("md")]: {
      height: 128,
      width: 128,
    },
  },
  button: {
    paddingLeft: 32,
    paddingRight: 32,
    margin: 16,
  },
  saveButton: {
    backgroundColor: theme.palette.success.main,
  },
  closeButton: {
    opacity: 0.6,
  },
  content: {
    backgroundColor: "#00000033",
    padding: 16,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  title: {
    marginBottom: 16,
  },
}));
