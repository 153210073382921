import { Button, Dialog, Divider, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SignUpForm from "./SignUpForm";
import LoginForm from "./LoginForm";
import { useLoginStyles } from "./useLoginStyles";

const LoginPopup = () => {
  const showLogin = useSelector((state) => state.user.showLogin);
  const showSignupTab = useSelector((state) => state.user.showSignupTab);
  const auth = useSelector((state) => state.firebase.auth);

  const dispatch = useDispatch();
  const classes = useLoginStyles();

  const loggedIn = auth.isLoaded && !auth.isEmpty && !auth.isAnonymous;

  const handleClose = (e) => {
    dispatch(userActions.dismissLogin());
  };

  const handleSignupTab = () => {
    dispatch(userActions.showSignUpTab());
  };

  const handleLoginTab = () => {
    dispatch(userActions.showLoginTab());
  };

  const renderLoggedIn = () => {
    return (
      <div className={classes.root}>
        <CheckCircleOutlineIcon className={classes.successIcon} />
        <Typography className={classes.thankYouTitle}>
          Thank you for signing in
        </Typography>
        <Divider className={classes.divider} />
        <Button
          variant="contained"
          onClick={handleClose}
          className={classes.closeButton}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={showLogin} onClose={handleClose}>
      {loggedIn ? (
        renderLoggedIn()
      ) : showSignupTab ? (
        <SignUpForm onLoginTab={handleLoginTab} />
      ) : (
        <LoginForm onSignupTab={handleSignupTab} />
      )}
    </Dialog>
  );
};

export default LoginPopup;
