import {
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

export default function AdminGames() {
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const classes = useStyles();

  useFirestoreConnect([
    {
      collection: "games",
      //  where: [["presets", ">", 0]],
    },
  ]);
  const gamesList = useSelector((state) => state.firestore.ordered.games);

  const renderGameItem = (game) => {
    return (
      <ListItem key={game.id} button>
        <ListItemAvatar>
          <img src={game.thumbnail} className={classes.thumbnail} />
        </ListItemAvatar>
        {game.name}
        <ListItemSecondaryAction>
          <Checkbox checked={Boolean(game.background)} disabled />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderGamesList = () => {
    return (
      <Fragment>
        <Checkbox
          checked={showOnlyActive}
          onChange={(e, checked) => setShowOnlyActive(checked)}
        />
        show only active
        <List>
          {gamesList &&
            gamesList.map((game, index) => {
              if ((showOnlyActive && game.presets > 0) || !showOnlyActive) {
                return renderGameItem(game);
              }
              return null;
            })}
        </List>
      </Fragment>
    );
  };

  return (
    <div>
      <Typography>Games List</Typography>

      {renderGamesList()}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: 50,
    height: 50,
    objectFit: "cover",
    color: "#fff",
  },
}));
