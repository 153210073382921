import { Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import { PasswordField } from "../PasswordField";
import { useLoginStyles } from "./useLoginStyles";
import { userActions } from "../../features/user";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";

export default function SignUpForm(props) {
  const { onLoginTab } = props;
  const classes = useLoginStyles();
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const loginError = useSelector((state) => state.user.loginError);
  const dispatch = useDispatch();

  const handleSignUp = () => {
    dispatch(
      userActions.emailSignUp(email, password, confirmPassword, displayName)
    );
  };

  return (
    <div className={classes.root}>
      <Typography
        align="center"
        color="primary"
        variant="h5"
        className={classes.title}
      >
        Sign up
      </Typography>

      <TextField
        label="E-Mail"
        id="email"
        className={classes.inputField}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="off"
      />
      <TextField
        label="Display Name"
        id="displayName"
        className={classes.inputField}
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        autoComplete="off"
      />

      <PasswordField
        label="Password"
        id="password"
        className={classes.inputField}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete="off"
      />

      <PasswordField
        label="Confirm Password"
        id="confirmPassword"
        className={classes.inputField}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        autoComplete="off"
      />

      {loginError && (
        <Alert severity="error" className={classes.loginError}>
          {loginError}
        </Alert>
      )}

      <div
        className={clsx(classes.loginButtonsContainer, classes.signUpContainer)}
      >
        <Button
          onClick={onLoginTab}
          color="primary"
          className={clsx(classes.mainButton, classes.secondaryLoginButton)}
        >
          allready signed?
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={clsx(classes.mainButton)}
          onClick={handleSignUp}
        >
          Sign up
        </Button>
      </div>
    </div>
  );
}
