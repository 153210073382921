import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { gameScoringActions } from "../../features/gameScoring";
import { PlayersSelection } from "../../components/PlayersSelection";
import { Scoring as ScoringInput } from "../../components/Scoring";
import { ScoreSummary } from "../../components/ScoreSummary";
import { makeStyles } from "@material-ui/core";
import { populate, useFirestoreConnect } from "react-redux-firebase";
import { analytics } from "../../firebase";

export default function Scoring() {
  const { gameId, presetId } = useParams();

  const presetPopulate = [{ child: "author", root: "users", keyProp: "key" }];

  useFirestoreConnect([
    {
      collection: `presets`,
      doc: presetId,
      populates: presetPopulate,
    },
    {
      collection: `games`,
      doc: gameId,
    },
  ]);
  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[gameId]
  );

  const preset = useSelector((state) => {
    const presets = populate(state.firestore, "presets", presetPopulate);
    return presets && presets[presetId];
  });
  const scorepad = useSelector((state) => state.gameScoring.scorepad);
  const selectedPreset = useSelector((state) => state.gameScoring.preset);

  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (!selectedPreset || (preset && preset.id !== selectedPreset.id)) {
      dispatch(gameScoringActions.setPreset(preset));
    }
    return () => {};
  }, [preset]);

  useEffect(() => {
    analytics.logEvent("start_scoring", {
      preset: presetId,
      game: gameId,
    });
    return () => {};
  }, []);

  const renderCurrentPage = () => {
    return (
      <Switch>
        <Route path={`/game/:gameId/:presetId/setup`}>
          <PlayersSelection />
        </Route>

        <Route path={`/game/:gameId/:presetId/score/:scoreIndex`}>
          {scorepad.length > 0 ? (
            <ScoringInput />
          ) : (
            <Redirect to={`/game/${gameId}/${presetId}/setup`} />
          )}
        </Route>

        <Route path={`/game/:gameId/:presetId/summary`}>
          {scorepad.length > 0 ? (
            <ScoreSummary />
          ) : (
            <Redirect to={`/game/${gameId}/${presetId}/setup`} />
          )}
        </Route>

        <Route path={`/game/:gameId/:presetId/`}>
          <PlayersSelection />
        </Route>
      </Switch>
    );
  };

  return <div className={classes.root}>{renderCurrentPage()}</div>;
}

const useStyles = makeStyles((theme) => ({
  root: { minHeight: "100% !important", display: "flex" },
}));
