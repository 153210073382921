import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";
import { MessagePopup } from "../Message Popup";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles, Typography } from "@material-ui/core";

export default function LoginRequiredMessage() {
  const showRequireLogin = useSelector((state) => state.user.showRequireLogin);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(userActions.closeLoginRequired());
  };

  const handleShowLogin = () => {
    dispatch(userActions.closeLoginRequired());
    dispatch(userActions.login());
  };

  return (
    <MessagePopup
      isOpen={showRequireLogin}
      onClose={handleClose}
      title="Login Required"
      message={
        <div className={classes.messageContent}>
          <ErrorOutlineIcon className={classes.icon} />
          <Typography align="center">
            You need to be logged in to do that
          </Typography>
        </div>
      }
      onAccept={handleShowLogin}
      onCancel={handleClose}
      acceptButtonProps={{
        title: "LOGIN",
        className: classes.loginButton,
      }}
      cancelButtonProps={{ className: classes.cancelButton }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  messageContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    width: 64,
    height: 64,
    opacity: 0.3,
  },
  loginButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: `${theme.palette.success.main}66`,
    },
    width: 150,
  },
  cancelButton: {
    backgroundColor: "#555555",
    "&:hover": {
      backgroundColor: "#55555566",
    },
    width: 150,
  },
}));
