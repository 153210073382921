import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "../Home";
import { Scoring } from "../Scoring";
import { GamePage } from "../../pages/GamePage";
import { PresetEditor } from "../Preset Editor";
import { ScrollToTop } from "../../components/ScrollToTop";
import { LoginPopup, LoginRequiredMessage } from "../../components/Login";
import { AccountSettings } from "../../components/Account Settings";
import { PageNotFound } from "../404";
import { BGGLogPlay } from "../../components/BGGLogPlay";
import { DeleteAccount } from "../Delete Account";
import Admin from "../Admin/Admin";
import { useSelector } from "react-redux";

export default function MainRoute() {
  const profile = useSelector((state) => state.firebase.profile);
  return (
    <BrowserRouter>
      <LoginPopup />
      <LoginRequiredMessage />
      <AccountSettings />
      <CssBaseline />
      <ScrollToTop />

      {profile.isLoaded && (
        <Switch>
          <Route exact path="/logPlay">
            <BGGLogPlay />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/findgame">
            <Home />
          </Route>
          <Route exact path="/favorites">
            <Home />
          </Route>
          <Route exact path="/about">
            <Home />
          </Route>

          <Route path="/game/:gameId/edit/:presetId">
            <PresetEditor />
          </Route>

          <Route path="/game/:gameId/:presetId">
            <Scoring />
          </Route>

          <Route path="/game/:gameId">
            <GamePage />
          </Route>

          <Route path="/deleteaccount">
            <DeleteAccount />
          </Route>

          {profile.role === "admin" && (
            <Route path="/admin">
              <Admin />
            </Route>
          )}
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      )}
    </BrowserRouter>
  );
}
