import { Button, Divider, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import clsx from "clsx";
import { useLoginStyles } from "./useLoginStyles";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../features/user";
import { PasswordField } from "../PasswordField";
import { Alert } from "@material-ui/lab";

export default function LoginForm(props) {
  const { onSignupTab } = props;

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const loginError = useSelector((state) => state.user.loginError);

  const classes = useLoginStyles();
  const dispatch = useDispatch();

  const handleLoginWithEmail = (e) => {
    dispatch(userActions.emailLogin(email, password));
  };

  const handleSignInWithGoogle = (e) => {
    dispatch(userActions.googleSignUp());
  };
  const handleSignInFacebook = (e) => {
    dispatch(userActions.facebookSignUp());
  };

  return (
    <div className={classes.root}>
      <Typography
        align="center"
        color="primary"
        variant="h5"
        className={classes.title}
      >
        Login
      </Typography>
      <FacebookLoginButton
        onClick={handleSignInFacebook}
        className={classes.loginProviderButton}
      />
      <GoogleLoginButton
        onClick={handleSignInWithGoogle}
        className={classes.loginProviderButton}
      />

      <div className={classes.orDivider}>
        <Divider className={classes.divider} />
        <Typography className={classes.orText} color="primary">
          OR
        </Typography>
        <Divider className={classes.divider} />
      </div>

      <TextField
        label="E-Mail"
        className={classes.inputField}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="off"
      />
      <PasswordField
        label="Password"
        className={classes.inputField}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete="off"
      />

      {loginError && (
        <Alert severity="error" className={classes.loginError}>
          {loginError}
        </Alert>
      )}

      <div className={classes.loginButtonsContainer}>
        <Button
          onClick={onSignupTab}
          className={clsx(classes.mainButton, classes.secondaryLoginButton)}
          color="primary"
        >
          Sign up
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.mainButton)}
          onClick={handleLoginWithEmail}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
