import React, { useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import { useDispatch, useSelector } from "react-redux";
import { ratingActions } from "../../features/rating/actions";
import StarIcon from "@material-ui/icons/Star";
import {
  Button,
  IconButton,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import { useFirestore } from "react-redux-firebase";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export default function PresetRating(props) {
  const { preset } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userRating, setUserRating] = React.useState(null);

  const auth = useSelector((state) => state.firebase.auth);

  const classes = useStyles();
  const dispatch = useDispatch();

  const firestore = useFirestore();

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      getUserRating();
    }
    return () => {};
  }, [open]);

  const getUserRating = async () => {
    const ratingKey = `${auth.uid}_${preset.id}`;
    const result = await firestore.collection("ratings").doc(ratingKey).get();
    setUserRating(result.data());
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRatingChange = (e, newValue) => {
    newValue && dispatch(ratingActions.setRating(preset, newValue));
    setAnchorEl(null);
  };

  const handleDeleteRating = () => {
    dispatch(ratingActions.clearRating(preset));
    setUserRating(null);
  };

  const ratingPanelPopper = () => {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {renderRatingPanel()}
      </Popover>
    );
  };

  const renderRatingPanel = () => {
    return (
      <div className={classes.ratingPanel}>
        <Typography>Rate this scorepad</Typography>
        <div className={classes.ratingActionContent}>
          <IconButton
            onClick={handleDeleteRating}
            className={classes.clearButton}
          >
            <HighlightOffIcon />
          </IconButton>
          <Rating
            name={`${preset.id}`}
            value={userRating ? userRating.rating : 0}
            onChange={handleRatingChange}
            size="medium"
          />
        </div>
      </div>
    );
  };

  const renderRatingDisplay = () => {
    return (
      <div>
        {ratingPanelPopper()}
        <Button onClick={handleClick}>
          <StarIcon style={{ color: "orange" }} />
          <div className={classes.ratingLabelContainer}>
            <Typography className={classes.ratingLabel}>
              {preset.rating && preset.rating.value > 0
                ? parseFloat(preset.rating.value).toFixed(1)
                : "-"}
            </Typography>
            <Typography className={classes.ratingCountLabel}>
              {preset.rating &&
                preset.rating.count > 0 &&
                `(${preset.rating.count})`}
            </Typography>
          </div>
        </Button>
      </div>
    );
  };

  return renderRatingDisplay();
}

const useStyles = makeStyles((theme) => ({
  ratingPanel: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ratingActionContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 8,
  },
  ratingLabelContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 4,
  },
  ratingLabel: {
    fontSize: 18,
    lineHeight: 1.1,
  },
  ratingCountLabel: {
    fontSize: 10,
  },
  clearButton: {
    padding: 0,
    margin: 0,
    marginRight: 8,
    color: "gray",
  },
}));
