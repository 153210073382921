import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameScoringActions } from "../../features/gameScoring";
import ScoringInputField from "./SoringInputField";
import {
  Typography,
  Button,
  makeStyles,
  Slide,
  Paper,
  Hidden,
  Divider,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import NumberPad from "./NumberPad";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useHistory, useParams } from "react-router";

export default function Scoring() {
  const scorepad = useSelector((state) => state.gameScoring.scorepad);
  const { gameId, presetId, scoreIndex } = useParams();
  const game = useSelector(
    ({ firestore: { data } }) => data.games && data.games[gameId]
  );
  const classes = useStyles({
    cover: game && game.cover,
    background: game && game.background,
  });

  const history = useHistory();

  const handlePrevius = () => {
    let previusIndex = Number(scoreIndex);
    do {
      previusIndex--;
    } while (scorepad[previusIndex] && scorepad[previusIndex].scoring.isHidden);

    if (previusIndex < 0) {
      history.push(`/game/${gameId}/${presetId}/setup`);
    } else {
      history.push(`/game/${gameId}/${presetId}/score/${previusIndex}`);
    }
  };
  const handleNext = () => {
    let nextIndex = Number(scoreIndex);
    do {
      nextIndex++;
    } while (scorepad[nextIndex] && scorepad[nextIndex].scoring.isHidden);

    if (nextIndex >= scorepad.length) {
      history.push(`/game/${gameId}/${presetId}/summary`);
    } else {
      history.push(`/game/${gameId}/${presetId}/score/${nextIndex}`);
    }
  };

  const handleFinish = () => {
    history.push(`/game/${gameId}/${presetId}/summary`);
  };

  const scoringContent = (scorepadItem, index) => {
    const player = scorepadItem.player;
    const scoring = scorepadItem.scoring;

    return (
      <Paper
        className={classes.mainCard}
        key={index}
        style={{
          border: player.color ? "3px solid" : "none",
          borderColor: player.color,
        }}
      >
        <div
          className={classes.cardColorOverlay}
          style={{
            backgroundColor: `${player.color}33`,
          }}
        />
        <div className={classes.cardContent}>
          <div className={classes.playerNameContent}>
            <Divider className={classes.divider} />
            <Typography className={classes.playerNameLabel}>
              {player && player.name}
            </Typography>
            <Divider className={classes.divider} />
          </div>

          <div className={classes.scoringDescriptionContent}>
            {scoring && scoring.image && (
              <img
                src={scoring && scoring.image}
                className={classes.scoringIcon}
              />
            )}

            <div>
              <Typography className={classes.scoringTitleLabel}>
                {scoring && scoring.name}
              </Typography>
              <Typography className={classes.scoringDescriptionLabel}>
                {scoring && scoring.description}
              </Typography>
            </div>
          </div>

          <ScoringInputField
            scoringIndex={index}
            onSubmit={handleNext}
            multiplier={scoring && scoring.multiplier}
            isActive={index === Number(scoreIndex)}
            negative={scoring && scoring.negative}
          />

          <div className={classes.buttonsContainer}>
            <Button
              onClick={handlePrevius}
              className={classes.navigationButton}
            >
              <ArrowBackIcon />
            </Button>
            <Button onClick={handleFinish} className={classes.navigationButton}>
              <ListAltIcon />
            </Button>
            <Button onClick={handleNext} className={classes.navigationButton}>
              <Typography>NEXT</Typography>
              <ArrowForwardIcon />
            </Button>
          </div>
        </div>
      </Paper>
    );
  };

  const renderCarousel = () => {
    return (
      <Carousel
        autoPlay={false}
        animation="slide"
        indicators={false}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={true}
        index={Number(scoreIndex)}
        prev={handlePrevius}
        next={handleNext}
      >
        {scorepad.map((scoring, index) => {
          return scoringContent(scoring, index);
        })}
      </Carousel>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.bg} />

      {renderCarousel()}

      <Hidden mdUp>
        <NumberPad onNext={handleNext} />
      </Hidden>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100% !important",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mainCard: {
    [theme.breakpoints.down("xs")]: { width: "100%" },
    [theme.breakpoints.up("sm")]: { width: "100%", marginTop: 64 },
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
      minWidth: 600,
      maxWidth: "50%",
    },
    margin: "auto",
    backgroundColor: theme.palette.background.default,
    filter: "drop-shadow(0px 8px 8px  #00000055)",
    overflow: "hidden",
    borderRadius: 5,
  },
  header: {
    backgroundImage: (props) => `url("${props.cover}")`,
    width: "100%",
    height: 120,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bg: {
    backgroundImage: (props) =>
      `url("${props.background ? props.background : props.cover}")`,
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    zIndex: -2,
    opacity: 0.5,
    filter: (props) => (props.background ? null : "blur(50px) brightness(2)"),
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  scoringIcon: {
    height: 72,
    width: 72,
    borderRadius: 4,
    marginRight: 16,
  },
  cardContent: {
    [theme.breakpoints.down("sm")]: { padding: 8 },
    [theme.breakpoints.up("md")]: {
      padding: 32,
    },

    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  cardColorOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  navigationButton: {
    display: "flex",
    padding: 8,
  },
  playerNameContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "streatch",
    width: "100%",
  },
  playerNameLabel: {
    flex: "1 1 auto",
    fontSize: 18,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  divider: {
    flex: "1 1 auto",
    margin: 16,
    width: "100%",
    height: 2,
  },
  scoringDescriptionContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      marginTop: 16,
    },
  },
  scoringTitleLabel: {
    fontSize: 28,
  },
  scoringDescriptionLabel: {
    fontSize: 18,
    opacity: 0.5,
  },
}));
