import {
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import ClearIcon from "@material-ui/icons/Clear";
import { Fragment } from "react";
import { userActions } from "../../features/user/actions";

export default function PlayerNameField(props) {
  const { onChange, defaultValue, placeholder } = props;
  const auth = useSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();

  useFirestoreConnect([
    {
      collection: `users_data`,
      doc: auth.uid,
    },
  ]);
  const userData = useSelector(
    ({ firestore: { data } }) => data.users_data && data.users_data[auth.uid]
  );

  const playersNames = userData && userData.players ? userData.players : [];

  const classes = useStyles();

  const handleTextChange = (e) => {
    onChange(e.target.value);
  };

  const handleAutoSelectChange = (e, newValue) => {
    onChange(newValue);
  };

  const handleDeleteName = (e, name) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(userActions.deletePlayerName(name));
  };

  return (
    <Autocomplete
      options={playersNames && playersNames}
      getOptionLabel={(option) => option.toString()}
      className={classes.nameField}
      onChange={handleAutoSelectChange}
      defaultValue={defaultValue}
      freeSolo
      renderOption={(option) => (
        <React.Fragment>
          <div className={classes.nameSelectionContent}>
            <Typography className={classes.nameSelectionLabel}>
              {option}
            </Typography>
            <IconButton
              onClick={(e) => handleDeleteName(e, option)}
              className={classes.deleteButton}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          inputProps={{ className: classes.nameLabelInput }}
          {...params}
          variant="outlined"
          placeholder={placeholder}
          className={classes.nameField}
          fullWidth
          onChange={handleTextChange}
        />
      )}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  nameField: {
    flex: 1,
    width: "100%",
  },
  nameLabelInput: {
    fontSize: 38,
  },
  deleteButton: {
    padding: 4,
  },
  nameSelectionContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  nameSelectionLabel: {},
}));
