import { makeStyles } from "@material-ui/core";

export const useLoginStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignItems: "stretch",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      minWidth: 400,
    },
  },
  successIcon: {
    color: theme.palette.success.main,
    width: 100,
    height: 100,
    margin: "auto",
  },
  closeButton: {
    marginTop: 32,
    margin: "auto",
  },
  thankYouTitle: {
    margin: "auto",
    marginBottom: 16,
  },
  title: {
    marginBottom: 16,
  },
  orDivider: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 32,
    marginBottom: 16,
  },
  divider: {
    flex: "1 1 auto",
    color: "#ffffff66",
    height: 2,
  },
  orText: {
    display: "block",
    border: "1px solid #ffffff66",
    borderRadius: "50%",
    padding: 8,
    fontSize: 12,
  },
  inputField: {
    marginBottom: 16,
  },
  loginButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    justifyContent: "space-between",
  },
  signUpContainer: {
    marginTop: 64,
  },
  loginProviderButton: {
    marginBottom: "16px !important",
  },
  mainButton: {
    paddingRight: 32,
    paddingLeft: 32,
  },
  secondaryLoginButton: {
    fontSize: 16,
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  loginError: {
    flex: 1,
  },
}));
